import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IPaymentPlanBalance } from '../../../models/paymentplanbalance';
import { ComponentService } from '../../../services/component/component.service';
import { ConsumerService } from '../../../services/consumer/consumer.service';
import { PaymentPlanService } from '../../../services/paymentplan/paymentplan.service';

@Component({
    selector: 'account-details-modal',
    template: require('./accountdetailsmodal.component.html'),
    styles: [require('./accountdetailsmodal.component.css')]
})

export class AccountDetailsModalComponent implements OnInit {
    @ViewChild('modaltemplate', { static: false }) modalTemplate: any; // Should be HTMLTemplateElement but IE sucks
    @Input() ppGUID: string;

    groupedBalances: IPaymentPlanBalance[] = [];

    public modalRef: BsModalRef;

    public config = {
        keyboard: false, // allow them to press Escape key
        backdrop: true, // dim the page with a backdrop
        ignoreBackdropClick: true, // Disallow them from clicking the backdrop to dismiss
        animated: false
    };

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private modalService: BsModalService,
        private paymentPlanService: PaymentPlanService
    ) { }

    ngOnInit() {
        // The following logic was taken from the loadPaymentPlans() method in
        // BalanceDetailsComponent and modified to work a little differently here.
        this.consumerService.getConsumer().then((consumer) => {
            this.paymentPlanService.getBalanceDetails(consumer.accountID).then((balances) => {
                // get distinct accountNumbers from all balances returned by getBalanceDetails
                // this maps an array of accountNumbers out of balances, then reduces them
                // to a array that only contains distinct accountNumbers - if the x array
                // contains the next y string, just return x, else expand the x array to include y
                balances = balances.filter(x => x.paymentPlanGUID === this.ppGUID);

                const accounts: string[] = balances.map(b => b.accountNumber)
                    .reduce((x: string[], y: string) => x.some(s => s === y) ? x : [...x, y], []);

                accounts.forEach(accountNumber => {
                    // for each distinct accountNumber, sum the balances, get the latest
                    // balanceAsOf date, and determine if at least one balance is on a payment plan
                    this.groupedBalances.push(balances.filter(b => b.accountNumber === accountNumber).reduce((summed, balance) => {
                        summed.balance = summed.balance + balance.balance;
                        summed.balanceAsOf = balance.balanceAsOf > summed.balanceAsOf ? balance.balanceAsOf : summed.balanceAsOf;

                        summed.paymentPlanGUID = this.balanceIsOnPaymentPlan(balance.paymentPlanGUID)
                            ? balance.paymentPlanGUID
                            : summed.paymentPlanGUID;

                        return summed;
                    }));
                });
            });
        });
    }

    balanceIsOnPaymentPlan(paymentPlanGUID: string): boolean {
        return !!paymentPlanGUID && paymentPlanGUID !== this.componentService.NULL_GUID;
    }

    public openModal(): void {
        this.modalRef = this.modalService.show(this.modalTemplate, this.config);
    }

    closeModal(): void {
        this.modalRef.hide();
    }
}
