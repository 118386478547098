export interface IPaymentPlanBalance {
    paymentLocationGUID: string;
    description: string;
    serviceAreaID: number;
    isPaymentPlanEligible: boolean;
    canStorePaymentMethod: boolean;
    balanceAsOf: Date;
    serviceDate: Date;
    customerAccountID: string;
    accountNumber: string;
    balance: number;
    balanceMethod: BalanceMethod;
    paymentPlanGUID: string;
    merchantProfileGUID: string;
    hasAccountBalanceTransactions: boolean;
    inactiveReason: string;
    isActive: boolean;
    sequentialIdentifier: number;
    expectedPaymentDate: Date;
    expectedPaymentAmount: number;
    isAccountPaymentPlanEligible: boolean;
    ineligibleReason: string;
    displayField?: string;
    initialPayment: boolean;
    initialPaymentAmount: number;
    notes: string;
}

export enum BalanceMethod {
    realtime = 1,
    overwrite = 2,
    balancedetails = 3,
    batch = 4,
    guarantorbalance = 5,
    unknown = 0
}

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const TEST_PAYMENT_PLAN_BALANCE: IPaymentPlanBalance = {
    paymentLocationGUID: '11111111-1111-1111-1111-111111111111',
    description: 'Clinic 1',
    serviceAreaID: 456,
    isPaymentPlanEligible: true,
    canStorePaymentMethod: true,
    balanceMethod: BalanceMethod.balancedetails,
    balanceAsOf: new Date(),
    serviceDate: null,
    customerAccountID: '123456789',
    accountNumber: '123456789',
    balance: 500,
    paymentPlanGUID: EMPTY_GUID,
    merchantProfileGUID: EMPTY_GUID,
    hasAccountBalanceTransactions: true,
    inactiveReason: 'inactive',
    isActive: true,
    sequentialIdentifier: 1,
    expectedPaymentDate: new Date(),
    expectedPaymentAmount: 150,
    isAccountPaymentPlanEligible: true,
    ineligibleReason: 'Ineligible Reason',
    displayField: 'mock display field',
    initialPayment: false,
    initialPaymentAmount: 0,
    notes: ''
};

export const TEST_PAYMENT_PLAN_BALANCES: IPaymentPlanBalance[] = [
    {
        paymentLocationGUID: '11111111-1111-1111-1111-111111111111',
        description: 'Clinic 1',
        serviceAreaID: 456,
        isPaymentPlanEligible: false,
        canStorePaymentMethod: true,
        balanceMethod: BalanceMethod.balancedetails,
        balanceAsOf: new Date(),
        serviceDate: null,
        customerAccountID: '123456789',
        accountNumber: '123456789',
        balance: 500,
        paymentPlanGUID: EMPTY_GUID,
        merchantProfileGUID: EMPTY_GUID,
        hasAccountBalanceTransactions: true,
        inactiveReason: 'inactive',
        isActive: true,
        sequentialIdentifier: 1,
        expectedPaymentDate: new Date(),
        expectedPaymentAmount: 150,
        isAccountPaymentPlanEligible: true,
        ineligibleReason: '',
        initialPayment: false,
        initialPaymentAmount: 0,
        notes: ''
    },
    {
        paymentLocationGUID: '11111111-1111-1111-1111-111111111111',
        description: 'Clinic 2',
        serviceAreaID: 456,
        isPaymentPlanEligible: true,
        canStorePaymentMethod: true,
        balanceMethod: BalanceMethod.balancedetails,
        balanceAsOf: new Date(),
        serviceDate: null,
        customerAccountID: '123456789',
        accountNumber: '123456789',
        balance: 1000,
        paymentPlanGUID: EMPTY_GUID,
        merchantProfileGUID: EMPTY_GUID,
        hasAccountBalanceTransactions: true,
        inactiveReason: 'inactive',
        isActive: true,
        sequentialIdentifier: 1,
        expectedPaymentDate: new Date(),
        expectedPaymentAmount: 150,
        isAccountPaymentPlanEligible: true,
        ineligibleReason: '',
        initialPayment: false,
        initialPaymentAmount: 0,
        notes: ''
    }
];
