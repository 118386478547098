import { Component, Input } from '@angular/core';

@Component({
    selector: 'message-with-link',
    template: require('./messagewithlink.component.html'),
    styles: [require('./messagewithlink.component.css')]
})

export class MessageWithLinkComponent {
    @Input() messageWithLinkTitle = '';
    @Input() messageWithLinkMessage = '';
    @Input() messageWithLinkLink = '';
}