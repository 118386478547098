import { animate, state, style, transition, trigger } from '@angular/animations';
import { CurrencyPipe } from '@angular/common';
import { Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import moment from 'moment';
import { IonRangeSliderComponent } from 'ng2-ion-range-slider';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationModalComponent } from '../../components/Controls/ConfirmationModal/confirmationmodal.component';
import { PaymentSelectionComponent } from '../../components/Controls/PaymentSelection/paymentselection.component';
import { SelectAccountsModalComponent } from '../../components/Controls/SelectAccountsModal/selectaccountsmodal.component';
import { IConciergePresets } from '../../models/conciergepresets';
import { IConsumer } from '../../models/consumer';
import { ICustomPlanPresets } from '../../models/customplanpresets';
import { IDomainInfo } from '../../models/domaininfo';
import { PaymentMethod, PaymentMethodType } from '../../models/paymentmethod';
import { PaymentMethodAssignmentSource } from '../../models/paymentmethodassignmentsource';
import { IPaymentPlan } from '../../models/paymentplan';
import { IPaymentPlanBalance } from '../../models/paymentplanbalance';
import { IPaymentPlanDetails } from '../../models/paymentplandetails';
import {
    IPaymentPlanInitializationModel,
    PaymentPlanInitializationStatus
} from '../../models/paymentplaninitializationmodel';
import { IPaymentPlanNewChargesModel } from '../../models/paymentplannewchargesmodel';
import { IPaymentPlanPresetMinMax } from '../../models/paymentplanpresetminmax';
import { IPaymentPlanSubmit, PaymentPlanMode } from '../../models/paymentplansubmit';
import { IPaymentPlanTermsAndConditions } from '../../models/paymentplantermsandconditions';
import { PaymentSelectionModel, PaymentSelectionStateModel } from '../../models/paymentselectionmodel';
import { AgentAssistedService } from '../../services/agentassisted/agentassisted.service';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { LoggingLevel } from '../../services/logging/logging.service';
import { PaymentPlanService } from '../../services/paymentplan/paymentplan.service';
import { PayableEntry } from './../../models/PayableEntry';

@Component({
    selector: 'paymentplan',
    template: require('./paymentplan.component.html'),
    styles: [require('./paymentplan.component.css')],
    animations: [
        trigger('selectAPlanTrigger', [
            state('active', style({
                opacity: 1,
                cursor: 'initial'
            })),
            state('activebutton', style({
                opacity: 1,
                cursor: 'pointer'
            })),
            state('activeradio', style({
                opacity: 1,
                cursor: 'pointer'
            })),
            state('inactive', style({
                opacity: 0,
                height: 0,
                cursor: 'default',
                padding: 0
            })),
            transition('active => *', animate('600ms ease')),
            transition('* => active', animate('600ms ease-in')),
            transition('activeradio => *', animate('600ms ease')),
            transition('* => activeradio', animate('600ms ease-in'))
        ])
    ]
})

export class PaymentPlanComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<any> = new Subject();

    @ViewChild('sliderElement', { static: false }) sliderComponent: IonRangeSliderComponent;
    @ViewChild('paymentSelectionCmp', { static: false }) paymentSelectionCmp: PaymentSelectionComponent;
    @ViewChild('termsAndConditions', { static: false }) termsAndConditionsModal: ConfirmationModalComponent;
    @ViewChild('selectAccountsModal', { static: false }) selectAccountsModal: SelectAccountsModalComponent;

    public accounts: PayableEntry[];

    domainInfo: IDomainInfo;
    currentPaymentPlanMerchantGUID: string;
    submission: boolean;
    domainPresetRanges: IPaymentPlanPresetMinMax;
    enablePaymentPlans = true;
    wereSorryPageNavigateButtonText: string;

    // Step 0
    needStepZero = false;
    balancesAreLoading = true;
    planEligibleBalances: IPaymentPlanBalance[] = [];
    existingPaymentPlans: IPaymentPlanDetails[] = [];
    currentActivePaymentPlan: IPaymentPlanDetails;
    addToPlanList: IPaymentPlanNewChargesModel[] = [];
    noEligibleBalances = true;
    userHasExistingPlans = false;
    userHasBalancesToAdd = false;
    userHasIncompatibleBalances = false;
    userIsIneligible = false;
    anErrorOccurred = false;
    multiplePaymentPlanNotAllowed = false;
    paymentPlanStep0Name: string;
    paymentPlanStep0Header: string;
    paymentPlanStep0SubHeader: string;
    newChargeAmount = 0;
    addToThisPlanText: string;
    eligibleForNewPlanText: string;
    remainingBalanceText: string;
    balanceDueText: string;
    eligibleForText: string;
    makePaymentLinkText: string;
    wereSorryHeader: string;
    wereSorryText: string;
    wereSorryMobileHeader: string;
    eligibilityThresholdText: string;
    eligibilityThresholdMobileText: string;
    goHomeLinkText: string;
    paymentPlanAccountSelectionSubheader: string;

    // Step 1
    plansAreLoading = true;
    paymentPlanStep1Name: string;
    paymentPlanStep2Name: string;
    paymentPlanStep3Name: string;
    selectAPlanButtonText: string;
    nextButtonText: string;
    backButtonText: string;
    continueButtonText: string;
    customPlanDescription: string;
    amountToPay = 0;
    amountToPayMinusInitialPayment = 0;
    oldAmount = 0;
    paymentAmountText: string;
    detailsOpen = false;
    customOpen = 'inactive';
    notCustomOpen = 'active';
    detailsText: string;
    conciergePaymentPlans: IPaymentPlan[] = [];
    selectAPlan: string;
    durationText: string;
    paymentWillBe: string;
    choosePlanButtonText: string;
    customDescription: string;
    customPlanTitle: string;
    selectAPlanText: string;
    selectAPlanDescription: string;
    theSelectedPlanDescription: string;
    radioButton1Text: string;
    radioButton2Text: string;
    radioButton3Text: string;
    youChoseText: string;
    customPlanSelected = false;
    consumer: IConsumer;

    // Step 1A Custom
    customPlanStepDescription: string;
    customizeAPlanText: string;
    perMonthText: string;
    lastPaymentPerMonthText: string;
    perMonthValued: string;
    lastPaymentPerMonthValued: string;
    selectNumberOfMonths: string;
    monthsToPay: number;
    paymentPlanInitialPaymentCheckboxText: string;
    customFirstMonthText: string;
    paymentPlanInitialPaymentTooHigh: string;
    enablePaymentPlanInitialPayment: boolean;

    // Step 2
    paymentPlanStep2Header: string;
    paymentPlanStep2SubHeading: string;
    selectedPaymentMethod: PaymentMethod;
    paymentDate: string = new Date().toISOString();
    isAgentAssistedPayment = false;

    // Step 3
    paymentPlanStep3Header: string;
    paymentPlanStep3SubHeading: string;
    paymentMethodText: string;
    firstPaymentText: string;
    firstPaymentTextSave: string;
    startDateText: string;
    planDetailsText: string;
    planAuthorizationText: string;
    paymentPlanAuthDisclaimerText: string;
    paymentPlanPaymentAuthDisclaimerText: string;
    createPlanButtonText: string;
    paymentEndDate: string;
    paymentAuthorization = false;
    termsAndConditionsContentText: string;
    termsAndConditionsContent: SafeHtml;
    termsAndConditionsModalTitle: string;
    termsAndConditionsLink: string;
    paymentPlanSubmitErrorText: string;
    paymentPlanSubmitError = false;
    paymentPlanPaymentMethodErrorText: string;
    paymentPlanPaymentMethodError = false;
    paymentPlanScheduledDateError: string;
    paymentPlanExistsError = false;
    paymentPlanExistsErrorText: string;

    emailErrorExists = false;
    pickerDateInvalid = false;
    promptCustomerEmail = false;
    updateEmailFromAccount = false;
    customerEmailAddress: string;
    updateCustomerEmailAddressTitle: string;
    updateCustomerEmailAddressText: string;
    emailErrorText: string;
    paymentPlanFirstPaymentDescription: string;
    paymentPlanMonthlyAmountDescription: string;
    paymentPlanFinalPaymentDescription: string;

    stepValidationMessage: string;
    paymentPlanError = false;
    paymentPlanErrorText: string;
    paymentAuthErrorText: string;
    maxInitialPaymentAmountErrorText: string;
    minMonthlyPaymentAmountErrorText: string;
    minDurationPaymentAmountErrorText: string;
    paymentPlanMaxAmountErrorText: string;
    newPlan = 'active';
    newButtonPlan = 'activebutton';
    radioPlan = 'inactive';
    radioActive = 'inactive';
    customRadioActive = 'inactive';
    radioStyle = 'radio-icon-wrap';
    monthsText: string;

    stepListWithZero: any[];
    stepListWithoutZero: any[];
    currentStep = 1;
    finalStep = 3;
    step0Valid = true;
    step1Valid = true;
    step2Valid = true;
    step3Valid = true;
    step3Sent: boolean;
    selectedPlanIdx = -1;
    selectedPaymentOption = -1;

    initialPayment = false;
    initialPaymentAmount = 0.00;
    maxInitialPaymentAmount = 0.00;
    paymentAmount = 0.00;
    lastPayment = 0.00;
    maxPlanLength = 36;
    minPlanLength = 3;
    minPaymentAmount: number;

    paymentPlanMonthlyPay = true;
    paymentPlanFirstPay = true;
    paymentPlanOnly = true;
    enablePaymentPlanAccountChoice = false;

    conciergePresets: IConciergePresets[];
    customPresets: ICustomPlanPresets;
    customAvailable = false;
    errorMessage: string;
    ineligibilityMessage: string;
    multiplePaymentPlanNotAllowedMessage: string;
    datepickerTodayText = '';

    paymentPlanID: number;
    paymentPlanMode: PaymentPlanMode;

    modelToPassToPayment: PaymentSelectionModel = new PaymentSelectionModel();

    ppForm: FormGroup;

    ppFirstPaymentDate = new Date();
    minDate: Date = new Date();
    maxDate: Date;
    invalidDateErrorText: string;

    constructor(
        private agentAssistedService: AgentAssistedService,
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private parentRouter: Router,
        private currencyPipe: CurrencyPipe,
        private paymentPlanService: PaymentPlanService,
        private ngZone: NgZone,
        private formBuilder: FormBuilder
    ) { }

    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        // Wrap with this because it messes up unit tests
        if (process.env.ENV !== 'test') {
            // Needed for onPopState to function completely
            history.pushState(false, 'paymentplans', 'paymentplans/new');
        }
        this.prevStep();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async ngOnInit() {
        // The hours need to be reset or they'll be whatever the current hours are.
        this.minDate.setHours(0, 0, 0, 0);
        this.ppFirstPaymentDate = this.getFirstPaymentDate(new Date());

        this.ppForm = this.formBuilder.group({
            ppFirstPaymentDate: new FormControl(this.ppFirstPaymentDate, Validators.required)
        });

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.invalidDateErrorText = this.componentService.contentService.tryGetContentItem(content, 'global', 'error', 'genericInvalidDateSelectedText').text;
                this.paymentPlanStep1Name = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStep1Name').text;
                this.paymentPlanStep2Name = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStep2Name').text;
                this.paymentPlanStep3Name = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStep3Name').text;
                this.customPlanDescription = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanDescriptionText').text;
                this.paymentAmountText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanPaymentAmountText').text;
                this.selectAPlanButtonText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanSelectAPlanButton').text;
                this.detailsText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanPaymentDetails').text;
                this.selectAPlan = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanSelectAPlan').text;
                this.durationText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanDurationText').text;
                this.paymentWillBe = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanEstimateText').text;
                this.choosePlanButtonText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanChoosePlanButtonText').text;
                this.customPlanTitle = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanCustomPlanTitleText').text;
                this.selectAPlanText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanSelectAPlanText').text;
                this.selectAPlanDescription = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanSelectAPlanDescription').text;
                this.theSelectedPlanDescription = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanTheSelectedPlanDescription').text;
                this.radioButton1Text = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanSelectedPlanRadio1').text;
                this.radioButton2Text = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanSelectedPlanRadio2').text;
                this.radioButton3Text = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanSelectedPlanRadio3').text;
                this.continueButtonText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanContinueButton').text;
                this.backButtonText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanBackButton').text;
                this.youChoseText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanYouChose').text;
                this.customPlanStepDescription = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanCustomPlanStepDescription').text;
                this.customizeAPlanText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanCustomizeAPlanText').text;
                this.perMonthText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanPerMonthText').text;
                this.lastPaymentPerMonthText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanLastPaymentPerMonthText').text;
                this.selectNumberOfMonths = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanSelectNumberOfMonths').text;
                this.paymentPlanStep2Header = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStep2Header').text;
                this.paymentPlanStep2SubHeading = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStep2SubHeader').text;
                this.paymentPlanStep3Header = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStep3Header').text;
                this.paymentPlanStep3SubHeading = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStep3SubHeader').text;
                this.paymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanPaymentMethod').text;
                this.firstPaymentTextSave = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanFirstPaymentText').text;
                this.firstPaymentText = this.firstPaymentTextSave;
                this.startDateText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStartDateText').text;
                this.planDetailsText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanPlanDetailsText').text;
                this.planAuthorizationText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanPlanAuthorizationText').text;
                this.paymentPlanAuthDisclaimerText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanAuthorizationDisclaimer').text;
                this.paymentPlanPaymentAuthDisclaimerText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanPaymentAuthorizationDisclaimer').text;
                this.createPlanButtonText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanCreatePlanButtonText').text;
                this.termsAndConditionsModalTitle = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanTermsAndConditionsModalTitle').text;
                this.termsAndConditionsLink = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanTermsAndConditionsModalLink').text;
                this.paymentAuthErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'paymentPlan', 'paymentPaymentAuthorizationError').text;
                this.minMonthlyPaymentAmountErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'paymentPlan', 'paymentPlanMinMonthlyPaymentAmountError').text;
                this.minDurationPaymentAmountErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'paymentPlan', 'paymentPlanMinDurationPaymentAmountError').text;
                this.paymentPlanStep0Name = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStep0Name').text;
                this.paymentPlanStep0Header = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStep0Header').text;
                this.paymentPlanStep0SubHeader = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanStep0SubHeader').text;
                this.addToThisPlanText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanAddToThisPlanText').text;
                this.eligibleForNewPlanText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanEligibleForNewPlanText').text;
                this.remainingBalanceText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanRemainingBalanceText').text;
                this.balanceDueText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanBalanceDueText').text;
                this.eligibleForText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanEligibleForExistingText').text;
                this.paymentPlanSubmitErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'paymentPlan', 'paymentPlanSubmitErrorText').text;
                this.paymentPlanPaymentMethodErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'paymentPlan', 'paymentPlanPaymentMethodErrorText').text;
                this.paymentPlanMaxAmountErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'paymentPlan', 'paymentPlanMaxAmountErrorText').text;
                this.errorMessage = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'error', 'paymentPlanApiErrorMessage').text;
                this.wereSorryText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanWereSorryText').text;
                this.eligibilityThresholdText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanEligibilityNotMetText').text;
                this.ineligibilityMessage = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanIneligibilityMessage').text;
                this.multiplePaymentPlanNotAllowedMessage = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanMultipleNotAllowedMessage').text;
                this.makePaymentLinkText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanMakePaymentLinkText').text;
                this.goHomeLinkText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanGoHomeLinkText').text;
                this.eligibilityThresholdMobileText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanEligibilityNotMetMobileText').text;
                this.datepickerTodayText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentDatePickerTodayText').text;
                this.monthsText = ' ' + this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanMonthsText').text;
                this.emailErrorText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'error', 'paymentPlanEmailErrorText').text;
                this.updateCustomerEmailAddressTitle = this.componentService.contentService.tryGetContentItem(
                    content, 'paymentplan', 'pageText', 'paymentPlanUpdateCustomerEmailAddressText').title;
                this.updateCustomerEmailAddressText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanUpdateCustomerEmailAddressText').text;
                this.paymentPlanInitialPaymentCheckboxText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanInitialPaymentCheckbox').text;
                this.customFirstMonthText = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanCustomFirstMonthText').text;
                this.paymentPlanInitialPaymentTooHigh = this.componentService.contentService.tryGetContentItem(content, 'error', 'paymentplan', 'paymentPlanInitialPaymentTooHigh').text;
                this.paymentPlanFirstPaymentDescription = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'paymentselection', 'paymentPlanFirstPaymentDescription').text;
                this.paymentPlanMonthlyAmountDescription = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'paymentselection', 'paymentPlanMonthlyAmountDescription').text;
                this.paymentPlanFinalPaymentDescription = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'paymentselection', 'paymentPlanFinalPaymentDescription').text;
                this.paymentPlanAccountSelectionSubheader = this.componentService.contentService.tryGetContentItem(content, 'paymentplan', 'pageText', 'paymentPlanAccountSelectionSubheader').text;
                this.paymentPlanScheduledDateError = this.componentService.contentService.tryGetContentItem(content, 'error', 'paymentPlan', 'paymentPlanScheduledDateError').text;

                this.stepListWithZero = [
                    {stepName: this.paymentPlanStep0Name},
                    {stepName: this.paymentPlanStep1Name},
                    {stepName: this.paymentPlanStep2Name},
                    {stepName: this.paymentPlanStep3Name},
                ];
                this.stepListWithoutZero = [
                    {stepName: this.paymentPlanStep1Name},
                    {stepName: this.paymentPlanStep2Name},
                    {stepName: this.paymentPlanStep3Name},
                ];
            });

        this.componentService.domainService.getDomainInfo().then(domainInfo => {
            if (!!domainInfo) {
                this.domainInfo = domainInfo;

                this.modelToPassToPayment.msbPaymentPlanFutureDateLimit = domainInfo.msbPaymentPlanFutureDateLimit;
                this.enablePaymentPlanAccountChoice = domainInfo.enablePaymentPlanAccountChoice;
                this.enablePaymentPlans = domainInfo.enablePaymentPlans;
                this.paymentPlanMonthlyPay = domainInfo.paymentPlanMonthlyPay;
                this.paymentPlanFirstPay = domainInfo.paymentPlanFirstPay;
                this.paymentPlanOnly = domainInfo.paymentPlanOnly;
                this.enablePaymentPlanAccountChoice = domainInfo.enablePaymentPlanAccountChoice;

                const maxDate: Date = new Date();
                maxDate.setDate(new Date().getDate() + this.domainInfo.msbPaymentPlanFutureDateLimit);
                this.maxDate = maxDate;

                // The payment plan's future date limit comes from the domain info,
                // use that to fill in the maximum date for the date picker error message
                const endDate = new Date();
                endDate.setDate(endDate.getDate() + this.domainInfo.msbPaymentPlanFutureDateLimit);
                // Don't allow payment start dates on the 29th, 30th, or 31st
                if (endDate.getDate() > 28) {
                    endDate.setDate(28);
                }

                this.paymentPlanScheduledDateError =
                    this.paymentPlanScheduledDateError.replace('!PAYMENTPLANFUTUREDATELIMIT!', endDate.toLocaleDateString());

                if (!this.enablePaymentPlans) {
                    this.showErrorScreen();
                }
            }
        });

        this.isAgentAssistedPayment = this.componentService.storageService.exists('agentAssistedPaymentEmailGUID');

        if (this.componentService.userIsLoggedIn() && this.enablePaymentPlans) {
            this.consumerService.getConsumer().then(consumer => {
                this.consumer = consumer;

                this.consumerService.getConsumerAccount().then(account => {
                    if (account != null) {
                        this.customerEmailAddress = consumer.emailAddress || account.emailAddress;
                        this.promptCustomerEmail = !this.customerEmailAddress || this.customerEmailAddress.trim() === '';
                        this.updateEmailFromAccount = !consumer.emailAddress || consumer.emailAddress.trim() === '';

                        if (!this.isAgentAssistedPayment) {
                            // This call will be removed once the initialization data returns an eligible boolean and a status reason
                            this.paymentPlanService.getPaymentPlanDetailsForAccount(account.customerAccountID).then(plans => {
                                this.existingPaymentPlans = plans;
                                this.userHasExistingPlans = plans.filter(p => p.status === 'Active').length > 0;
                                this.paymentPlanService.getPaymentPlanInitializationData(account.customerAccountID).then(data => {
                                    this.determinePlanEligibility(data);
                                    this.balancesAreLoading = false;
                                    this.plansAreLoading = false;
                                }).catch(error => {
                                    this.showErrorScreen();
                                    this.componentService.loggingService.log(
                                        'error retrieving payment plan initialization data', LoggingLevel.error, error);
                                });
                            }).catch(error => {
                                this.showErrorScreen();
                            });
                        }
                    }
                }).catch(error => {
                    this.showErrorScreen();
                    this.componentService.loggingService.log('error retrieving consumer account', LoggingLevel.error, error);
                });
            });
        }

        if (this.isAgentAssistedPayment) {
            this.currentStep = 2;
            this.noEligibleBalances = false;
            const agentAssistedGUID = this.componentService.storageService.retrieve('agentAssistedPaymentEmailGUID');
            this.agentAssistedService.getStagedPaymentPlan(agentAssistedGUID).then(model => {
                this.amountToPay = model.balance;
                this.paymentAmount = model.expectedPaymentAmount;
                this.initialPayment = model.initialPayment;
                this.initialPaymentAmount = model.initialPaymentAmount;
                this.lastPayment = model.lastPayment;
                this.currentPaymentPlanMerchantGUID = model.merchantProfileGUID;
                this.setCurrentMerchantProfile(this.currentPaymentPlanMerchantGUID);

                switch (model.paymentPlanMode) {
                    case 'Monthly':
                        this.paymentPlanMode = PaymentPlanMode.Monthly;
                        break;
                    case 'First':
                        this.paymentPlanMode = PaymentPlanMode.First;
                        break;
                    default:
                        this.paymentPlanMode = PaymentPlanMode.Manual;
                        break;
                }

                this.paymentPlanID = model.paymentPlanID;

                const expectedPaymentDate = new Date(model.expectedPaymentDate);
                const todayDateOnly = new Date();
                if (todayDateOnly.valueOf() > expectedPaymentDate.valueOf()) {
                    this.ppFirstPaymentDate = todayDateOnly;
                } else {
                    this.ppFirstPaymentDate = expectedPaymentDate;
                }

                const newPlanIndex = this.conciergePaymentPlans.length;
                this.conciergePaymentPlans.push({
                    paymentPlanIdx: newPlanIndex,
                    amount: model.expectedPaymentAmount,
                    duration: model.duration,
                    paymentDescription: null
                });
                this.selectedPlanIdx = newPlanIndex;

                this.modelToPassToPayment.amountToPay = model.expectedPaymentAmount;
                this.modelToPassToPayment.lockSaveToWallet = true;
            });
        }

        this.modelToPassToPayment.contentItemCategory = 'paymentplan';
        this.modelToPassToPayment.contentItemSubCategory = 'paymentSelection';
        this.modelToPassToPayment.showPaymentDate = false;
    }

    getFirstPaymentDate(date: Date): Date {
        while (date.getDate() > 28) {
            date.setDate(date.getDate() + 1);
        }

        return date;
    }

    public async openSelectAccountsModal(): Promise<void> {
        if (this.currentActivePaymentPlan) {
            await this.selectAccountsModal.openModalExistingPaymentPlan(this.currentActivePaymentPlan.paymentPlanGUID);
        } else {
            await this.selectAccountsModal.openModalNewPaymentPlan(this.currentPaymentPlanMerchantGUID);
        }
    }

    public onAccountsSelected(event: any) {
        this.accounts = event;

        this.transitionToStep1(true);
    }

    buildConciergePresets(): void {
        if (this.conciergePresets == null) {
            this.componentService.loggingService.log(
                'PaymentPlan -> no concierge presets found for ' + this.amountToPay, LoggingLevel.debug);
            return;
        }

        let i = 1;
        this.conciergePresets.forEach(element => {
            this.conciergePaymentPlans = this.conciergePaymentPlans.concat({
                paymentPlanIdx: i++,
                duration: element.duration,
                amount: element.amount,
                paymentDescription: null
            });
        });
        this.conciergePaymentPlans.forEach(
            x => x.paymentDescription = this.paymentWillBe.replace('!AMOUNT!', this.currencyPipe.transform(x.amount, 'USD', 'symbol', '1.2-2'))
        );

        this.setPaymentPlanText(this.conciergePaymentPlans[0].duration);
    }

    buildCustomPlanPresets(): void {
        if (this.customPresets == null) {
            this.componentService.loggingService.log('PaymentPlan -> no custom presets found for ' + this.amountToPay, LoggingLevel.debug);
            return;
        }

        // Don't want to change/lose the amountToPay value
        this.amountToPayMinusInitialPayment = this.amountToPay;
        if (this.initialPayment) {
            this.amountToPayMinusInitialPayment -= this.initialPaymentAmount;
        }

        this.maxPlanLength = this.customPresets.maximumPlanDuration;

        if (Math.round(this.amountToPayMinusInitialPayment / this.customPresets.minimumMonthlyPayment) <
                       this.customPresets.maximumPlanDuration) {
            this.maxPlanLength = Math.round(this.amountToPayMinusInitialPayment / this.customPresets.minimumMonthlyPayment);

            if (this.initialPayment) {
                // Need to +1 that Initial Payment into the plan length
                this.maxPlanLength += 1;
            }
        }

        this.minPlanLength = this.customPresets.minimumPlanDuration;

        // Setting the initial value to min slider value
        this.monthsToPay = this.minPlanLength;
        this.minPaymentAmount = this.customPresets.minimumMonthlyPayment;

        let minPlanLengthMinusInitialPayment = this.minPlanLength;
        if (this.initialPayment) {
            minPlanLengthMinusInitialPayment -= 1;
        }

        this.paymentAmount = ComponentService.toDecimal(this.amountToPayMinusInitialPayment / minPlanLengthMinusInitialPayment);
        this.paymentAmountChange();
    }

    prevStep() {
        this.paymentPlanSubmitError = false;
        this.paymentPlanPaymentMethodError = false;

        if ((this.currentStep > 0 && this.needStepZero) || (this.currentStep > 1 && !this.needStepZero)) {
            this.paymentSelectionCmp.clearErrorState();
            this.step2Valid = this.step3Valid = true;
            // clear error message
            this.stepValidationMessage = null;

            if (this.currentStep === this.finalStep && this.paymentAuthorization) {
                // Reset authorization checkbox
                this.paymentAuthorization = false;
            }

            if (this.selectedPaymentOption === 3 && this.currentStep === this.finalStep) {
                // Skip step 2
                this.currentStep--;
            }

            this.paymentSelectionCmp.revertFakeSavedPayment();
            this.firstPaymentText = this.firstPaymentTextSave;
            this.currentStep--;
            this.componentService.scrollPageToTop();
        } else {
            // Redirect to / should get them to their domain default homepage
            this.ngZone.run(() => this.parentRouter.navigateByUrl('/')).then();
        }
    }

    nextStep() {
        // Wrap with this because it messes up unit tests
        if (process.env.ENV !== 'test') {
            // Needed for onPopState to function completely
            history.pushState(false, 'paymentplans', 'paymentplans/new');
        }

        if (this.currentStep === 0) {
            // TODO: Validate balance selection step?
        }

        if (this.currentStep === 1) {
            if (this.customPlanSelected) {
                this.validatePaymentPlanAmounts();
                this.modelToPassToPayment.monthSpanToPay = this.monthsToPay;
            } else {
                this.validateConcierge();
                this.modelToPassToPayment.monthSpanToPay =
                    this.conciergePaymentPlans.find((x) => x.paymentPlanIdx === this.selectedPlanIdx).duration;
            }

            this.modelToPassToPayment.amountToPay = this.initialPaymentAmount > 0 ? this.initialPaymentAmount : this.paymentAmount;
            this.modelToPassToPayment.paymentDate = new Date().toISOString();
            this.modelToPassToPayment.lockSaveToWallet = true;

            if (this.selectedPaymentOption === 3 && this.currentStepIsValid(this.currentStep)) {
                // Skip step 2
                this.modelToPassToPayment.monthSpanToPay = 0;

                if (this.selectedPlanIdx !== -1) {
                    this.setPaymentPlanText(this.conciergePaymentPlans.find((x) => x.paymentPlanIdx === this.selectedPlanIdx).duration);
                }
                this.currentStep++;
                this.planValuesSetup();
            }
            this.paymentSelectionCmp.clearPayments(true);
        }

        if (this.currentStep === 2 && this.selectedPaymentOption !== 3) {
            // Get payment method selected on step 2 so we can pass it to step 3's binding
            this.planValuesSetup();
        }

        if (this.currentStep === 3) {
            this.step3Sent = true;
            this.reviewPaymentValidation(true);
        }

        if (this.currentStep < this.finalStep && this.currentStepIsValid(this.currentStep)) {
            this.currentStep++;
            this.componentService.scrollPageToTop();
        } else if (this.currentStep === this.finalStep && this.currentStepIsValid(this.currentStep)) {
            this.submitPaymentPlan();
        }

        this.selectedPaymentMethod = this.paymentSelectionCmp.getPaymentSelectionState().selectedPaymentMethod;
    }

    currentStepIsValid(step: number): boolean {
        let stepIsValid: boolean;
        switch (step) {
            case 0:
                stepIsValid = this.step0Valid;
                break;

            case 1:
                stepIsValid = this.step1Valid;
                break;

            case 2:
                stepIsValid = this.step2Valid;
                break;
            case 3:
                stepIsValid = this.step3Valid;
                break;
        }
        return stepIsValid;
    }

    determinePlanEligibility(paymentPlanInitializationData: IPaymentPlanInitializationModel) {
        if (!paymentPlanInitializationData) {
            this.showErrorScreen();
            return;
        }

        this.planEligibleBalances = paymentPlanInitializationData.balancesToCreate;
        this.addToPlanList = paymentPlanInitializationData.balancesToAdd;
        this.newChargeAmount = paymentPlanInitializationData.totalBalance;

        // Only balances came back but domain doesn't allow multiple plans
        if (paymentPlanInitializationData.status === PaymentPlanInitializationStatus.multiplePaymentPlansNotAllowed) {
            this.showMultiplePaymentPlanErrorScreen();
            this.componentService.loggingService.log(
                'User has existing payment plan and domain doesn\'t allow multiple.', LoggingLevel.debug);
            return;
        }

        // If nothing came back or all the things that came back are ineligible
        if (!paymentPlanInitializationData.hasEligibleBalances) {
            this.showIneligibilityScreen();
            return;
        }

        this.userHasIncompatibleBalances = paymentPlanInitializationData.balancesToCreate.length > 1;
        this.userHasBalancesToAdd = paymentPlanInitializationData.balancesToAdd.length > 0;
        this.noEligibleBalances = !paymentPlanInitializationData.hasEligibleBalances;

        if (this.userHasBalancesToAdd) {
            this.currentStep = 0;
            this.needStepZero = true;

            this.setMonthlyPayBooleans(paymentPlanInitializationData.balancesToAdd[0].existingPlan.canStorePaymentMethod);
        } else if (this.userHasIncompatibleBalances) {
            this.currentStep = 0;
            this.needStepZero = true;
        } else {
            // Only one merchant, we're good to go to "select a plan"
            this.componentService.loggingService.log(
                'PaymentPlan no existing plans and one merchant -> skipping to step 1', LoggingLevel.debug);
            this.needStepZero = false;
            this.amountToPay = this.newChargeAmount;
            this.setCurrentMerchantProfile(paymentPlanInitializationData.balancesToCreate[0].merchantProfileGUID);
            this.setMonthlyPayBooleans(paymentPlanInitializationData.balancesToCreate[0].canStorePaymentMethod);
            this.transitionToStep1(true);
        }
    }

    addToPlan(existingPlanBalance: IPaymentPlanBalance) {
        const existingPlan = this.existingPaymentPlans.find(item => item.paymentPlanGUID === existingPlanBalance.paymentPlanGUID);
        this.paymentSelectionCmp.setPreselectedPaymentMethod(existingPlan.consumerPaymentMethod);
        const merchantGUID = this.existingPaymentPlans.filter(
            p => p.paymentPlanGUID === existingPlan.paymentPlanGUID).map(b => b.merchantProfileGUID)[0];
        this.setCurrentMerchantProfile(merchantGUID);
        this.oldAmount = this.amountToPay;

        // Should only be adding existing plan balance to 1 balanceToAdd, but because it is lists, it maps
        this.amountToPay = existingPlan.balance + this.addToPlanList
            .filter(x => x.existingPlan.paymentPlanGUID === existingPlan.paymentPlanGUID)
            .map(p => p.balanceToAdd)
            .reduce((sum, val) => sum + val, 0);
        this.setMonthlyPayBooleans(existingPlanBalance.canStorePaymentMethod);
        this.transitionToStep1();
    }

    validateEmailAddress(): void {
        this.emailErrorExists = false;

        if (this.step3Sent && this.promptCustomerEmail
            && (!this.customerEmailAddress
                || !this.componentService.emailRegex.test(this.customerEmailAddress.toLowerCase()))) {
            this.emailErrorExists = true;
            // Validate current step
            this.reviewPaymentValidation();
        }
    }

    createPlanWithBalance(balance: IPaymentPlanBalance) {
        this.oldAmount = this.amountToPay;
        this.amountToPay = balance.balance;
        this.setMonthlyPayBooleans(balance.canStorePaymentMethod);
        this.setCurrentMerchantProfile(balance.merchantProfileGUID);
        this.transitionToStep1();
    }

    transitionToStep1(shortcircuitValidation: boolean = false) {
        if (shortcircuitValidation) {
            this.currentStep = 1;
        } else {
            this.nextStep();
        }

        // Don't call the API again if the amount is the same.
        if (this.oldAmount !== this.amountToPay) {
            // Reset paymentplans list so we get the fresh presets on the next call.
            this.conciergePaymentPlans = [];
            this.plansAreLoading = true;

            // Identify the active payment plan for the merchant profile.
            const activePaymentPlans = this.existingPaymentPlans.filter(p =>
                p.status === 'Active' && p.merchantProfileGUID === this.currentPaymentPlanMerchantGUID);
            this.currentActivePaymentPlan = activePaymentPlans.length > 0 ? activePaymentPlans[0] : null;

            if (!!this.accounts && this.accounts.length > 0) {
                this.amountToPay = this.currentActivePaymentPlan === null ? 0 : this.currentActivePaymentPlan.balance;
                this.accounts.forEach(a => {
                    this.amountToPay += a.payableAmount;
                });
            }

            this.paymentPlanService.getConciergePlanPresets(
                this.consumer.accountID, this.amountToPay, this.currentPaymentPlanMerchantGUID).then(conciergePresets => {
                    this.conciergePresets = conciergePresets;
                    this.paymentPlanService.getCustomPlanPresets(
                        this.consumer.accountID, this.amountToPay, this.currentPaymentPlanMerchantGUID).then(customPresets => {
                            this.customPresets = customPresets;
                            this.buildConciergePresets();
                            this.buildCustomPlanPresets();
                            this.checkConciergeAndCustomPresets();
                        }).catch(error => {
                            // this.errorMessage = <any>error;
                            this.showErrorScreen();
                        });
                }).catch(error => {
                    // this.errorMessage = <any>error;
                    this.showErrorScreen();
                });
        } else {
            this.checkConciergeAndCustomPresets();
        }
    }

    showErrorScreen() {
        this.anErrorOccurred = true;
        this.noEligibleBalances = true;
        this.multiplePaymentPlanNotAllowed = false;
        this.currentStep = null;
        this.wereSorryPageNavigateButtonText = this.goHomeLinkText;
        this.wereSorryHeader = this.wereSorryText;
        this.wereSorryMobileHeader = this.wereSorryText;
    }

    showIneligibilityScreen() {
        this.userIsIneligible = true;
        this.noEligibleBalances = true;
        this.multiplePaymentPlanNotAllowed = false;
        this.currentStep = null;
        this.wereSorryPageNavigateButtonText = this.makePaymentLinkText;
        this.wereSorryHeader = this.eligibilityThresholdText;
        this.wereSorryMobileHeader = this.eligibilityThresholdMobileText;
    }

    showMultiplePaymentPlanErrorScreen() {
        this.userIsIneligible = false;
        this.multiplePaymentPlanNotAllowed = true;
        this.noEligibleBalances = true;
        this.currentStep = null;
        this.wereSorryPageNavigateButtonText = this.makePaymentLinkText;
        this.wereSorryHeader = this.wereSorryText;
        this.wereSorryMobileHeader = this.wereSorryText;
    }

    balanceIsWithinPresets(balance: number): boolean {
        if (this.domainPresetRanges == null) {
            return false;
        }

        if (this.domainPresetRanges.conciergeMinimumBalance <= balance && balance <= this.domainPresetRanges.conciergeMaximumBalance) {
            return true;
        }

        if (this.domainPresetRanges.customMinimumBalance <= balance && balance <= this.domainPresetRanges.customMaximumBalance) {
            return true;
        }

        return false;
    }

    private setMonthlyPayBooleans(canStorePaymentMethod: boolean) {
        this.paymentPlanMonthlyPay = this.domainInfo.paymentPlanMonthlyPay && canStorePaymentMethod;
        this.paymentPlanFirstPay = this.domainInfo.paymentPlanFirstPay && canStorePaymentMethod;
    }

    private checkConciergeAndCustomPresets() {
        if (this.conciergePresets == null && this.customPresets == null) {
            // This shouldn't happen because this record should show "make a payment" but just in case
            this.showIneligibilityScreen();
        }

        if (this.conciergePresets == null) {
            this.customPlan();
        }

        if (this.customPresets != null) {
            this.customAvailable = true;
        }

        this.plansAreLoading = false;
    }

    redirect(relativeRoute: string) {
        this.ngZone.run(() => this.parentRouter.navigateByUrl(relativeRoute)).then();
    }

    private setCurrentMerchantProfile(merchantProfileGUID: string) {
        this.currentPaymentPlanMerchantGUID = merchantProfileGUID;

        this.consumerService.getMerchantProfileByMerchantProfileGUID(this.currentPaymentPlanMerchantGUID).then((merchant) => {
            this.enablePaymentPlanInitialPayment = merchant.enablePaymentPlanInitialPayment;

            this.paymentSelectionCmp.setMerchantProfile(merchant, true, true);
            this.componentService.contentService.getMerchantProfileContentItems(merchant.merchantProfileGUID, 'phrase', null, 'PaymentPlanCustomDescriptionText').then(
                merchantContentItem => {
                    if (!!merchantContentItem) {
                        this.customDescription = merchantContentItem.text;
                    }
                });
            this.componentService.contentService.getMerchantProfileContentItems(merchant.merchantProfileGUID, 'phrase', null, 'TermsAndConditionsNewPlanMSB').then(
                merchantContentItem => {
                    if (!!merchantContentItem) {
                        this.termsAndConditionsContentText = merchantContentItem.text;
                    }
                });

        });
    }

    private submitPaymentPlan(): void {
        this.paymentPlanSubmitError = false;
        this.paymentPlanPaymentMethodError = false;
        this.submission = true;
        const paymentPlanSubmitModel: IPaymentPlanSubmit = this.createPaymentPlanRequest();

        this.paymentPlanService.submitPaymentPlan(paymentPlanSubmitModel).then(() => {
            this.submission = false;
            this.componentService.storageService.store('paymentplancreatedorupdated', paymentPlanSubmitModel);

            if (this.isAgentAssistedPayment) {
                this.componentService.storageService.store('agentassistedrole', 'paymentPlanExisting');
            }

            this.ngZone.run(() => {
                this.ngZone.run(() => this.parentRouter.navigateByUrl('/paymentplans')).then();
            });
        }).catch((error) => {
            if (!!error && error.message === 'Failed to save payment method') {
                this.paymentPlanPaymentMethodError = true;
            } else if (!!error && error.message === 'Active payment plan already exists') {
                this.paymentPlanExistsError = true;
                this.paymentPlanExistsErrorText = error.message;
            } else {
                this.paymentPlanSubmitError = true;
            }

            this.submission = false;
        });
    }

    /**
     * This is the assembly of the ConsumerPayment to send to the various ConsumerAPI methods for
     * validation and payment.  createPayment stores the payment created on the PaymentComponent to be
     * used throughout the various calls.
     *
     * @memberof AutopayEnrollmentComponent
     */
    private createPaymentPlanRequest(): IPaymentPlanSubmit {
        const paymentState: PaymentSelectionStateModel = this.paymentSelectionCmp.getPaymentSelectionState();
        const payment: IPaymentPlanSubmit = {} as IPaymentPlanSubmit;

        if (this.selectedPaymentOption !== 3) {
            payment.PaymentSource = this.paymentSelectionCmp.getPaymentSource();
            // Must have one of tokenpayment,achpayment or creditcardpayment
            // check for wallet
            if (!!paymentState.selectedPaymentMethod.tokenGUID && paymentState.selectedPaymentMethod.tokenGUID !== 'newPaymentMethod') {
                payment.consumerPaymentMethodGUID = paymentState.selectedPaymentMethod.tokenGUID;
            } else {
                // Not wallet, is it cc or ach?
                if (paymentState.selectedPaymentMethod.paymentMethodType === PaymentMethodType.ach) {
                    payment.ACHPaymentMethod = this.paymentSelectionCmp.getACHPayment();
                } else {
                    payment.CreditCardPaymentMethod = this.paymentSelectionCmp.getCreditCardPayment();
                }
            }
            payment.Address = {
                firstName: paymentState.firstName,
                lastName: paymentState.lastName,
                address1: paymentState.address1,
                address2: paymentState.address2,
                city: paymentState.city,
                stateRegion: paymentState.state,
                postalCode: paymentState.postalCode,
                country: paymentState.country
            };
        }

        if (this.selectedPaymentOption > -1) {
            payment.PaymentPlanMode = this.selectedPaymentPlanMode();
        } else {
            payment.PaymentPlanMode = this.paymentPlanMode;
        }

        payment.MerchantProfileGUID = this.currentPaymentPlanMerchantGUID;
        payment.CustomerAccountID = this.consumer.accountID;
        payment.FirstDate = this.ppFirstPaymentDate;

        payment.LastDate = new Date(this.paymentEndDate);
        payment.InitialPayment = this.initialPayment;

        if (this.initialPayment) {
            payment.InitialPaymentAmount = this.initialPaymentAmount;
        } else {
            payment.InitialPaymentAmount = null;
        }

        payment.PaymentAmount = this.paymentAmount;
        payment.Balance = ComponentService.toDecimal(this.amountToPay);
        payment.ExpectedPaymentAmount = this.paymentAmount;
        payment.ExpectedPaymentDate = this.ppFirstPaymentDate;
        payment.PaymentMethodNickname = paymentState.newPaymentNickname;
        payment.CustomerEmailAddress = this.promptCustomerEmail || this.updateEmailFromAccount ? this.customerEmailAddress : undefined;
        payment.PaymentMethodAssignmentSource = PaymentMethodAssignmentSource.SelfService;

        if (!!this.accounts && this.accounts.length > 0) {
            payment.GuarantorAccountNumbers = [];
            this.accounts.forEach( a => {
                payment.GuarantorAccountNumbers.push(a.itemDescription);
            });
        }

        payment.PaymentPlanID = this.paymentPlanID;

        return payment;
    }

    private validateConcierge(): void {
        this.step1Valid = true;
        if (this.selectedPaymentOption === -1) {
            this.step1Valid = false;
            this.radioStyle = 'validation-errors';
        }
    }

    private validatePaymentPlanAmounts(): void {
        this.step1Valid = true;
        this.stepValidationMessage = '';

        this.validateConcierge();

        // Don't want to change/lose the amountToPay value
        this.amountToPayMinusInitialPayment = this.amountToPay;
        if (this.initialPayment) {
            this.amountToPayMinusInitialPayment -= this.initialPaymentAmount;
        }

        if (this.initialPayment && this.initialPaymentAmount < this.minPaymentAmount) {
            // Initial Payment Amount too small
            this.step1Valid = false;
            this.stepValidationMessage = this.minMonthlyPaymentAmountErrorText.replace(
                '!MIN!', this.currencyPipe.transform(this.minPaymentAmount, 'USD', 'symbol'));
            return;
        }

        if (this.initialPayment && this.initialPaymentAmount > this.maxInitialPaymentAmount) {
            // Initial Payment is too much
            this.step1Valid = false;

            this.stepValidationMessage = this.paymentPlanInitialPaymentTooHigh.replace(
                '!AMOUNT!', this.currencyPipe.transform(this.maxInitialPaymentAmount, 'USD', 'symbol'));
            return;
        }

        if (this.paymentAmount < this.minPaymentAmount || !Number.isFinite(this.paymentAmount)) {
            // Payment Amount too small
            this.step1Valid = false;
            this.stepValidationMessage = this.minMonthlyPaymentAmountErrorText.replace(
                '!MIN!', this.currencyPipe.transform(this.minPaymentAmount, 'USD', 'symbol'));
            return;
        }

        if (this.lastPayment < 5) {
            // Last payment must be $5 or more
            this.step1Valid = false;
            this.stepValidationMessage = this.paymentPlanMaxAmountErrorText;
            return;
        }

        let months: number = this.minPlanLength;

        if (this.initialPayment) {
            // Need to remove the initial payment
            months--;
        }

        while (this.paymentAmount > 0 && months * this.paymentAmount < (this.amountToPayMinusInitialPayment - 5)) {
            months++;
        }

        if (this.initialPayment) {
            // Need to add the initial payment back in
            months++;
        }

        if (months > this.maxPlanLength) {
            this.step1Valid = false;
            this.stepValidationMessage = this.minDurationPaymentAmountErrorText.replace('!MAXMONTHS!', this.maxPlanLength.toString());
            return;
        }

        let minPlanLengthMinusInitialPayment = this.minPlanLength;
        if (this.initialPayment) {
            // Don't count the Initial Payment in the else if's below
            minPlanLengthMinusInitialPayment -= 1;
        }

        if (this.paymentAmount !== this.lastPayment) {
            // Include lastPayment in max/min payment error calculations
            if (ComponentService.toDecimal((this.paymentAmount * (this.maxPlanLength - 1)) + this.lastPayment, 2) <
                                            this.amountToPayMinusInitialPayment) {
                this.step1Valid = false;
                this.stepValidationMessage = this.minMonthlyPaymentAmountErrorText.replace(
                    '!MIN!', this.currencyPipe.transform(this.minPaymentAmount, 'USD', 'symbol'));
            } else if (ComponentService.toDecimal((this.paymentAmount * (minPlanLengthMinusInitialPayment - 1)), 2) >
                                                   this.amountToPayMinusInitialPayment) {
                this.step1Valid = false;
                this.stepValidationMessage = this.minDurationPaymentAmountErrorText.replace('!MAXMONTHS!', this.maxPlanLength.toString());
            }
        } else {
            if (ComponentService.toDecimal(this.paymentAmount * this.maxPlanLength, 2) < this.amountToPayMinusInitialPayment) {
                this.step1Valid = false;
                this.stepValidationMessage = this.minMonthlyPaymentAmountErrorText.replace(
                    '!MIN!', this.currencyPipe.transform(this.minPaymentAmount, 'USD', 'symbol'));
            } else if (ComponentService.toDecimal(this.paymentAmount * minPlanLengthMinusInitialPayment, 2) >
                                                  this.amountToPayMinusInitialPayment) {
                this.step1Valid = false;
                this.stepValidationMessage = this.minDurationPaymentAmountErrorText.replace('!MAXMONTHS!', this.maxPlanLength.toString());
            }
        }
    }

    private planValuesSetup(): void {
        this.step2Valid = true;
        if (this.selectedPaymentOption !== 3) {
            this.step2Valid = this.paymentSelectionCmp.isValid();
        }

        this.setPlanValues();
    }

    private setPlanValues(): void {
        // None of this can be done if there's an invalid date in the datepicker.
        // This can happen if a user types garbage into the datepicker, then goes back a step, and tries to continue.
        if (this.pickerDateInvalid) {
            this.reviewPaymentValidation();
            return;
        }

        this.paymentDate = this.ppFirstPaymentDate.toISOString();

        // Since the date does not have a time associated with it here, there is no need to use UTC,
        // and in fact using .UTC would sometimes end up changing the date when it adjusts for time zone.
        // (This is not a problem above since it is immediately convered to an ISOString, prior to any manipulation.)
        const endDate = this.ppFirstPaymentDate;

        let endDateMoment: moment.Moment = moment(endDate);

        if (this.selectedPlanIdx !== -1) {
            endDateMoment = endDateMoment.add(
                this.conciergePaymentPlans.find((x) => x.paymentPlanIdx === this.selectedPlanIdx).duration - 1, 'months');

            this.setPaymentPlanText(this.conciergePaymentPlans.find((x) => x.paymentPlanIdx === this.selectedPlanIdx).duration);
        } else {
            endDateMoment = endDateMoment.add(this.monthsToPay - 1, 'months');
            // this.setPaymentPlanText done in custom step 1a
        }

        this.paymentEndDate = endDateMoment.toISOString();

        if (this.selectedPaymentOption === 3) {
            this.firstPaymentText = this.startDateText;
        }
    }

    // Sets stepValidationMessage and step3Valid
    private reviewPaymentValidation(createPlan: boolean = false): void {
        // Set the validationMessage based on which error is present
        // First for the date, then if it is the final step and the Create Plan button was selected,
        // then check for the authorization checkbox, and last for email
        this.stepValidationMessage =
            this.pickerDateInvalid ? this.paymentPlanScheduledDateError :
            (this.currentStep === this.finalStep && createPlan && !this.paymentAuthorization) ? this.paymentAuthErrorText :
                    this.emailErrorExists ? this.emailErrorText :
                        null;

        // Step 3 is valid if there's no error message
        this.step3Valid = !this.stepValidationMessage;
    }

    private getPaymentPlanDetailsTerms(): void {
        let planDuration = 0;

        if (this.conciergePaymentPlans[0] && this.selectedPlanIdx !== -1) {
            // A concierge preset is selected.
            if (this.selectedPlanIdx === 0) {
                planDuration = this.conciergePaymentPlans[0].duration;
            } else {
                planDuration = this.conciergePaymentPlans[this.selectedPlanIdx - 1].duration;
            }
        } else {
            // This is a custom plan.
            planDuration = this.monthsToPay;
        }

        if (this.addToPlanList.length > 0 && this.addToPlanList[0].existingPlan) {
            const existingPlan = this.addToPlanList[0].existingPlan;
            this.paymentPlanService.getPaymentPlanDetailsTerms(
                existingPlan.customerAccountID,
                existingPlan.paymentPlanGUID
            ).then(data => {
                const plan = Object.assign(data, {
                    balance: existingPlan.balance,
                    nextExpectedPaymentDate: moment(this.paymentDate).toDate(),
                    nextExpectedPaymentAmount: this.paymentAmount,
                    consumerPaymentMethod: this.selectedPaymentMethod,
                    duration: planDuration,
                    finalPaymentDate: moment(this.paymentEndDate).toDate(),
                    finalPaymentAmount: this.lastPayment
                });
                const htmlContent = this.componentService.formatTermsAndConditions(this.termsAndConditionsContentText, plan);
                this.termsAndConditionsContent = this.componentService.BypassSecurityTrustHtml(htmlContent);
            });
        } else {
            const htmlContent = this.componentService.formatTermsAndConditions(this.termsAndConditionsContentText,
                {
                    createdDate: new Date(),
                    balance: this.newChargeAmount,
                    nextExpectedPaymentDate: moment(this.paymentDate).toDate(),
                    nextExpectedPaymentAmount: this.paymentAmount,
                    consumerPaymentMethod: this.selectedPaymentMethod,
                    name: this.consumer.fullName,
                    emailAddress: this.consumer.emailAddress,
                    startingBalance: this.newChargeAmount,
                    duration: planDuration,
                    firstPaymentDate: moment(this.paymentDate).toDate(),
                    firstPaymentAmount: this.paymentAmount,
                    finalPaymentDate: moment(this.paymentEndDate).toDate(),
                    finalPaymentAmount: this.lastPayment,
                    createdBy: 'Self'
                } as IPaymentPlanTermsAndConditions);

            this.termsAndConditionsContent = this.componentService.BypassSecurityTrustHtml(htmlContent);
        }
    }

    toggleDetails() {
        this.detailsOpen = !this.detailsOpen;
    }

    choosePlan(paymentPlanIdx: number) {
        this.newPlan = 'inactive';
        this.newButtonPlan = 'inactive';
        this.radioPlan = 'active';
        this.radioActive = 'activeradio';
        this.selectedPlanIdx = paymentPlanIdx;
        const selectedPlan = this.conciergePaymentPlans.find((x) => x.paymentPlanIdx === paymentPlanIdx);
        this.paymentAmount = ComponentService.toDecimal(this.amountToPay / selectedPlan.duration);
        this.lastPayment = ComponentService.toDecimal(this.amountToPay - ((selectedPlan.duration - 1) * this.paymentAmount));
        this.checkAvailablePaymentOptions();
    }

    private customPlan() {
        // If there's a step 0 and no concierge, they jump over step 1a back to step 0
        if (this.needStepZero && this.customOpen !== 'inactive' && this.conciergePresets == null) {
            this.prevStep();
        }

        // If the same conditions, but no step 0, go to home (per MSB-1166)
        if (this.customOpen !== 'inactive' && this.conciergePresets == null) {
            this.navigatetoHome();
        }

        if (this.customOpen === 'inactive') {
            this.newPlan = 'inactive';
            this.newButtonPlan = 'inactive';
            this.customOpen = 'active';
            this.notCustomOpen = 'inactive';
            this.customRadioActive = 'activeradio';
            this.customPlanSelected = true;
        } else {
            this.selectedPaymentOption = -1;
            this.newPlan = 'active';
            this.newButtonPlan = 'activebutton';
            this.customOpen = 'inactive';
            this.notCustomOpen = 'active';
            this.customRadioActive = 'inactive';
            this.customPlanSelected = false;
            this.buildCustomPlanPresets();
        }
        this.checkAvailablePaymentOptions();
    }

    showRightBorder(i: number): boolean {
        return i !== (this.conciergePaymentPlans.length - 1) && this.selectedPlanIdx === -1;
    }

    hidePlan(i: number): boolean {
        return i !== this.selectedPlanIdx && this.selectedPlanIdx !== -1;
    }

    planSelected(i: number): boolean {
        if (i == null && this.selectedPlanIdx !== -1) {
            return true;
        }

        if (i == null && this.selectedPlanIdx === -1) {
            return false;
        }

        return this.selectedPlanIdx !== -1 && i === this.selectedPlanIdx;
    }

    paymentOptionSelected(i: number): string {
        if (i === this.selectedPaymentOption) {
            return 'radio_button_checked';
        }

        return 'radio_button_unchecked';
    }

    onSelectionChange(i: number): void {
        this.selectedPaymentOption = i;
        this.radioStyle = 'radio-icon-wrap';
    }

    featureSelected(): boolean {
        return this.selectedPaymentOption !== -1;
    }

    clearSelectedPlan(): void {
        this.selectedPlanIdx = -1;
        this.selectedPaymentOption = -1;
        this.newPlan = 'active';
        this.newButtonPlan = 'activebutton';
        this.radioPlan = 'inactive';
        this.buildCustomPlanPresets();
    }

    initialLoading(): boolean {
        return this.balancesAreLoading;
    }

    plansLoading(): boolean {
        return this.plansAreLoading;
    }

    selectedPlan(paymentPlanIdx: number): string {
        if (this.selectedPlanIdx === paymentPlanIdx) {
            return 'active';
        } else {
            return 'inactive';
        }
    }

    radioClass(): string {
        return this.radioStyle;
    }

    private sliderCalculate(sliderMonths: number): void {
        this.step1Valid = true;

        // Don't want to change/lose the amountToPay value
        this.amountToPayMinusInitialPayment = this.amountToPay;
        if (this.initialPayment) {
            this.amountToPayMinusInitialPayment -= this.initialPaymentAmount;

            // Remove month for calculation purposes
            sliderMonths -= 1;
        }

        const amt = ComponentService.toDecimal(this.amountToPayMinusInitialPayment / sliderMonths);
        if (amt < this.minPaymentAmount) {
            this.paymentAmount = this.minPaymentAmount;
        } else {
            this.paymentAmount = amt;
        }

        this.lastPayment = ComponentService.toDecimal(this.amountToPayMinusInitialPayment - ((sliderMonths - 1) * this.paymentAmount));

        if (this.initialPayment /* && (this.paymentAmount !== this.lastPayment) */) {
            // Add this month back in if Initial Payment and paymentAmount !== lastPayment
            sliderMonths += 1;
        }

        this.monthsToPay = sliderMonths;

        this.setPaymentPlanText(this.monthsToPay);
    }

    public sliderDone($event: any): void {
        this.sliderCalculate($event.from);
    }

    public initialPaymentAmountChange(): void {
        this.maxInitialPaymentAmount = this.amountToPay - (this.minPaymentAmount * (this.minPlanLength - 1));

        if (this.initialPaymentAmount < this.minPaymentAmount) {
            // Initial Payment is too small
            this.step1Valid = false;

            this.stepValidationMessage = this.minMonthlyPaymentAmountErrorText.replace(
                '!MIN!', this.currencyPipe.transform(this.minPaymentAmount, 'USD', 'symbol'));
        } else if (this.initialPaymentAmount > this.maxInitialPaymentAmount) {
            // Initial Payment is too much
            this.step1Valid = false;

            this.stepValidationMessage = this.paymentPlanInitialPaymentTooHigh.replace(
                '!AMOUNT!', this.currencyPipe.transform(this.maxInitialPaymentAmount, 'USD', 'symbol'));
        } else {
            // Initial Payment is just right
            this.buildCustomPlanPresets();
            this.sliderCalculate(this.minPlanLength);
        }
    }

    private paymentAmountChange(): void {
        this.step1Valid = true;

        // Don't want to change/lose the amountToPay value
        this.amountToPayMinusInitialPayment = this.amountToPay;
        if (this.initialPayment) {
            this.amountToPayMinusInitialPayment -= this.initialPaymentAmount;
        }

        // Adjust the slider
        let months: number = this.minPlanLength;

        if (this.initialPayment) {
            // Need to remove the initial payment
            months--;
        }

        while (this.paymentAmount > 0 && months * this.paymentAmount < (this.amountToPayMinusInitialPayment - 5)) {
            months++;
        }

        this.lastPayment = ComponentService.toDecimal(this.amountToPayMinusInitialPayment - ((months - 1) * this.paymentAmount));

        if (this.initialPayment) {
            // Need to add the initial payment back in
            months++;
        }

        this.validatePaymentPlanLimits(months);
    }

    private validatePaymentPlanLimits(months: number): void {
        this.paymentPlanError = false;

        if (this.initialPayment && (this.initialPaymentAmount < this.minPaymentAmount)) {
            this.step1Valid = false;
            this.paymentPlanError = true;

            // Initial Payment Amount is too small
            this.stepValidationMessage = this.minMonthlyPaymentAmountErrorText.replace(
                '!MIN!', this.currencyPipe.transform(this.minPaymentAmount, 'USD', 'symbol'));
        }

        if (this.isValidPayment(months)) {
            this.setPaymentPlanText(months);
            this.monthsToPay = months;
            this.sliderComponent.update({from: months});
        } else {
            this.step1Valid = false;
            this.stepValidationMessage = this.paymentPlanMaxAmountErrorText;
        }

        // Last payment must be $5 or more
        if (this.lastPayment < 5) {
            this.step1Valid = false;
            this.stepValidationMessage = this.paymentPlanMaxAmountErrorText;
        }
    }

    private isValidPayment(months: number): boolean {
        if (this.lastPayment > 0
            && this.paymentAmount >= this.minPaymentAmount
            && this.minPlanLength <= months
            && months <= this.maxPlanLength) {
                return true;
            }

        return false;
    }

    private setPaymentPlanText(months: number): void {
        if (this.initialPayment) {
            months -= 1;
        }

        if (this.lastPayment === this.paymentAmount) {
            this.lastPaymentPerMonthValued = '&nbsp;';
            this.perMonthValued = this.perMonthText.replace('!MONTHS!', months.toString());
        } else {
            this.lastPaymentPerMonthValued = this.lastPaymentPerMonthText.replace('!AMOUNT!', this.currencyPipe.transform(this.lastPayment, 'USD', 'symbol', '1.2-2'));
            this.perMonthValued = this.perMonthText.replace('!MONTHS!', (months - 1).toString());
        }
    }

    wereSorryPageNavigate() {
        if (this.anErrorOccurred) {
            this.navigatetoHome();
        } else {
            this.navigateToPayment();
        }
    }

    private navigatetoHome(): void {
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/home')).then();
    }

    private navigateToPayment(): void {
        this.componentService.redirectToPayment();
    }

    detailsLinkShown(): boolean {
        return false;
    }

    isSubmission(): boolean {
        return this.submission;
    }

    public initialPaymentCheckboxChecked(): void {
        if (this.initialPayment) {
            this.maxInitialPaymentAmount = this.amountToPay - (this.minPaymentAmount * this.minPlanLength);
            this.initialPaymentAmount = this.minPaymentAmount;
        }

        this.buildCustomPlanPresets();
        this.sliderCalculate(this.minPlanLength);
    }

    authorizationCheckboxChecked(): void {
        // When we check the authorization box, check the step 3 validation.
        this.reviewPaymentValidation();
    }

    openTermsModal(): void {
        this.getPaymentPlanDetailsTerms();
        this.termsAndConditionsModal.openModal();
    }

    private checkAvailablePaymentOptions(): void {
        if (this.availablePaymentOptions() === 1) {
            if (this.paymentPlanMonthlyPay) {
                this.selectedPaymentOption = 1;
            } else if (this.paymentPlanFirstPay) {
                this.selectedPaymentOption = 2;
            } else if (this.paymentPlanOnly) {
                this.selectedPaymentOption = 3;
            }
        }
    }

    private selectedPaymentPlanMode(): PaymentPlanMode {
        switch (this.selectedPaymentOption) {
            case 1:
                return PaymentPlanMode.Monthly;
            case 2:
                return PaymentPlanMode.First;
            case 3:
                return PaymentPlanMode.Manual;
            default:
                return null;
        }
    }

    private availablePaymentOptions(): number {
        return [this.paymentPlanMonthlyPay, this.paymentPlanFirstPay, this.paymentPlanOnly].filter(opt => opt).length;
    }

    // This event will fire whenever the First Payment DatePicker changes.
    ppFirstPaymentDateChanged(event: any) {
        this.ppFirstPaymentDate = new Date(event.target.value);

        // Mark dates greater than 28, garbage dates, and dates outside the Min and Max dates as invalid.
        if (this.ppFirstPaymentDate.getDate() > 28 ||
           (this.ppFirstPaymentDate < this.minDate || this.ppFirstPaymentDate > this.maxDate)
        ) {
            this.pickerDateInvalid = true;
            this.reviewPaymentValidation();
        } else {
            this.pickerDateInvalid = false;
            this.reviewPaymentValidation();
            this.setPlanValues();
        }
    }

    datePickerFilter = (d: Date | null): boolean => {
        const dayOfMonth = (d || new Date()).getDate();
        return dayOfMonth < 29;
    }
}
