import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConsumerSystemMessageComponent } from '../../components/Controls/ConsumerSystemMessage/consumersystemmessage.component';
import { IConsumer } from '../../models/consumer';
import { IDomainInfo } from '../../models/domaininfo';
import { IPaymentPlanDetails } from '../../models/paymentplandetails';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { PaymentPlanService } from '../../services/paymentplan/paymentplan.service';

@Component({
    selector: 'logged-in',
    template: require('./loggedin.component.html'),
    styles: [require('./loggedin.component.css')]
})
export class LoggedInComponent implements OnInit, OnDestroy {
    consumer: IConsumer = null;
    adLinkUrl: string;
    adLinkText: string;
    adImageUrl: string;
    adText: string;
    adTitle: string;
    checkValidEmailAddress: string;
    checkValidPhoneNumber: string;
    checkValidEmailAndPhoneProfileLink: string;

    deliveryMethod: string;
    loadingDomain = true;
    loadingPaymentPlans = true;
    loadingRecurringPlans = true;
    loading = true;
    showAutopay = false;
    showPaymentPlans = false;
    showRecurring = false;
    existingPaymentPlans: IPaymentPlanDetails[] = [];
    profileIsEnabled = false;
    checkForEmailOrSmsFailures = false;
    alertForFailedEmails = false;
    alertForFailedSms = false;

    autoPayClass = '';
    paymentPlanClass = '';
    deliveryMethodClass = '';
    statementWidgetClass = '';
    recurringPlanClass = '';

    @ViewChild('message') message: ConsumerSystemMessageComponent;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private paymentPlanService: PaymentPlanService,
    ) {}

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.componentService.scrollPageToTop();
        this.populateRecurringPaymentPlans();

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                async domainInfo => {
                    this.profileIsEnabled = domainInfo.enableProfileTab;
                    this.showAutopay = domainInfo.enableAgreements;
                    if (domainInfo != null && domainInfo.enablePaymentPlans != null) {
                        this.showPaymentPlans = domainInfo.enablePaymentPlans;
                    }
                    await this.populateConsumer(domainInfo);
                    this.doneLoadingDomain();
                });

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (content != null) {
                    this.adTitle = this.componentService.contentService.tryGetContentItem(content, 'ad', 'home', 'adTitle').text;
                    this.adText = this.componentService.contentService.tryGetContentItem(content, 'ad', 'home', 'adText').text;
                    this.adImageUrl = this.componentService.contentService.tryGetContentItem(
                        content, 'ad', 'image', 'homeAdImageUrl').text;

                    this.adLinkText = this.componentService.contentService.tryGetContentItem(content, 'ad', 'home', 'adLinkText').text;
                    this.adLinkUrl = this.componentService.contentService.tryGetContentItem(content, 'ad', 'home', 'adLinkUrl').text;

                    this.checkValidEmailAddress =
                        this.componentService.contentService.tryGetContentItem(
                            content,
                            'global',
                            'message',
                            'checkValidEmailAddress'
                        ).text;

                    this.checkValidPhoneNumber =
                        this.componentService.contentService.tryGetContentItem(
                            content,
                            'global',
                            'message',
                            'checkValidPhoneNumber'
                        ).text;

                    this.checkValidEmailAndPhoneProfileLink =
                        this.componentService.contentService.tryGetContentItem(
                            content,
                            'global',
                            'message',
                            'checkValidEmailAndPhoneProfileLink'
                        ).text;
                }
            });
    }

    async populateConsumer(domainInfo: IDomainInfo) {
        // This will keep the Email/SMS failure check running only on the Home page.
        this.checkForEmailOrSmsFailures = cloneDeep(this.consumer) === null;

        this.consumer = await this.consumerService.getConsumer();
        const consumerAccount = await this.consumerService.getConsumerAccount();

        if (domainInfo.useConsolidatedCommunication) {
            const preferences =
                await this.consumerService.getConsumerCommunicationPreferences(
                    consumerAccount.customerAccountID,
                    this.checkForEmailOrSmsFailures
                );

            // Don't set alertForFailedEmails to the preferences
            // value or it will be clear on subsequent calls.
            if (preferences.alertForFailedEmails) {
                this.alertForFailedEmails = true;
            }

            this.checkValidEmailAddress =
                this.checkValidEmailAddress.replace(
                    '!EMAILADDRESS!',
                    this.consumer.emailAddress
                )

            // Don't set alertForFailedSms to the preferences
            // value or it will be clear on subsequent calls.
            if (preferences.alertForFailedSms) {
                this.alertForFailedSms = true;
            }

            this.checkValidPhoneNumber =
                this.checkValidPhoneNumber.replace(
                    '!PHONENUMBER!',
                    this.formatPhoneNumber(this.consumer.phone)
                )

            this.deliveryMethod = this.consumerService.convertConsumerCommunicationPreferencesToDeliveryMethodWords(preferences);
        } else {
            this.deliveryMethod = consumerAccount.deliveryMethod;
        }

        const plans = await this.paymentPlanService.getPaymentPlanDetailsForAccount(consumerAccount.customerAccountID);
        this.existingPaymentPlans = plans;
        this.doneLoadingPaymentPlans();
    }

    formatPhoneNumber(phoneNumberString: string) {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }

        return null;
    }

    populateRecurringPaymentPlans(): void {
        this.consumerService.getConsumerHasRecurringPaymentPlans().then(hasPlans => {
            if (hasPlans != null) {
                this.showRecurring = hasPlans;
                this.doneLoadingRecurringPaymentPlans();
            }
        });
    }

    doneLoadingDomain() {
        this.loadingDomain = false;
        this.doneLoading();
    }

    doneLoadingPaymentPlans() {
        this.loadingPaymentPlans = false;
        this.doneLoading();
    }

    doneLoadingRecurringPaymentPlans() {
        this.loadingRecurringPlans = false;
        this.doneLoading();
    }

    doneLoading() {
        if (!this.loadingDomain && !this.loadingPaymentPlans && !this.loadingRecurringPlans) {
            this.setPaymentPlanClass();
            this.setAutoPayClass();
            this.setDeliveryMethodClass();
            this.setStatementWidgetClass();
            this.setRecurringPlanClass();
            this.loading = false;
        }
    }

    setAutoPayClass(): void {
        if (!this.showAutopay) {
            this.autoPayClass = '';
        } else {
            const classes = [];
            classes.push('col-xs-12');

            classes.push(this.getWidgetSize());

            this.autoPayClass = classes.join(' ');
        }
    }

    setRecurringPlanClass(): void {
        const classes = [];
        if (!this.showRecurring) {
            this.recurringPlanClass = '';
            return;
        }

        classes.push('col-xs-12');

        classes.push(this.getWidgetSize());

        this.recurringPlanClass = classes.join(' ');
    }

    setDeliveryMethodClass(): void {
        const classes = [];
        classes.push('col-xs-12');

        if (!this.showAutopay && !this.showPaymentPlans) {
            classes.push('col-sm-6');
        } else if (!this.showAutopay && this.displayingPaymentPlanWidgetLarge()) {
            classes.push('col-sm-6');
        } else {
            classes.push('hidden');
        }

        this.deliveryMethodClass = classes.join(' ');
    }

    setPaymentPlanClass(): void {
        const classes = [];
        if (!this.showPaymentPlans) {
            this.paymentPlanClass = '';
            return;
        }

        classes.push('col-xs-12');

        if (!this.displayingPaymentPlanWidgetLarge()) {
            classes.push(this.getWidgetSize());
        }

        this.paymentPlanClass = classes.join(' ');
    }

    setStatementWidgetClass(): void {
        const classes = [];

        classes.push('col-xs-12');
        classes.push(this.getWidgetSize());

        this.statementWidgetClass = classes.join(' ');
    }

    onEmailUpdated() {
        if (!!this.message) {
            this.message.evaluateShowEmailVerificationMessage();
        }
    }

    private isLoading(): boolean {
        return this.loading;
    }

    private displayingPaymentPlanWidgetLarge(): boolean {
        return this.showPaymentPlans && this.existingPaymentPlans && this.existingPaymentPlans.length > 0;
    }

    private displayingPaymentPlanWidgetSmall(): boolean {
        return this.showPaymentPlans && this.existingPaymentPlans && this.existingPaymentPlans.length === 0;
    }

    private getWidgetSize(): string {
        if (this.displayingPaymentPlanWidgetLarge()) {
            if (this.showAutopay && this.showRecurring) {
                return 'col-sm-4';
            } else if (this.showAutopay || this.showRecurring) {
                return 'col-sm-6';
            } else {
                return 'col-sm-6';
            }
        } else if (this.showPaymentPlans) {
            if (this.showAutopay && this.showRecurring) {
                return 'col-sm-6';
            } else if (this.showAutopay || this.showRecurring) {
                return 'col-sm-4';
            } else {
                return 'col-sm-6';
            }
        } else {
            if (this.showAutopay && this.showRecurring) {
                return 'col-sm-4';
            } else if (this.showAutopay || this.showRecurring) {
                return 'col-sm-6';
            } else {
                return 'col-sm-6';
            }
        }
    }

    private isTwoByTwo(): boolean {
        return this.showAutopay && this.showPaymentPlans && this.showRecurring && this.displayingPaymentPlanWidgetSmall();
    }
}
