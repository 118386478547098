import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from '../../app.config';
import { IAccountBalanceInfo } from '../../models/accountbalanceinfo';
import { ACHPaymentMethod } from '../../models/achpaymentmethod';
import { Activity } from '../../models/activity';
import { Address } from '../../models/address';
import { AgreementStatus, IAutopayAgreement } from '../../models/autopayagreement';
import { AutopayEnrollPayment } from '../../models/autopayenrollpayment';
import { CancelAutoPayPayment } from '../../models/cancelautopaypayment';
import { ICommunicationPreferences } from '../../models/communicationPreferences';
import { ICommunicationPreferenceChange } from '../../models/communicationPreferenceType';
import { IConsumer } from '../../models/consumer';
import {
    ConsumerAccount,
    ConsumerAccountDeliveryPreferences,
    ConsumerAccountSMSPreferences
} from '../../models/consumeraccount';
import { ConsumerAccountRequest } from '../../models/consumeraccountrequest';
import { ConsumerActivityRequest } from '../../models/consumeractivityrequest';
import { CreditCard } from '../../models/creditcard';
import { AutopayPaymentStatus, IDocument } from '../../models/document';
import { EmailAddressType } from '../../models/emailaddresstype';
import { FastTrackPaymentInfoResponse } from '../../models/fasttrackpaymentinforesponse';
import { GenerateUsernameRequest } from '../../models/generateusernamerequest';
import { GenericResponse, IGenericResponse, Response } from '../../models/genericresponse';
import { GuarantorEmail } from '../../models/guarantoremail';
import { GuarantorEmailRequest } from '../../models/guarantoremailrequest';
import { IMerchantProfile } from '../../models/imerchantprofile';
import { ILogin } from '../../models/login';
import { FloatingChildItem, PayableEntry, PayableEntryType } from '../../models/PayableEntry';
import { PayableItemType } from '../../models/payableitem';
import { PayableStatement } from '../../models/payablestatement';
import { IPayment } from '../../models/payment';
import { FloatingBucket } from '../../models/paymentbucket';
import { IPaymentLocation } from '../../models/PaymentLocation';
import { CreditCardIssuerType, OtherPaymentMethod, PaymentMethod, PaymentMethodType } from '../../models/paymentmethod';
import { IProfileCompleteness } from '../../models/profilecompleteness';
import {
    IRecurringPaymentPlan,
    RecurringFrequency,
    RecurringMode,
    RecurringPaymentPlanStatus,
    RecurringPlanType
} from '../../models/recurringpaymentplan';
import { IRecurringPaymentPlanSubmit } from '../../models/recurringpaymentplansubmit';
import { StatementBalance } from '../../models/statementbalance';
import { SubmitFeedbackRequest } from '../../models/submitfeedbackrequest';
import { jsonRequestOptions } from '../../util/HttpClientUtility';
import { ComponentService } from '../component/component.service';
import { ConsumerStorageService } from '../indexedDb/consumer-storage.service';
import { LoggingLevel, LoggingService } from '../logging/logging.service';
import { StorageService } from '../storage/storage.service';


@Injectable()
export class ConsumerService {

    constructor(
        private http: HttpClient,
        private config: AppConfig,
        private componentService: ComponentService,
        private loggingService: LoggingService,
        private router: Router,
        private consumerStorageService: ConsumerStorageService,
        public storage: StorageService
    ) {
        this.domain = this.componentService.storageService.getDomain();
    }

    private token = '';
    private domain = '';
    private domainUrl = this.config.getConfig('domainInfo');
    private accountBalanceInfoPath = this.config.getConfig('accountBalanceInfoPath');
    private activateRecurringPaymentPlanPath = this.config.getConfig('activateRecurringPaymentPlanPath');
    private autopayEnrollmentPath = this.config.getConfig('enrollAutopayAgreementPath');
    private cancelAutopayPaymentPath = this.config.getConfig('cancelAutopayPaymentPath');
    private cancelWalletPath = this.config.getConfig('cancelWalletPath');
    private verifyNumberNotBlacklistedNumberPath = this.config.getConfig('verifyNumberNotBlacklistedNumberPath');
    private consumerAccountPath = this.config.getConfig('consumerAccountPath');
    private consumerHasRecurringPaymentPlansPath = this.config.getConfig('consumerHasRecurringPaymentPlanPath');
    private recurringActivationEmailValidPath = this.config.getConfig('recurringActivationEmailValidPath');
    private createConsumerAccountPath = this.config.getConfig('createConsumerAccountPath');
    private deactivateSmsPath = this.config.getConfig('deactivateSmsPath');
    private deactivateSmsByVerificationIDPath = this.config.getConfig('deactivateSmsByVerificationIDPath');
    private generateUsernamePath = this.config.getConfig('generateUsernamePath');
    private merchantPath = this.config.getConfig('merchantProfilePath');
    private merchantProfileForConsumerPath = this.config.getConfig('merchantProfileForConsumerPath');
    private profileCompletenessPath = this.config.getConfig('profileCompletenessPath');
    private sendVerificationPath = this.config.getConfig('sendVerificationPath');
    private sendPasswordResetPath = this.config.getConfig('sendPasswordResetPath');
    private storeWalletPath = this.config.getConfig('storeWalletPath');
    private sendSmsPath = this.config.getConfig('sendSmsConfirmationPinPath');
    private recurringPaymentPlansPath = this.config.getConfig('recurringPaymentPlanPath');
    private recurringPaymentPlanByGuidPath = this.config.getConfig('recurringPaymentPlanByGuidPath');
    private submitFeedbackPath = this.config.getConfig('submitFeedbackPath');
    private updateAutopayAgreementPath = this.config.getConfig('updateAutopayAgreementPath');
    private updateConsumerPath = this.config.getConfig('updateConsumerPath');
    private updateConsumerByVerificationIDPath = this.config.getConfig('updateConsumerByVerificationIDPath');
    private updateConsumerPasswordPath = this.config.getConfig('updateConsumerPasswordPath');
    private updatePasswordResetPath = this.config.getConfig('updatePasswordResetPath');
    private updatePrimaryWalletPath = this.config.getConfig('updatePrimaryWalletPath');
    private updateRecurringPaymentPlanPath = this.config.getConfig('updateRecurringPaymentPlanPath');
    private updateWalletPaymentMethodPath = this.config.getConfig('updateWalletPaymentMethodPath');
    private usernamePath = this.config.getConfig('username');
    private getFastTrackPaymentInfoPath = this.config.getConfig('getFastTrackPaymentInfoPath');
    private validatePasswordResetPath = this.config.getConfig('validatePasswordResetPath');
    private validatePendingEnrollmentPath = this.config.getConfig('validatePendingEnrollmentPath');
    private validateSmsPinPath = this.config.getConfig('validateSmsPinPath');
    private validateDocumentGUIDPath = this.config.getConfig('validateDocumentGUIDPath');
    private validateVerificationPath = this.config.getConfig('validateVerificationPath');
    private getAccountTypeByVerificationIdPath = this.config.getConfig('getAccountTypeByVerificationIdPath');
    private getConsumerRolesPath = this.config.getConfig('getConsumerRolesPath');
    private getGuarantorEmailsPath = this.config.getConfig('retrieveGuarantorEmailsPath');
    private updateCommunicationPreferencesPath = this.config.getConfig('updateCommunicationPreferencesPath');
    private updateCommunicationPreferencesForUnsubscribeEmailPath = this.config.getConfig('updateCommunicationPreferencesForUnsubscribeEmailPath');
    private updateConsumerAccountForUnsubscribeEmailPath = this.config.getConfig('updateConsumerAccountForUnsubscribeEmailPath');
    private consumerIsCaptchaRequiredPath = this.config.getConfig('consumerIsCaptchaRequiredPath');
    private verifyEmailExistsPath = this.config.getConfig('verifyEmailExistsPath');
    private activateRecurringPaymentAgreementPath = this.config.getConfig('activateRecurringPaymentAgreementPath');
    private sendVerificationCodePath = this.config.getConfig('sendVerificationCodePath');
    private validateVerificationCodePath = this.config.getConfig('validateVerificationCodePath');
    private updateConsumerAccountLastEStatementPromptPath = this.config.getConfig('updateConsumerAccountLastEStatementPromptPath');

    private paymentMethods = new BehaviorSubject<PaymentMethod[]>(OtherPaymentMethod);

    public paymentMethods$ = this.paymentMethods.asObservable();

    /**
     * Returns the entire consumer model from API or from storage
     *
     * @returns {Promise<IConsumer>}
     *
     * @memberOf ConsumerService
     */
    public async getConsumer(loginToken: string = null): Promise<IConsumer> {
        if (await this.consumerStorageService.consumerExistsInStorage()) {
            return await this.consumerStorageService.getConsumerFromStorage();
        } else {
            const data = await this.callConsumerApi(loginToken);
            if (data != null && data.accountID != null) {
                if (data.country != null) {
                    if ('US,CA,MX'.indexOf(data.country) === -1) {
                        if ('UNITED STATES, USA, U.S.A., UNITED STATES OF AMERICA'.indexOf(data.country.toUpperCase()) >= 0) {
                            data.country = 'US';
                        } else if ('CANADA, CAN'.indexOf(data.country.toUpperCase()) >= 0) {
                            data.country = 'CA';
                        } else if ('MEXICO, MEX'.indexOf(data.country.toUpperCase()) >= 0) {
                            data.country = 'MX';
                        } else {
                            data.country = 'US';
                        }
                    }
                    if (!data.country) {
                        data.country = 'US';
                    }
                }
                this.bindDocuments(data.documents);

                await this.consumerStorageService.addConsumerToStorage(data);

                // Now that we have a consumer, lazy load all the merchant profiles they may use
                if (!this.componentService.storageService.exists('merchantprofiles')) {
                    await this.getAllMerchantProfilesForConsumer(data);
                }
            }

            return data;
        }
    }

    public isProcessorMultiMID(merchantCredentialVendors: string[]): boolean {
        let isMultiMID = false;
        if (merchantCredentialVendors.length > 0) {
            let sameVendor = true;
            const firstVendor = merchantCredentialVendors[0];

            merchantCredentialVendors.forEach(vendor => {
                if (vendor !== firstVendor) {
                    sameVendor = false;
                }
            });

            if (sameVendor) {
                const multiMIDProcessors = [
                    'Card Connect',
                    'RevSpring'
                ];

                isMultiMID = (multiMIDProcessors.indexOf(firstVendor) > -1);
            }
        }

        return isMultiMID;
    }

    /**
     * Get email address by pending enrollment guid
     *
     * @param {string} pendingEnrollmentGUID
     * @returns {Promise<string>}
     *
     * @memberOf ConsumerService
     */
    public async getEmailByPendingEnrollment(pendingEnrollmentGUID: string): Promise<string> {
        return this.callGetEmailByPendingEnrollment(pendingEnrollmentGUID);
    }

    /**
     * Get account delivery preferences object by verification ID
     * @param verificationID
     * @returns {Promise<ConsumerAccountDeliveryPreferences>}
     *
     * @memberOf ConsumerService
     */
    public async getConsumerAccountDeliveryPreferencesByVerificationID(
        verificationID: string): Promise<ConsumerAccountDeliveryPreferences> {
        return this.callGetConsumerAccountDeliveryPreferencesByVerificationIDApi(verificationID);
    }

    /**
     * Get account SMS preferences object by verification ID
     * @param verificationID
     * @returns {Promise<ConsumerAccountDeliveryPreferences>}
     *
     * @memberOf ConsumerService
     */
    public async getConsumerAccountSMSPreferencesByVerificationID(verificationID: string): Promise<ConsumerAccountSMSPreferences> {
        return this.callGetConsumerAccountSMSPreferencesByVerificationIDApi(verificationID);
    }

    /**
     * Get consumer account by consumeraccountguid
     * @param consumerAccountGUID
     * @returns {Promise<ConsumerAccount>}
     *
     * @memberOf ConsumerService
     */
    public async callGetEmailFromConsumerAccountGUID(
        consumerAccountGUID: string): Promise<string> {
        return this.callGetEmailFromConsumerAccountGUIDApi(consumerAccountGUID);
    }

    /**
     * Get consumer account by consumeraccountguid
     * @param consumerAccountGUID
     * @returns {Promise<ConsumerAccount>}
     *
     * @memberOf ConsumerService
     */
    public async callUpdateConsumerAccountForUnsubscribeEmail(
        consumerAccountGUID: string): Promise<boolean> {
        return this.callUpdateConsumerAccountForUnsubscribeEmailApi(consumerAccountGUID);
    }

    /**
     * Update Communication Preference For Unsubscribe Email
     * @param consumerAccountGUID
     * @param changes
     * @returns {Promise<boolean>}
     *
     * @memberOf ConsumerService
     */
    public async callUpdateCommunicationPreferenceForUnsubscribeEmail(
        consumerAccountGUID: string,
        changes: ICommunicationPreferenceChange[]): Promise<boolean> {
        return this.updateCommunicationPreferenceForUnsubscribeEmail(
            consumerAccountGUID,
            changes
        );
    }

    /**
     * Returns model for StatementBalance component
     *
     * @returns {Promise<StatementBalance>}
     *
     * @memberOf ConsumerService
     */
    public async getStatementBalance(totalBalanceType: string): Promise<StatementBalance> {
        const consumer = await this.getConsumer();
        const statementBalance = new StatementBalance();
        const payableDocuments = this.getPayableDocuments(consumer.documents);
        // TODO: should this business logic be here? yes
        if (consumer.accounts && consumer.accounts.length > 0) {
            statementBalance.balance = consumer.accounts[0].currentBalance; // TODO: can accounts be more than 1?
            // statementBalance.dueDate = consumer.accounts[0].currentBalanceAsOf; // TODO: is this really the due date?
        } else if (payableDocuments.length > 0) {
            if (totalBalanceType === 'Last Statement') {
                // Given that the API is sorting this list correctly, we will use the first item for the payment amount
                statementBalance.balance = payableDocuments[0].totalAmount;
                if (!!payableDocuments[0].promptPayDueDate && payableDocuments[0].promptPayDueDate >= new Date()) {
                    statementBalance.balance = payableDocuments[0].promptPayAmount;
                }
                // installment amount wins
                if (!!payableDocuments[0].externalPaymentPlanAmount && payableDocuments[0].externalPaymentPlanAmount > 0) {
                    statementBalance.balance = payableDocuments[0].externalPaymentPlanAmount;
                }
                statementBalance.dueDate = payableDocuments[0].dueDate;
                statementBalance.dueDateUtc = payableDocuments[0].dueDateUtc;
            } else {
                statementBalance.balance = payableDocuments.reduce((sum, doc) => sum + doc.totalAmount, 0);
                const dueDateDocument = payableDocuments.sort((a, b) => ComponentService.sortDateAscending(a.dueDate, b.dueDate))[0];
                statementBalance.dueDate = dueDateDocument.dueDate;
                statementBalance.dueDateUtc = dueDateDocument.dueDateUtc;
            }

            const balanceAsOf =
                payableDocuments.sort(
                    (a, b) => ComponentService.sortDateDescending(a.documentDate, b.documentDate)
                )[0];

            statementBalance.balanceAsOf = balanceAsOf.documentDate;
        } else {
            // No account and no documents, return empty
            statementBalance.balance = 0;
            statementBalance.dueDate = null;
            statementBalance.dueDateUtc = null;
            statementBalance.balanceAsOf = null;
        }
        // we must set lastPaymentDate via a separate call to getConsumerPaymentHistory, so we don't do that here.
        return statementBalance;
    }

    /**
     * Returns the real-time balance from the Consumer if there is one, otherwise returns null
     *
     * @returns {Promise<PayableStatement>}
     *
     * @memberOf ConsumerService
     */
    public async getRealtimeBalance(): Promise<PayableStatement> {
        const consumer = await this.getConsumer();
        let realtimeBalance = new PayableStatement();
        if (consumer.accounts && consumer.accounts.length > 0 && consumer.accounts[0].isActive) {
            const account = consumer.accounts[0];
            realtimeBalance.payableAmount = account.currentBalance;
            realtimeBalance.postDate = account.currentBalanceAsOf;
            realtimeBalance.itemDescription = account.accountNumber;
            realtimeBalance.paymentBuckets = null;
        } else {
            realtimeBalance = null;
        }
        return realtimeBalance;
    }

    public async getAccountBalanceInfo(): Promise<IAccountBalanceInfo> {
        return this.callGetAccountBalanceInfoApi();
    }

    /**
     * Pulls Document list from the consumer, maps that to a list of PayableStatement
     *
     * @returns {Promise<PayableStatement[]>}
     *
     * @memberOf ConsumerService
     */
    public async getPayableStatements(): Promise<PayableStatement[]> {
        const consumer = await this.getConsumer();
        const payableStatementList = this.getPayableDocuments(consumer.documents).map((document) => {
            let paymentBucketList = null;
            if (document.paymentBuckets != null) {
                paymentBucketList = document.paymentBuckets.map((bucket) => {
                    // returns a new PaymentBucket
                    return {
                        bucketId: document.documentID.toString() + bucket.sequence.toString(),
                        sequence: bucket.sequence,
                        bucketDescription: bucket.description,
                        accountNumber: bucket.accountNumber,
                        bucketBalance: bucket.amount,
                        promptPayAmount: bucket.promptPayAmount,
                        payToBucket: false,
                        percentAllocation: bucket.percentToAllocate,
                        bucketPaymentAmount: null,
                        maxPaymentAmount: null,
                        minPaymentAmount: null,
                        chargeDate: bucket.date != null ? new Date(bucket.date) : null,
                        bucketInstallmentAmount: null,
                        displayBalance: bucket.amount
                    };
                });
            }
            // returns new PayableStatement with the buckets from above
            return {
                id: this.componentService.generateUUID(),
                itemType: PayableItemType.statement,
                payableAmount: document.totalAmount,
                dueDate: document.dueDate != null ? new Date(document.dueDate) : null,
                itemName: document.customerLOBName,
                documentID: document.documentID,
                postDate: document.documentDate != null ? new Date(document.documentDate) : null,
                paymentAmount: null,
                paymentBuckets: paymentBucketList,
                merchantProfile: null,
                documentGUID: document.documentGUID,
                merchantCredentialGUID: document.merchantCredentialGUID,
                merchantProfileGUID: document.merchantProfileGUID,
                itemDescription: document.accountNumber,
                promptPayAmount: document.promptPayAmount,
                promptPayDueDate: document.promptPayDueDate != null ? new Date(document.promptPayDueDate) : null,
                payToItem: false,
                autopayPaymentStatus: document.autopayPaymentStatus,
                hasScheduledPayment: false,
                externalPaymentPlanAmount: document.externalPaymentPlanAmount,
                maxPayableAmount: null,
                isAccountPaymentPlanEligible: false,
                paymentLocationGUID: null,
                ineligibleReason: null,
                initialPayment: false,
                initialPaymentAmount: 0,
                dueUponReceipt: document.dueUponReceipt
            };
        });

        payableStatementList.forEach(async (statement) => {
            // Get the merchant profile for each document and set it on that document.
            const merchantProfile = await this.getMerchantProfileByMerchantProfileGUID(statement.merchantProfileGUID);
            statement.merchantProfile = merchantProfile;
            this.setMerchantProfileSettingsOnStatement(statement);
        });

        return payableStatementList;
    }

    /**
     * Pulls Document list from the consumer, maps that to a list of PayableStatement
     *
     * @returns {Promise<PayableStatement[]>}
     *
     * @memberOf ConsumerService
     */
    // TODO: APX-9846
    public async getPayableStatementsAsPayableEntries(): Promise<PayableEntry[]> {
        const consumer = await this.getConsumer();
        const payableStatementList = this.getPayableDocuments(consumer.documents).map((document) => {
            let childItemList = null;
            if (document.paymentBuckets != null) {
                childItemList = document.paymentBuckets.map((bucket) => {
                    // returns a new PayableEntry - childItem:
                    return {
                        accountNumber: bucket.accountNumber,
                        autopayPaymentStatus: null,
                        bucketInstallmentAmount: null,
                        chargeDate: bucket.date != null ? new Date(bucket.date) : null,
                        childItems: null,
                        documentGUID: null,
                        documentID: null,
                        dueDate: null,
                        externalPaymentPlanAmount: null,
                        id: document.documentID.toString() + bucket.sequence.toString(),
                        isChild: true,
                        isDisabled: bucket.amount <= 0,
                        itemDescription: null,
                        itemName: bucket.description,
                        itemType: null,
                        hasScheduledPayment: null,
                        maxPayableAmount: null,
                        merchantCredentialGUID: document.merchantCredentialGUID,
                        merchantProfile: null,
                        merchantProfileGUID: null,
                        minPayableAmount: null,
                        overpayDisableUI: false,
                        payableAmount: bucket.amount,
                        payToItem: false,
                        percentAllocation: bucket.percentToAllocate,
                        paymentAmount: null,
                        paymentLocationGUID: null,
                        postDate: null,
                        promptPayAmount: bucket.promptPayAmount,
                        promptPayDueDate: null,
                        sequence: bucket.sequence,
                        displayBalance: bucket.amount,
                        isAccountPaymentPlanEligible: true,
                        ineligibleReason: null,
                        paymentPlanGUID: null,
                        initialPayment: null,
                        initialPaymentAmount: null
                    };
                });
            }
            // returns new PayableEntry with the childItems from above
            return {
                accountNumber: null,
                autopayPaymentStatus: document.autopayPaymentStatus,
                bucketInstallmentAmount: null,
                chargeDate: null,
                childItems: childItemList,
                documentGUID: document.documentGUID,
                documentID: document.documentID,
                dueDate: document.dueDate != null ? new Date(document.dueDate) : null,
                externalPaymentPlanAmount: document.externalPaymentPlanAmount,
                id: this.componentService.generateUUID(),
                isChild: false,
                isDisabled: false,
                itemDescription: document.accountNumber,
                itemName: document.customerLOBName,
                itemType: PayableEntryType.statement,
                hasScheduledPayment: false,
                maxPayableAmount: null,
                merchantCredentialGUID: document.merchantCredentialGUID,
                merchantProfile: null,
                merchantProfileGUID: document.merchantProfileGUID,
                minPayableAmount: null,
                overpayDisableUI: false,
                payableAmount: document.totalAmount,
                payToItem: false,
                percentAllocation: null,
                paymentAmount: null,
                paymentLocationGUID: null,
                postDate: document.documentDate != null ? new Date(document.documentDate) : null,
                promptPayAmount: document.promptPayAmount,
                promptPayDueDate: document.promptPayDueDate != null ? new Date(document.promptPayDueDate) : null,
                sequence: null,
                displayBalance: null,
                isAccountPaymentPlanEligible: true,
                ineligibleReason: null,
                paymentPlanGUID: null,
                initialPayment: null,
                initialPaymentAmount: null
            };
        });

        payableStatementList.forEach(async (statement) => {
            // Get the merchant profile for each document and set it on that document.
            const merchantProfile = await this.getMerchantProfileByMerchantProfileGUID(statement.merchantProfileGUID);
            statement.merchantProfile = merchantProfile;
            this.setMerchantProfileSettingsOnPayableEntry(statement);
        });

        return payableStatementList;
    }

    /**
     * Gets the currently logged-in guarantor's/consumer's emails.
     *
     * @returns (Promise<GuarantorEmail[]>)
     * @memberof ConsumerService
     */
    public async getGuarantorEmails(): Promise<GuarantorEmail[]> {
        const acct = await this.getConsumer();
        const emailRequest: GuarantorEmailRequest[] = [
            {
                customerId: acct.customerID,
                customerAccountId: acct.accountID,
                emailAddressType: EmailAddressType.All
            }
        ];

        return this.callGetGuarantorEmailsApi(emailRequest);
    }

    /**
     * Gets a QuickPay user's emails.
     * Requires authentication via account verification ID.
     *
     * @returns (Promise<GuarantorEmail[]>)
     * @memberof ConsumerService
     */
    public async getGuarantorEmailsByVerificationId(accountVerificationId: string):
        Promise<GuarantorEmail[]> {
        if (!accountVerificationId) {
            throw new Error('No account verification ID passed');
        }
        return await this.callGetGuarantorEmailsByVerificationIdApi(accountVerificationId);
    }

    /**
     * Gets the currently logged-in guarantor's/consumer's emails
     * that have the given type.
     *
     * @returns (Promise<GuarantorEmail[]>)
     * @memberof ConsumerService
     */
    public async getGuarantorEmail(type: EmailAddressType, id: number = null): Promise<GuarantorEmail[]> {
        const acct = await this.getConsumerAccount(null, null);
        const emailRequest: GuarantorEmailRequest[] = [
            {
                customerId: acct.customerID,
                customerAccountId: acct.customerAccountID,
                emailAddressType: type,
                id
            }
        ];

        return this.callGetGuarantorEmailsApi(emailRequest);
    }

    /**
     * Sets the overpayment/ external payment plan settings based on the merchant profile
     *
     * @param {PayableStatement} statement
     * @returns
     * @memberof ConsumerService
     */
    setMerchantProfileSettingsOnStatement(statement: PayableStatement) {
        if (statement == null || statement.merchantProfile == null) {
            this.loggingService.log('consumerService -> setMerchantProfileSettingsOnStatement: merchant profile or statement null, can\'t set external payment plan values.', LoggingLevel.debug);
            return;
        }

        // Only add the floater if they can't overpay buckets but can overpay the document
        if (!statement.merchantProfile.detailOverpayments
            && statement.merchantProfile.maxOverpayment != null
            && statement.merchantProfile.maxOverpayment > 0) {
            // Push a copy of the default floating bucket
            statement.paymentBuckets.push(Object.assign({}, FloatingBucket));
        }

        if (statement.merchantProfile.allowExternalPaymentPlans && statement.externalPaymentPlanAmount > 0) {
            // Calculate each buckets new amount based on the posting method (percentAllocation)
            let externalPaymentPlanAmount = statement.externalPaymentPlanAmount;
            statement.paymentBuckets.filter((x) => x.bucketBalance > 0).forEach((bucket) => {
                let bucketPercentage: number;
                if (statement.payableAmount === 0) {
                    bucketPercentage = 0;
                } else {
                    bucketPercentage = ComponentService.toDecimal(bucket.percentAllocation / 100, 6);
                }

                let externalPaymentAmountToAllocate = ComponentService.toDecimal(externalPaymentPlanAmount * bucketPercentage, 2);
                // Don't allow overpayment on externalPaymentPlan buckets
                if (externalPaymentAmountToAllocate > bucket.bucketBalance) {
                    externalPaymentAmountToAllocate = bucket.bucketBalance;
                }
                bucket.bucketInstallmentAmount = bucket.displayBalance = bucket.bucketPaymentAmount = externalPaymentAmountToAllocate;
                // If it's FIFO, we have to subtract from the total since all buckets are 100%
                if (bucketPercentage === 1) {
                    externalPaymentPlanAmount = externalPaymentPlanAmount - bucket.bucketInstallmentAmount;
                }
            });

            // Check the total since we often get +/- $0.01
            let bucketTotal = statement.paymentBuckets.map(b => b.bucketInstallmentAmount).reduce((sum, val) => sum + val);
            // If > expected total, reduce first bucket
            if (bucketTotal > statement.externalPaymentPlanAmount) {
                while (bucketTotal > statement.externalPaymentPlanAmount) {
                    statement.paymentBuckets[0].bucketInstallmentAmount
                        = statement.paymentBuckets[0].displayBalance
                        = statement.paymentBuckets[0].bucketPaymentAmount
                        = statement.paymentBuckets[0].bucketInstallmentAmount - 0.01;
                    bucketTotal = statement.paymentBuckets.map(b => b.bucketInstallmentAmount).reduce((sum, val) => sum + val);
                }
            }

            // If < expected total, increase first bucket
            if (bucketTotal < statement.externalPaymentPlanAmount) {
                while (bucketTotal < statement.externalPaymentPlanAmount) {
                    statement.paymentBuckets[0].bucketInstallmentAmount
                        = statement.paymentBuckets[0].displayBalance
                        = statement.paymentBuckets[0].bucketPaymentAmount
                        = statement.paymentBuckets[0].bucketInstallmentAmount + 0.01;
                    bucketTotal = statement.paymentBuckets.map(b => b.bucketInstallmentAmount).reduce((sum, val) => sum + val);
                }
            }
        }
    }

    /**
     * Sets the overpayment/external payment plan settings based on the merchant profile
     *
     * @param {PayableEntry} item
     * @returns
     * @memberof ConsumerService
     */
    setMerchantProfileSettingsOnPayableEntry(item: PayableEntry) {
        if (item == null || item.merchantProfile == null) {
            this.loggingService.log('consumerService -> setMerchantProfileSettingsOnPayableEntry: merchant profile or item null, can\'t set external payment plan values.', LoggingLevel.debug);
            return;
        }

        // Only add the floater if they can't overpay buckets but can overpay the document
        if (!item.merchantProfile.detailOverpayments
            && item.merchantProfile.maxOverpayment != null
            && item.merchantProfile.maxOverpayment > 0) {
            // Push a copy of the default floating childItem
            item.childItems.push(Object.assign({}, FloatingChildItem));
        }

        if (item.merchantProfile.allowExternalPaymentPlans && item.externalPaymentPlanAmount > 0) {
            // Calculate each buckets new amount based on the posting method (percentAllocation)
            let externalPaymentPlanAmount = item.externalPaymentPlanAmount;
            item.childItems.filter((x) => x.payableAmount > 0).forEach((childItem) => {
                let bucketPercentage: number;
                if (item.payableAmount === 0) {
                    bucketPercentage = 0;
                } else {
                    bucketPercentage = ComponentService.toDecimal(childItem.percentAllocation / 100, 6);
                }

                let externalPaymentAmountToAllocate = ComponentService.toDecimal(externalPaymentPlanAmount * bucketPercentage, 2);
                // Don't allow overpayment on externalPaymentPlan buckets
                if (externalPaymentAmountToAllocate > childItem.payableAmount) {
                    externalPaymentAmountToAllocate = childItem.payableAmount;
                }
                childItem.bucketInstallmentAmount = childItem.displayBalance
                    = childItem.paymentAmount = externalPaymentAmountToAllocate;

                // If it's FIFO, we have to subtract from the total since all buckets are 100%
                if (bucketPercentage === 1) {
                    externalPaymentPlanAmount = externalPaymentPlanAmount - childItem.bucketInstallmentAmount;
                }
            });

            // Check the total since we often get +/- $0.01
            let bucketTotal = item.childItems.map(b => b.bucketInstallmentAmount).reduce((sum, val) => sum + val);
            // If > expected total, reduce first childItem
            if (bucketTotal > item.externalPaymentPlanAmount) {
                while (bucketTotal > item.externalPaymentPlanAmount) {
                    item.childItems[0].bucketInstallmentAmount
                        = item.childItems[0].displayBalance
                        = item.childItems[0].paymentAmount
                        = item.childItems[0].bucketInstallmentAmount - 0.01;
                    bucketTotal = item.childItems.map(b => b.bucketInstallmentAmount).reduce((sum, val) => sum + val);
                }
            }

            // If < expected total, increase first childItem
            if (bucketTotal < item.externalPaymentPlanAmount) {
                while (bucketTotal < item.externalPaymentPlanAmount) {
                    item.childItems[0].bucketInstallmentAmount
                        = item.childItems[0].displayBalance
                        = item.childItems[0].paymentAmount
                        = item.childItems[0].bucketInstallmentAmount + 0.01;
                    bucketTotal = item.childItems.map(b => b.bucketInstallmentAmount).reduce((sum, val) => sum + val);
                }
            }

            if (item.payableAmount <= 0 && item.id !== FloatingBucket.bucketId) {
                item.isDisabled = true;
            }
        }
    }

    /**
     * Gets the merchant profile for the domain since we don't have document IDs in some instances.
     *
     * @returns {Promise<IMerchantProfile>}
     *
     * @memberof ConsumerService
     */
    public async getMerchantProfileForDomain(): Promise<IMerchantProfile> {
        if (this.componentService.storageService.exists('domainmerchantprofileguid')) {
            const domainMerchantProfileGUID = this.componentService.storageService.retrieve('domainmerchantprofileguid');
            return this.getMerchantProfileByMerchantProfileGUID(domainMerchantProfileGUID);
        } else {
            const data = await this.callMerchantProfileApi(this.domain);
            this.storeDomainMerchantProfileGUID(data.merchantProfileGUID);
            this.addMerchantProfileToStorage(data);
            return data;
        }
    }

    /**
     *  Gets the merchant profile by merchantProfileGUID either from storage or API
     *
     * @param {string} merchantProfileGUID
     * @returns {Promise<IMerchantProfile>}
     *
     * @memberof ConsumerService
     */
    public async getMerchantProfileByMerchantProfileGUID(merchantProfileGUID: string): Promise<IMerchantProfile> {
        const merchantProfilesFromStorage: IMerchantProfile[] = this.componentService.storageService.retrieve('merchantprofiles');
        if (merchantProfilesFromStorage != null
            && merchantProfilesFromStorage.find(p => p.merchantProfileGUID === merchantProfileGUID) != null) {
            return merchantProfilesFromStorage.find(p => p.merchantProfileGUID === merchantProfileGUID);
        } else {
            const data = await this.callMerchantProfileApi(this.domain, null, merchantProfileGUID);
            this.addMerchantProfileToStorage(data);
            return data;
        }
    }

    /**
     * Stores the Merchant Profile GUID of the domain Merchant Profile
     *
     * @param {string} domainMerchantProfileGUID
     * @memberof ConsumerService
     */
    storeDomainMerchantProfileGUID(domainMerchantProfileGUID: string) {
        this.componentService.storageService.store('domainmerchantprofileguid', domainMerchantProfileGUID);
    }

    /**
     * Adds the passed in merchant profile to storage if it isn't already there
     *
     * @param {IMerchantProfile} merchantProfile
     * @returns
     * @memberof ConsumerService
     */
    addMerchantProfileToStorage(merchantProfile: IMerchantProfile) {
        if (merchantProfile == null) {
            return;
        }
        const merchantProfilesFromStorage: IMerchantProfile[] = this.componentService.storageService.exists('merchantprofiles')
            ? this.componentService.storageService.retrieve('merchantprofiles')
            : [];
        // if it isn't currently there, add it, otherwise don't duplicate
        if (merchantProfilesFromStorage.find(p => p.merchantProfileGUID === merchantProfile.merchantProfileGUID) == null) {
            merchantProfilesFromStorage.push(merchantProfile);
            this.componentService.storageService.store('merchantprofiles', merchantProfilesFromStorage);
        }
    }

    /**
     * Gets the profile completeness details for a consumer account.
     *
     * @returns {Promise<IProfileCompleteness>}
     *
     * @memberof ConsumerService
     */
    public async getProfileCompleteness(): Promise<IProfileCompleteness> {
        return this.callProfileCompletenessApi();
    }

    /**
     * Pulls list of mySecureWallet Payment Methods for the consumer, maps that to a list of PaymentMethod
     *
     * @returns {Promise<PaymentMethod[]>}
     *
     * @memberOf ConsumerService
     */
    public async getWalletPaymentMethods(): Promise<PaymentMethod[]> {
        if (this.componentService.storageService.exists('wallet')) {
            const walletFromStorage = this.componentService.storageService.retrieve('wallet');
            this.paymentMethods.next(walletFromStorage);
            const response: PaymentMethod[] = walletFromStorage;
            return response;
        } else {
            let paymentMethods = await this.callGetConsumerWalletApi();
            paymentMethods.forEach(p => this.bindPaymentMethod(p));
            const otherPayment = Object.assign({}, OtherPaymentMethod[0]);
            paymentMethods = paymentMethods.concat(otherPayment);
            this.componentService.storageService.store('wallet', paymentMethods);
            this.paymentMethods.next(this.componentService.storageService.retrieve('wallet'));
            return paymentMethods;
        }
    }

    /**
     * Pulls list of mySecureWallet Payment Methods for the consumer,
     * based on the item's merchantCredentialGUID, maps that to a list of PaymentMethod.
     * This will exclude payment methods when the domain credentials and plan credentials don't match, accounting for multi-MID logic
     *
     * @returns {Promise<PaymentMethod[]>}
     *
     * @memberOf ConsumerService
     */
    public async getWalletPaymentMethodsByMerchant(merchantCredentialGUID: string): Promise<PaymentMethod[]> {
        const paymentMethods = await this.callGetConsumerWalletByMerchantApi(merchantCredentialGUID);
        paymentMethods.forEach(p => this.bindPaymentMethod(p));
        return paymentMethods;
    }

    /**
     * dumps localstorage wallets and refreshes the list
     *
     * @returns {Promise<PaymentMethod[]>}
     *
     * @memberOf ConsumerService
     */
    public async updateWalletPaymentMethodStorage(): Promise<PaymentMethod[]> {
        this.componentService.storageService.clear('wallet');
        return this.getWalletPaymentMethods();
    }

    /**
     * update existing wallet payment method
     * @returns {Promise<boolean>}
     * @memberOf ConsumerService
     */
    public async updateWalletPaymentMethod(
        paymentMethodGUID: string, merchantGUID: string, nickname: string,
        address: Address, creditCard: CreditCard): Promise<IGenericResponse> {
        return this.callUpdateWalletPaymentMethodApi(paymentMethodGUID, merchantGUID, nickname, address, creditCard);
    }

    /**
     * Stores a new mySecureWallet Payment Method for the consumer
     *
     * @returns {Promise<boolean>}
     *
     * @memberOf ConsumerService
     */
    public async storeWalletPaymentMethod(
        merchantGUID: string, nickname: string, primary: boolean, creditCard: CreditCard,
        achPaymentMethod: ACHPaymentMethod, address: Address): Promise<IGenericResponse> {
        return this.callStoreWalletApi(merchantGUID, nickname, primary, creditCard, achPaymentMethod, address);
    }

    /**
     * Cancels/deletes a new mySecureWallet Payment Method for the consumer
     *
     * @returns {Promise<boolean>}
     *
     * @memberOf ConsumerService
     */
    public async updatePrimaryWalletPaymentMethod(merchantGUID: string, paymentMethodGUID: string): Promise<boolean> {
        return this.callUpdatePrimaryWalletApi(merchantGUID, paymentMethodGUID);
    }

    /**
     * Cancels/deletes a new mySecureWallet Payment Method for the consumer
     *
     * @returns {Promise<boolean>}
     *
     * @memberOf ConsumerService
     */
    public async cancelWalletPaymentMethod(merchantGUID: string, paymentMethodGUID: string): Promise<boolean> {
        return this.callCancelWalletApi(merchantGUID, paymentMethodGUID);
    }

    /**
     * Submit customer feedback
     *
     * @returns {Promise<boolean>}
     *
     * @param feedbackRequest
     *
     * @memberOf ConsumerService
     */
    public async submitFeedback(feedbackRequest: SubmitFeedbackRequest, loginToken: string = null): Promise<boolean> {
        return this.callSubmitFeedbackApi(feedbackRequest, loginToken) != null;
    }

    /**
     * Get autopay agreements for current consumer
     *
     * @returns {Promise<IAutopayAgreement[]>}
     *
     * @memberOf ConsumerService
     */
    public async getAutopayAgreements(): Promise<IAutopayAgreement[]> {
        const data = await this.callAutopayAgreementApi();
        this.bindAutopayAgreements(data);
        return data;
    }

    /**
     * Get locations that a consumer can enroll into AutoPayments
     *
     * @returns {Promise<IPaymentLocation[]>}
     *
     * @memberOf ConsumerService
     */
    public async getAutopayEnrollmentLocations(): Promise<IPaymentLocation[]> {
        return this.callAutopayEnrollmentLocationsApi();
    }

    /**
     * Updates the consumer's Autopay Agreement status ('Active', 'Cancelled')
     *
     * @returns {Promise<IGenericResponse>}
     *
     * @memberOf ConsumerService
     */
    public async updateAutoPayAgreement(autopayAgreement: IAutopayAgreement): Promise<IGenericResponse> {
        return this.updateAutoPayAgreementApi(autopayAgreement);
    }

    /**
     * Will enroll the consumer into an autopay agreement with the location and payment information passed in
     *
     * @param autopayAgreement
     * @returns {Promise<IGenericResponse>}
     * @memberOf ConsumerService
     */
    public async enrollInAutoPayAgreement(autopayAgreement: AutopayEnrollPayment): Promise<IGenericResponse> {
        return this.enrollInAutoPayAgreementApi(autopayAgreement);
    }

    public async setHasAutopayAgreements(hasAgreements: boolean): Promise<void> {
        // Linting rules would rather this be .updateConsumer({hasAgreements}).
        // After following that suggestion, it complains: "Unused property hasAgreements" ...
        // tslint:disable-next-line:object-literal-shorthand
        await this.consumerStorageService.updateConsumer({hasAgreements: hasAgreements});
    }

    /**
     * Gets list of Consumer Activity records for the consumer, maps that to a list of ConsumerActivity
     *
     * @returns {Promise<ConsumerActivity[]>}
     *
     * @memberOf ConsumerService
     */
    public async getConsumerActivity(numberOfResults?: number, fromDate?: Date, toDate?: Date): Promise<Activity[]> {
        let data = await this.callConsumerActivityApi(numberOfResults, fromDate, toDate);

        // Filter out activityTypes which should not be displayed in MSB.
        data = data.filter(activity => activity.activityType !== 'Score');

        return data;
    }

    /**
     * Get Consumer Account returns the profile information for the current consumer
     *
     * @returns {Promise<ConsumerAccount>}
     * @memberof ConsumerService
     */
    public async getConsumerAccount(loginToken: string = null, customerAccountID: string = null): Promise<ConsumerAccount> {
        if (this.componentService.storageService.exists('consumeraccount')) {
            return this.componentService.storageService.retrieve('consumeraccount');
        } else {
            const data = await this.callConsumerAccountApi(loginToken, customerAccountID);
            this.componentService.storageService.store('consumeraccount', data);
            return data;
        }
    }

    /**
     * Calls the server to see if the username exists for this domain
     *
     * @param {string} username username to look up
     * @returns {Promise<boolean>}
     * @memberof ConsumerService
     */
    public async checkIfUsernameExists(username: string): Promise<boolean> {
        return this.callCheckIfUsernameExists(username);
    }

    /**
     * Pass a first and last name to API, it will then generate a unique username with random number at the end.
     *
     * @param generateUsernameRequest
     * @returns {Promise<string>}
     * @memberOf ConsumerService
     */
    public async generateUsername(generateUsernameRequest: GenerateUsernameRequest): Promise<string> {
        return this.callGenerateUsername(generateUsernameRequest);
    }

    /**
     * Creates the Consumer Account.
     *
     * @param {ConsumerAccountRequest} consumer
     * @param {boolean} isConsumerSetFromContactDetailsModal -- because the modal is calling for consumer details,
     * and the service method sets those details in localStorage when getting the consumer details,
     * an optional flag can be passed here when calling from /enrollment to continue on, instead of just logging that
     * the acct already exists.
     * @returns {Promise<boolean>}
     * @memberof ConsumerService
     */
    public async createConsumerAccount(
        consumer: ConsumerAccountRequest,
        isConsumerSetFromContactDetailsModal = false
    ): Promise<boolean> {
        if (consumer.AccountType !== 'QuickPay' &&
            this.componentService.storageService.exists('consumeraccount') &&
            !isConsumerSetFromContactDetailsModal) {
            this.loggingService.log('There is already a consumer when trying to create one', LoggingLevel.error);
        } else {
            return this.createConsumerAccountWithApi(consumer);
        }
    }

    /**
     * Updates the consumer account in storage
     *
     * @returns {Promise<boolean>}
     * @memberof ConsumerService
     */
    public async updateConsumerAccountStorage(): Promise<boolean> {
        this.componentService.storageService.clear('consumeraccount');
        const consumerAccount = await this.getConsumerAccount();
        return consumerAccount != null;
    }
    //
    /**
     * Calls the server to validate documentGUID
     *
     * @param {string} documentGUID documentGUID to look up
     * @returns {Promise<boolean>}
     * @memberof ConsumerService
     */
    public async validateDocumentGUID(documentGUID: string): Promise<boolean> {
        return this.callValidateDocumentGUID(documentGUID);
    }

    /**
     * Calls the server to validate verification email
     *
     * @param {string} verificationId verificationId to look up
     * @param {string} enrollmentchannel The Consumer Account EnrollmentChannel
     * @returns {Promise<boolean>}
     * @memberof ConsumerService
     */
    public async validateVerificationId(verificationId: string, enrollmentchannel: string): Promise<boolean> {
        const consumerAccountRequest = new ConsumerAccountRequest();
        consumerAccountRequest.EnrollmentChannel = enrollmentchannel;
        consumerAccountRequest.EmailVerificationId = verificationId;
        return this.callValidateVerificationId(consumerAccountRequest);
    }

    /**
     * Calls the server to validate pending Enrollment email
     * @param pendingEnrollmentGUID
     * @returns {Promise<IGenericResponse>}
     * @memberof ConsumerService
     */
    public async validatePendingEnrollment(pendingEnrollmentGUID: string): Promise<IGenericResponse> {
        return this.callValidatePendingEnrollment(pendingEnrollmentGUID);
    }

    /**
     * Calls the server to validate password reset email
     *
     * @param {string} passwordResetId passwordResetId to look up
     * @returns {Promise<boolean>}
     * @memberof ConsumerService
     */
    public async validatePasswordResetId(passwordResetId: string): Promise<boolean> {
        const consumerAccountRequest = new ConsumerAccountRequest();
        consumerAccountRequest.EmailVerificationId = passwordResetId;
        return this.callValidatePasswordResetId(consumerAccountRequest);
    }

    /**
     * Reset password following forgot password email
     *
     * @param {string} passwordResetId
     * @param {string} password
     * @returns {Promise<IGenericResponse>}
     *
     * @memberof ConsumerService
     */
    public async submitForgotPasswordReset(passwordResetId: string, password: string): Promise<IGenericResponse> {
        const body: any = {};
        body.bypass = true;
        body.bypass = true;
        body.path = this.updatePasswordResetPath;
        body.password = password;
        body.passwordresetid = passwordResetId;

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IGenericResponse>) => {
                return response.body;
            })
            .catch((response: GenericResponse) => {
                return this.loggingService.handleError(response);
            });
    }

    /**
     * Update consumer account, returns JSON with boolean value 'success'. Using ILogin since API method
     *
     * @param {string} customerAccountID
     * @param {string} token
     * @param {string} password
     * @returns {Promise<ILogin>}
     *
     * @memberof ConsumerService
     */
    public async updateConsumerAccountPassword(customerAccountID: string, password: string, oldPassword?: string): Promise<ILogin> {
        this.token = this.componentService.storageService.retrieve('token');

        const body: any = {};
        body.bypass = true;
        body.path = this.updateConsumerPasswordPath;
        body.UrlAccessKey = this.token;
        body.customerAccountID = customerAccountID;
        body.password = password;
        body.oldPassword = oldPassword;

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<ILogin>) => {
                const resp = response.body;
                // check for "messages" AKA errors on the response
                if (resp.messages && resp.messages.length > 0) {
                    resp.userMessage = this.componentService.handleValidationErrorCode(resp.messages[0]);
                }
                return resp;
            })
            .catch((response: GenericResponse) => {
                return this.loggingService.handleError(response);
            });
    }

    /**
     * Updates consumer account profile information, including email address, delivery method
     *
     * @param {string} customerAccountID
     * @param {string} emailAddress
     * @param {string} deliveryMethod
     * @returns {Promise<any>}
     *
     * @memberof ConsumerService
     */
    public async updateConsumerAccountPreferences(
        customerAccountID: string, emailAddress: string, deliveryMethod: string): Promise<GenericResponse> {
        this.token = this.componentService.storageService.retrieve('token');

        const body: any = {};
        body.bypass = true;
        body.domain = this.domain;
        body.path = this.updateConsumerPath;
        body.UrlAccessKey = this.token;
        body.customerAccountID = customerAccountID;
        body.emailAddress = emailAddress;
        body.deliveryMethod = deliveryMethod;
        body.enrollmentChannel = 'MySecureBill';
        body.domain = this.domain;

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => {
                return response;
            })
            .catch((response: GenericResponse) => {
                return this.loggingService.handleError(response);
            });
    }

    public async updateConsumerCommunicationPreference(
        customerAccountID: string,
        changes: ICommunicationPreferenceChange[]
    ): Promise<ICommunicationPreferences> {
        const mapped = changes.map(change => {
            change.customerAccountID = customerAccountID;
            return change;
        });
        const body = {
            UrlAccessKey: this.token,
            collectionRequest: mapped,
            path: this.updateCommunicationPreferencesPath,
            bypass: true,
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((res: Response<ICommunicationPreferences>) => res.body.data)
            .catch(res => this.loggingService.handleError(res));
    }

    public async updateCommunicationPreferenceForUnsubscribeEmail(
            consumerAccountGUID: string,
            changes: ICommunicationPreferenceChange[]
        ): Promise<boolean> {

        // changes must be mapped before passed as the collectionRequest
        const mapped = changes.map(change => {
            return change;
        });

        const myPath = `${this.updateCommunicationPreferencesForUnsubscribeEmailPath}/${consumerAccountGUID}`;

        const body = {
            path: myPath,
            bypass: true,
            requireCredentials: false,
            collectionRequest: mapped
        };

        return this.http.post(
                this.domainUrl + '?relativePath=' + encodeURIComponent(myPath) + '&requireCredentials=false',
                {body},
                jsonRequestOptions()
            )
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    public async getConsumerCommunicationPreferences(
        customerAccountID: string,
        checkForEmailOrSmsFailures: boolean = false
    ): Promise<ICommunicationPreferences> {
        const token = this.componentService.storageService.retrieve('token');
        const body = {
            UrlAccessKey: token,
            bypass: true,
            path: 'consumer/v2/communication-preferences-request',
            customerAccountId: customerAccountID,
            checkForEmailOrSmsFailures
        };
        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((res: Response<ICommunicationPreferences>) => res.body.data)
            .catch(res => this.loggingService.handleError(res));
    }

    // The following method will be replaced in future stories by the next method.
    public convertConsumerCommunicationPreferencesToDeliveryMethodWords(preferences: ICommunicationPreferences): string {
        let deliveryMethod;

        if (preferences.statementEmailEnabled && preferences.statementPaperEnabled) {
            deliveryMethod = 'Both';
        } else if (preferences.statementEmailEnabled) {
            deliveryMethod = 'Electronic';
        } else {
            deliveryMethod = 'Paper';
        }

        return deliveryMethod;
    }

    // Yes, this one.
    public convertCommunicationPreferencesToWords(preferences: ICommunicationPreferences): string {
        if (preferences.statementEmailEnabled &&
            preferences.statementSmsEnabled &&
            preferences.statementPaperEnabled) {
            return 'Email, Text, & Paper';
        } else if (preferences.statementSmsEnabled && preferences.statementPaperEnabled) {
            return 'Text & Paper';
        } else if (preferences.statementEmailEnabled && preferences.statementPaperEnabled) {
            return 'Email & Paper';
        } else if (preferences.statementEmailEnabled && preferences.statementSmsEnabled) {
            return 'Email & Text';
        } else if (preferences.statementSmsEnabled) {
            return 'Text';
        } else if (preferences.statementEmailEnabled) {
            return 'Email';
        } else {
            return 'Paper';
        }
    }

    public getSelectedDeliveryMethods(profileDeliveryMethodOptions: string, selectedDeliveryMethods: string): string {
        return profileDeliveryMethodOptions.split(';').filter(x => x.match(selectedDeliveryMethods)).join(';');
    }

    /**
     * Updates consumer account profile information using verificationID
     *
     * @param {string} verificationID
     * @param {string} emailAddress
     * @param {string} deliveryMethod
     * @param {boolean} isQuickPay
     * @returns {Promise<any>}
     *
     * @memberOf ConsumerService
     */
    public async updateConsumerAccountPreferencesByVerificationID(
        verificationID: string,
        emailAddress: string,
        deliveryMethod: string,
        isQuickPay: boolean
    ): Promise<GenericResponse> {
        const body: any = {};
        body.bypass = true;
        body.domain = this.domain;
        body.path = this.updateConsumerByVerificationIDPath;
        body.verificationID = verificationID;
        body.emailAddress = emailAddress;
        body.deliveryMethod = deliveryMethod;
        body.isQuickPay = isQuickPay;

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => {
                return response;
            })
            .catch((response: GenericResponse) => {
                return this.loggingService.handleError(response);
            });
    }

    /**
     * Sends a verification email to the consumer for profile changes
     *
     * @param {string} customerAccountID
     * @param {string} emailAddress
     * @returns {Promise<boolean>}
     *
     * @memberof ConsumerService
     */
    public async sendVerificationEmail(customerAccountID: string, emailAddress: string): Promise<string> {
        this.token = this.componentService.storageService.retrieve('token');

        const body: any = {};
        body.bypass = true;
        body.domain = this.domain;
        body.path = this.sendVerificationPath;
        body.UrlAccessKey = this.token;
        body.customerAccountID = customerAccountID;
        body.emailAddress = emailAddress;

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<string>) => {
                return response.body.data;
            })
            .catch((response: GenericResponse) => {
                return this.loggingService.handleError(response);
            });
    }

    /**
     * Sends a verification email to the consumer for password reset
     *
     * @param {string} username
     * @returns {Promise<boolean>}
     *
     * @memberof ConsumerService
     */
    public async sendPasswordResetEmail(username: string): Promise<boolean> {
        const body: any = {};
        body.bypass = true;
        body.domain = this.domain;
        body.path = this.sendPasswordResetPath;
        body.username = username;

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => {
                return response.body.data;
            })
            .catch((response: any) => {
                // Error code 400.41 associated to 'ConsumerAccount not found for given username'
                if (response.error &&
                    response.error.messages &&
                    !response.error.messages.some((m: string) => m.startsWith('400.41'))) {
                    return this.loggingService.handleError(response);
                }
            });
    }

    /**
     * Get Consumer Payment History returns the history of payments for the current consumer
     *
     * @param {number} [numberOfResults]
     * @param {Date} [fromDate]
     * @param {Date} [toDate]
     * @returns {Promise<Payment[]>}
     * @memberof ConsumerService
     */
    public async getConsumerPaymentHistory(): Promise<IPayment[]> {
        return this.callConsumerPaymentHistoryApi();
    }

    public async verifyNumberNotBlacklisted(phoneNumber: string): Promise<IGenericResponse> {
        return this.verifyNumberNotBlacklistedApi(phoneNumber);
    }

    /**
     * Sends a verification PIN to the consumer to opt in to the SMS service
     *
     * @param {string} customerAccountID
     * @param {string} phoneNumber
     * @returns {Promise<IGenericResponse>}
     *
     * @memberof ConsumerService
     */
    public async sendForSmsConfirmationPin(customerAccountID: string, phoneNumber: string): Promise<IGenericResponse> {
        return this.sendForSmsConfirmationPinApi(customerAccountID, phoneNumber);
    }

    /**
     * Activates SMS messaging after confirming correct PIN for the consumer account
     *
     * @param {string} customerAccountID
     * @param {string} phoneNumber
     * @param {string} smsPin
     * @returns {Promise<IGenericResponse>}
     *
     * @memberof ConsumerService
     */
    public async validateSmsPin(customerAccountID: string, phoneNumber: string, smsPin: string): Promise<IGenericResponse> {
        return this.validateSmsPinApi(customerAccountID, phoneNumber, smsPin);
    }

    /**
     * Deactivates SMS communcation for the consumer account
     *
     * @param {string} customerAccountID
     * @returns {Promise<IGenericResponse>}
     *
     * @memberof ConsumerService
     */
    public async deactivateSms(customerAccountID: string): Promise<IGenericResponse> {
        return this.deactivateSmsApi(customerAccountID);
    }

    /**
     * Deactivates SMS communcation for the consumer account by verification ID
     *
     * @param {string} customerAccountID
     * @returns {Promise<IGenericResponse>}
     *
     * @memberof ConsumerService
     */
    public async deactivateSmsByVerificationID(verificationID: string): Promise<IGenericResponse> {
        return this.callDeactivateSmsByVerificationIDApi(verificationID);
    }

    /**
     * Cancels the AutoPay Payment
     *
     * @param {CancelAutoPayPayment} cancelAutoPayPayment
     * @returns {Promise<IGenericResponse>}
     * @memberof ConsumerService
     */
    public async cancelAutoPayPayment(cancelAutoPayPayment: CancelAutoPayPayment): Promise<IGenericResponse> {
        return await this.callCancelAutoPayPaymentApi(cancelAutoPayPayment);
    }

    /**
     * @returns {Promise<IRecurringPaymentPlan>}
     * @memberof ConsumerService
     */
    public async getRecurringPaymentPlans(): Promise<IRecurringPaymentPlan[]> {
        if (!await this.consumerStorageService.consumerExistsInStorage()) {
            throw new Error('invalid session');
        }
        const data = await this.callGetRecurringPaymentPlansApi();
        data.forEach(plan => this.bindRecurringPaymentPlanStatus(plan));
        return data;
    }

    /**
     * Gets a boolean representing whether or not the consumer has recurring payment plans set up.
     * Mostly useful for the loggedin dashboard so we don't do all the crazy prefetching and mapping that method does.
     *
     * @returns {Promise<boolean>}
     * @memberof ConsumerService
     */
    public async getConsumerHasRecurringPaymentPlans(): Promise<boolean> {
        if (!await this.consumerStorageService.consumerExistsInStorage()) {
            throw new Error('invalid session');
        }
        return this.callGetConsumerHasRecurringPaymentPlansApi();
    }

    /**
     * @returns {Promise<string>} Indicates whether or not the stored secureemailguid represents a valid/unexpired RecurringActivationEmail
     * @memberof ConsumerService
     */
    public async getRecurringActivationEmailValid(): Promise<string> {
        return this.callGetRecurringActivationEmailValidApi();
    }

    /**
     * @returns {Promise<IRecurringPaymentPlan>}
     * @memberof ConsumerService
     */
    public async getRecurringPaymentPlanByGuid(planGuid: string): Promise<IRecurringPaymentPlan> {
        const data = await this.callGetRecurringPaymentPlanByGuidApi(planGuid);
        this.bindRecurringPaymentPlanStatus(data);
        return data;
    }

    /**
     * save the changes to the recurring payment
     *
     * @returns {Promise<IGenericResponse>}
     * @memberof ConsumerService
     */
    public async updateRecurringPaymentPlan(plan: IRecurringPaymentPlan): Promise<IGenericResponse> {
        return this.updateRecurringPaymentPlanApi(plan);
    }

    /**
     *
     * Activate the recurring payment plan
     * @param {IRecurringPaymentPlan} plan the recurring payment plan to activate.  Must be in a pending status.
     * @returns {Promise<boolean>} success of saving the plan
     * @memberof ConsumerService
     */
    public async activateRecurringPaymentPlan(plan: IRecurringPaymentPlan): Promise<boolean> {
        if (plan.status !== RecurringPaymentPlanStatus.pending) {
            throw new Error('Can\'t activate a plan that isn\'t in a pending status.');
        }
        return this.activateRecurringPaymentPlanApi(plan);
    }

    /**
     * Gets all the merchant profiles relevant to the logged in consumer so we don't have to get them later
     *
     * @returns {Promise<IMerchantProfile[]>}
     * @memberof ConsumerService
     */
    public async getAllMerchantProfilesForConsumer(consumer: IConsumer): Promise<IMerchantProfile[]> {
        const data = await this.callGetAllMerchantProfilesForConsumerApi(consumer.accountID);
        data.forEach(profile => this.addMerchantProfileToStorage(profile));
        return data;
    }

    /**
     * Gets the paymentinfo corresponding to the passed in FastTrackID (GUID) via the ConsumerAPI's call to the external FastTrack Service
     *
     * @param {string} fastTrackGUID
     * @returns {Promise<FastTrackPaymentInfoResponse>}
     * @memberof ConsumerService
     */
    public async getFastTrackPaymentInfo(fastTrackGUID: string): Promise<FastTrackPaymentInfoResponse> {
        return this.callGetFastTrackPaymentInfoApi(fastTrackGUID);
    }

    /**
     * Get the account type of the consumer for the given verificationID
     *
     * @param {string} verificationID  the verificationID from the verification email
     * @returns {Promise<string>} the account type
     * @memberof ConsumerService
     */
    public async getAccountTypeByVerificationID(verificationID: string): Promise<string> {
        if (verificationID == null) {
            throw new Error('verificationID was null');
        }
        return this.callGetAccountTypeByVerificationID(verificationID);
    }

    public async getConsumerRoles(): Promise<string[]> {
        return this.callGetConsumerRoles();
    }

    public async isCaptchaRequired(): Promise<boolean> {
        if (!await this.consumerStorageService.consumerExistsInStorage()) {
            throw new Error('invalid session');
        }
        return this.callIsCaptchaRequiredApi();
    }

    /**
     * Gets a boolean representing whether or not the email passed has any accounts tied to it.
     *
     * @returns {Promise<boolean>}
     * @memberof ConsumerService
     */
    public async verifyEmailExists(email: string): Promise<GenericResponse> {
        return this.callVerifyEmailExists(email);
    }

    public async activateRecurringPaymentAgreement(recurringPaymentAgreement: IRecurringPaymentPlanSubmit): Promise<boolean> {
        return this.callActivateRecurringPaymentAgreement(recurringPaymentAgreement);
    }

    public async sendVerificationCode(customerAccountID: string, emailAddress:string): Promise<GenericResponse> {
        return this.sendVerificationCodeApi(customerAccountID, emailAddress);
    }

    public async validateVerificationCode(
        customerAccountID: string,
        emailAddress: string,
        code: string,
        consumerAccountGUID: string,
        prefChanges: ICommunicationPreferenceChange[]
    ): Promise<GenericResponse> {
        return this.validateVerificationCodeApi(
            customerAccountID,
            emailAddress,
            code,
            consumerAccountGUID,
            prefChanges
        );
    }

    public async updateLastEStatementPrompt(consumerAccountGUID: string): Promise<boolean> {
        return this.updateLastEStatementPromptApi(consumerAccountGUID);
    }

    private async callVerifyEmailExists(email: string): Promise<GenericResponse> {
         const relativePath = this.verifyEmailExistsPath + '/?email=' + email + '&domain=' + this.domain;

         return this.http.get(this.domainUrl + '?relativePath=' + encodeURIComponent(relativePath) + '&requireCredentials=false',
        jsonRequestOptions())
        .toPromise()
        .then((response: Response<GenericResponse>) => response.body.data)
        .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callConsumerApi(loginToken: string = null): Promise<IConsumer> {
        // Make sure memory value is cleared since the storage may have been dumped.
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.exists('token')
            ? this.componentService.storageService.retrieve('token')
            : loginToken;

        const path: string = this.domainUrl + '?relativePath=' +
            encodeURIComponent(this.token + '/consumer/v1/token?domain=' + this.domain) + '&requireCredentials=true';

        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: HttpResponse<IConsumer>) => response.body)
            .catch((response: GenericResponse) =>
                this.loggingService.handleError(response));
    }

    private async callGetAllMerchantProfilesForConsumerApi(customerAccountID: string): Promise<IMerchantProfile[]> {
        this.token = this.componentService.storageService.retrieve('token');
        const path = this.merchantProfileForConsumerPath;

        const body = {
            UrlAccessKey: this.token,
            CustomerAccountID: customerAccountID,
            path,
            bypass: true
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IMerchantProfile[]>) => response.body.data)
            .catch((response: IGenericResponse) => this.loggingService.handleError(response));
    }

    private async callMerchantProfileApi(
        domain: string, docIds: number[] = [], merchantProfileGUID: string = this.componentService.NULL_GUID): Promise<IMerchantProfile> {
        const path = this.merchantPath;
        this.token = this.componentService.storageService.retrieve('token');
        let body: any;
        if (this.token == null) {
            body = {domain, merchantProfileGUID, path};
        } else {
            body = {AccessKey: this.token, DocumentIDs: docIds, domain, merchantProfileGUID, path, bypass: true};
        }

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IMerchantProfile>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callProfileCompletenessApi(): Promise<IProfileCompleteness> {
        const path = this.profileCompletenessPath;
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();
        let body: any;
        body = {UrlAccessKey: this.token, CustomerAccountID: consumer.accountID, path, bypass: true};

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IProfileCompleteness>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetConsumerWalletApi(): Promise<PaymentMethod[]> {
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();
        const path = 'consumerwallet/v2/ID';
        const body = { UrlAccessKey: this.token, CustomerAccountID: consumer.accountID, path, bypass: true};

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<PaymentMethod[]>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetConsumerWalletByMerchantApi(merchantCredentialGUID: string): Promise<PaymentMethod[]> {
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();
        const path = 'consumerwallet/v2/ID/Merchant';
        const body = {
            UrlAccessKey: this.token,
            CustomerAccountID: consumer.accountID,
            MerchantCredentialGUID: merchantCredentialGUID,
            path,
            bypass: true
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<PaymentMethod[]>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callAutopayAgreementApi(): Promise<IAutopayAgreement[]> {
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();

        const body = {
            UrlAccessKey: this.token,
            path: 'consumer/v2/autopayagreements',
            bypass: true,
            customerAccountID: consumer.accountID
        };

        return this.http.post(this.domainUrl,
            { body },
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<IAutopayAgreement[]>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetEmailByPendingEnrollment(pendingEnrollmentGUID: string): Promise<string> {
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');

        const relativePath = this.token + '/consumeraccount/v2/getemailbypendingenrollment/?pendingEnrollmentGUID=' + pendingEnrollmentGUID;

        return this.http.get(this.domainUrl + '?relativePath=' + encodeURIComponent(relativePath) + '&requireCredentials=true',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<string>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetConsumerAccountDeliveryPreferencesByVerificationIDApi(
        verificationID: string): Promise<ConsumerAccountDeliveryPreferences> {

        const relativePath = 'consumeraccount/v2/getaccountdeliverypreferencesbyverificationid/?verificationID=' + verificationID;

        return this.http.get(this.domainUrl + '?relativePath=' + encodeURIComponent(relativePath) + '&requireCredentials=false',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<ConsumerAccountDeliveryPreferences>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private callGetConsumerAccountSMSPreferencesByVerificationIDApi(verificationID: string): Promise<ConsumerAccountSMSPreferences> {

        const relativePath = 'consumeraccount/v2/getaccountsmspreferencesbyverificationid/?verificationID=' + verificationID;

        return this.http.get(this.domainUrl + '?relativePath=' + encodeURIComponent(relativePath) + '&requireCredentials=false',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<ConsumerAccountSMSPreferences>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetEmailFromConsumerAccountGUIDApi(
        consumerAccountGUID: string): Promise<string> {
        const relativePath = 'consumeraccount/v2/getemailfromconsumeraccountguid/' + consumerAccountGUID;

        return this.http.get(this.domainUrl + '?relativePath=' + encodeURIComponent(relativePath) + '&requireCredentials=false',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<string>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callUpdateConsumerAccountForUnsubscribeEmailApi(consumerAccountGUID: string): Promise<boolean> {
        const myPath = `${this.updateConsumerAccountForUnsubscribeEmailPath}/${consumerAccountGUID}`;

        const body = {
            path: myPath,
            bypass: true,
            requireCredentials: false
        };

        return this.http.post(
                this.domainUrl + '?relativePath=' + encodeURIComponent(myPath) + '&requireCredentials=false',
                {body},
                jsonRequestOptions()
            )
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callAutopayEnrollmentLocationsApi(): Promise<IPaymentLocation[]> {

        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();

        const body = {
            UrlAccessKey: this.token,
            path: 'consumer/v2/autopayagreements/enrolllocations',
            bypass: true,
            customerAccountID: consumer.accountID
        };

        return this.http.post(this.domainUrl,
            { body },
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<IAutopayAgreement[]>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async updateAutoPayAgreementApi(autopayAgreement: IAutopayAgreement): Promise<IGenericResponse> {
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();

        const body = {
            UrlAccessKey: this.token,
            CustomerAccountID: consumer.accountID,
            agreementID: autopayAgreement.autopayAgreementID,
            status: autopayAgreement.status,
            path: this.updateAutopayAgreementPath,
            bypass: true
        };
        // tslint:disable-next-line: forin
        for (const i in autopayAgreement) {
            body[i] = autopayAgreement[i];
        }

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async enrollInAutoPayAgreementApi(payment: AutopayEnrollPayment): Promise<IGenericResponse> {

        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');

        const body = {
            bypass: true,
            path: this.autopayEnrollmentPath,
            domain: this.domain,
            UrlAccessKey: this.token
        };
        // tslint:disable-next-line: forin
        for (const i in payment) {
            body[i] = payment[i];
        }

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    // TODO: make this a success: boolean; messages: string[]; object
    private async callStoreWalletApi(
        merchantGUID: string, nickname: string, primary: boolean, creditCard: CreditCard,
        achPaymentMethod: ACHPaymentMethod, address: Address): Promise<IGenericResponse> {

        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();

        const body: any = {
            UrlAccessKey: this.token,
            CustomerAccountID: consumer.accountID,
            MerchantGUID: merchantGUID,
            Nickname: nickname,
            Source: 'MSB Ultra',
            Primary: primary,
            path: this.storeWalletPath,
            bypass: true
        };
        // tslint:disable-next-line: forin
        for (const i in creditCard) {
            if (!body.CreditCardPaymentMethod) {
                body.CreditCardPaymentMethod = {};
            }
            body.CreditCardPaymentMethod[i] = creditCard[i];
        }
        // tslint:disable-next-line: forin
        for (const i in achPaymentMethod) {
            if (!body.ACHPaymentMethod) {
                body.ACHPaymentMethod = {};
            }
            body.ACHPaymentMethod[i] = achPaymentMethod[i];
        }
        // tslint:disable-next-line: forin
        for (const i in address) {
            if (!body.Address) {
                body.Address = {};
            }
            body.Address[i] = address[i];
        }

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callUpdateWalletPaymentMethodApi(
        paymentMethodGUID: string, merchantGUID: string, nickname: string, address: Address,
        creditCard: CreditCard): Promise<IGenericResponse> {

        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();

        const body: any = {
            UrlAccessKey: this.token,
            CustomerAccountID: consumer.accountID,
            MerchantGUID: merchantGUID,
            PaymentMethodGUID: paymentMethodGUID,
            Nickname: nickname,
            CVV: creditCard.CVV,
            ExpirationDate: creditCard.ExpirationDate,
            CardNumber: creditCard.CardNumber,
            Source: 'MSB Ultra',
            path: this.updateWalletPaymentMethodPath,
            bypass: true
        };
        // tslint:disable-next-line: forin
        for (const i in address) {
            if (!body.Address) {
                body.Address = {};
            }
            body.Address[i] = address[i];
        }

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callUpdatePrimaryWalletApi(merchantGUID: string, paymentMethodGUID: string): Promise<boolean> {

        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();

        const body = {
            UrlAccessKey: this.token,
            CustomerAccountID: consumer.accountID,
            MerchantGUID: merchantGUID,
            PaymentMethodGUID: paymentMethodGUID,
            path: this.updatePrimaryWalletPath,
            bypass: true
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callCancelWalletApi(merchantGUID: string, paymentMethodGUID: string): Promise<boolean> {
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();

        const body = {
            UrlAccessKey: this.token,
            CustomerAccountID: consumer.accountID,
            MerchantGUID: merchantGUID,
            PaymentMethodGUID: paymentMethodGUID,
            path: this.cancelWalletPath,
            bypass: true
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callConsumerActivityApi(numberOfResults?: number, fromDate?: Date, toDate?: Date): Promise<Activity[]> {
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();

        const body: ConsumerActivityRequest = new ConsumerActivityRequest(); //
        body.UrlAccessKey = this.token;
        body.customerAccountID = consumer.accountID;
        body.path = 'consumer/v2/activity';
        body.bypass = true;

        if (numberOfResults || (fromDate && toDate)) {
            if (numberOfResults) {
                body.numberOfResults = numberOfResults;
            }

            if (fromDate && toDate) {
                body.fromDate = fromDate;
                body.toDate = toDate;
            }
        }

        return this.http.post(this.domainUrl,
            { body },
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<Activity[]>) => response.body.data as Activity[])
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callConsumerAccountApi(loginToken: string = null, customerAccountID: string = null): Promise<ConsumerAccount> {
        if (!this.token) {
            this.token = this.componentService.storageService.exists('token')
                ? this.componentService.storageService.retrieve('token')
                : loginToken;
        }

        // this will first pull the value from localStorage
        // if it exists, otherwise will request the info
        // from the server (this is required to move forward)
        const consumer: IConsumer = await this.getConsumer(this.token);

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.consumerAccountPath,
            customerAccountID: consumer ? consumer.accountID : customerAccountID,
            checkUcc: false
        };

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<ConsumerAccount>) => response.status !== 204 ? response.body.data : null)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callValidateDocumentGUID(documentGUID: string): Promise<boolean> {
        const path = this.validateDocumentGUIDPath + '?documentGUID=' + documentGUID;

        return this.http.get(this.domainUrl + '?relativePath=' + path + '&requireCredentials=false',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callValidateVerificationId(consumerAccountRequest: ConsumerAccountRequest): Promise<boolean> {

        const path = this.validateVerificationPath;
        const body = {path, bypass: true};

        // tslint:disable-next-line: forin
        for (const i in consumerAccountRequest) {
            body[i] = consumerAccountRequest[i];
        }

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callValidatePendingEnrollment(pendingEnrollmentGUID: string): Promise<IGenericResponse> {
        const relativePath = this.validatePendingEnrollmentPath + '?pendingEnrollmentGUID=' + encodeURIComponent(pendingEnrollmentGUID);

        return this.http.get(this.domainUrl + '?relativePath=' + relativePath + '&requireCredentials=false',
            jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callValidatePasswordResetId(consumerAccountRequest: ConsumerAccountRequest): Promise<boolean> {

        const path = this.validatePasswordResetPath;
        const body = {path, bypass: true};

        // tslint:disable-next-line: forin
        for (const i in consumerAccountRequest) {
            body[i] = consumerAccountRequest[i];
        }

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callCheckIfUsernameExists(username: string): Promise<boolean> {
        this.token = this.componentService.storageService.retrieve('token');
        if (this.token == null) {
            return null;
        }

        let path = this.token;
        path = this.urlAppend(path, this.usernamePath) + '?username=' + encodeURIComponent(username);

        return this.http.get(this.domainUrl + '?relativePath=' + path + '&requireCredentials=true',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGenerateUsername(generateUsernameRequest: GenerateUsernameRequest): Promise<string> {
        const body: any = {};
        body.bypass = true;
        body.domain = this.domain;
        body.path = this.generateUsernamePath;
        body.firstName = generateUsernameRequest.FirstName;
        body.lastName = generateUsernameRequest.LastName;

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<string>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async createConsumerAccountWithApi(consumerAccountRequest: ConsumerAccountRequest): Promise<boolean> {
        const path = this.createConsumerAccountPath;
        this.token = this.componentService.storageService.retrieve('token');
        let body: any;

        consumerAccountRequest.Domain = this.domain;

        if (this.token == null) {
            throw new Error(('No token'));
        } else {
            body = {UrlAccessKey: this.token, path, bypass: true};
        }

        // tslint:disable-next-line: forin
        for (const i in consumerAccountRequest) {
            body[i] = consumerAccountRequest[i];
        }

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private verifyNumberNotBlacklistedApi(phoneNumber: string): Promise<IGenericResponse> {
        this.token = this.componentService.storageService.retrieve('token');

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.verifyNumberNotBlacklistedNumberPath,
            phoneNumber
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private sendForSmsConfirmationPinApi(customerAccountID: string, phoneNumber: string): Promise<IGenericResponse> {
        this.token = this.componentService.storageService.retrieve('token');

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.sendSmsPath,
            customerAccountId: customerAccountID,
            phoneNumber
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => {
                return response.body;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async validateSmsPinApi(customerAccountID: string, phoneNumber: string, smsPin: string): Promise<IGenericResponse> {
        this.token = this.componentService.storageService.retrieve('token');

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.validateSmsPinPath,
            customerAccountId: customerAccountID,
            phoneNumber,
            pin: smsPin,
            enrollmentChannel: 'MySecureBill'
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => {
                return response.body;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async deactivateSmsApi(customerAccountID: string): Promise<IGenericResponse> {

        this.token = this.componentService.storageService.retrieve('token');

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.deactivateSmsPath,
            customerAccountId: customerAccountID
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => {
                return response.body;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callDeactivateSmsByVerificationIDApi(verificationID: string): Promise<IGenericResponse> {
        const body = {
            UrlAccessKey: verificationID,
            bypass: true,
            path: this.deactivateSmsByVerificationIDPath,
            verificationID,
            requireCredentials: false
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => {
                return response.body;
            })
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callCancelAutoPayPaymentApi(cancelAutoPayPayment: CancelAutoPayPayment): Promise<IGenericResponse> {

        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');

        const body = {
            UrlAccessKey: this.token,
            path: this.cancelAutopayPaymentPath,
            bypass: true,
            customerAccountId: cancelAutoPayPayment.customerAccountId,
            autoPayId: cancelAutoPayPayment.autoPayId,
            amount: cancelAutoPayPayment.amount,
            cancelDate: cancelAutoPayPayment.cancelDate,
            emailAddress: cancelAutoPayPayment.emailAddress,
            merchantProfileGUID: cancelAutoPayPayment.merchantProfileGUID,
            paymentMethodDescription: cancelAutoPayPayment.paymentMethodDescription,
            paymentLocationDescription: cancelAutoPayPayment.paymentLocationDescription
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetRecurringPaymentPlansApi(): Promise<IRecurringPaymentPlan[]> {
        // Make sure memory value is cleared since the storage may have been dumped.

        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.recurringPaymentPlansPath,
            customerAccountID: consumer.accountID
        };

        return this.http.post(this.domainUrl,
            { body },
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<IRecurringPaymentPlan>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));

    }

    private async callGetConsumerHasRecurringPaymentPlansApi(): Promise<boolean> {
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();
        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.consumerHasRecurringPaymentPlansPath,
            customerAccountID: consumer.accountID
        };

        return this.http.post(this.domainUrl,
            { body },
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private callGetRecurringActivationEmailValidApi(): Promise<string> {
        const emailGuid: string = this.componentService.storageService.retrieve('secureemailguid');

        let path = this.componentService.NULL_GUID;
        path = this.urlAppend(path, this.recurringActivationEmailValidPath);
        path = this.urlAppend(path, emailGuid);
        path = this.urlAppend(path, '');  // Empty closing / for pathing

        return this.http.get(this.domainUrl + '?relativePath=' + encodeURIComponent(path) + '&requireCredentials=false',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<string>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetRecurringPaymentPlanByGuidApi(planGUID: string): Promise<IRecurringPaymentPlan> {
        // Make sure memory value is cleared since the storage may have been dumped.

        // need pass an access key
        let path = this.componentService.NULL_GUID;
        path = this.urlAppend(path, this.recurringPaymentPlanByGuidPath);
        if (planGUID != null) {
            path = this.urlAppend(path, planGUID);
        }
        path = this.urlAppend(path, '');  // Empty closing / for pathing

        return this.http.get(this.domainUrl + '?relativePath=' + encodeURIComponent(path) + '&requireCredentials=false',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<IRecurringPaymentPlan>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));

    }

    private async updateRecurringPaymentPlanApi(plan: IRecurringPaymentPlan): Promise<IGenericResponse> {

        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();

        const body = {
            UrlAccessKey: this.token,
            CustomerAccountID: consumer.accountID,
            planGUID: plan.recurringConsumerPaymentGUID,
            status: plan.status,
            path: this.updateRecurringPaymentPlanPath,
            bypass: true
        };
        // tslint:disable-next-line: forin
        for (const i in plan) {
            body[i] = plan[i];
        }

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async activateRecurringPaymentPlanApi(plan: IRecurringPaymentPlan): Promise<boolean> {
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        const body = {
            UrlAccessKey: this.componentService.NULL_GUID,  // need an access key, but don't need an access key
            planGUID: plan.recurringConsumerPaymentGUID,
            status: plan.status,
            path: this.activateRecurringPaymentPlanPath,
            bypass: true,
            requireCredentials: false
        };
        // tslint:disable-next-line: forin
        for (const i in plan) {
            body[i] = plan[i];
        }

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callSubmitFeedbackApi(feedbackRequest: SubmitFeedbackRequest, loginToken: string = null): Promise<IGenericResponse> {
        if (!this.token) {
            this.token = this.componentService.storageService.exists('token')
                ? this.componentService.storageService.retrieve('token')
                : loginToken;
        }

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.submitFeedbackPath,
        };

        // tslint:disable-next-line: forin
        for (const i in feedbackRequest) {
            body[i] = feedbackRequest[i];
        }

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => response.body)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetAccountBalanceInfoApi(): Promise<IAccountBalanceInfo> {
        // Make sure memory value is cleared since the storage may have been dumped.

        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();
        let path = this.token;
        path = this.urlAppend(path, this.accountBalanceInfoPath);
        path = this.urlAppend(path, consumer.accountID);
        path = this.urlAppend(path, '');  // Empty closing / for pathing

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.accountBalanceInfoPath,
            customerAccountID: consumer.accountID
        };

        return this.http.post(
            this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IAccountBalanceInfo>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private callGetFastTrackPaymentInfoApi(fastTrackGUID: string): Promise<FastTrackPaymentInfoResponse> {
        let path = this.token;
        path = this.urlAppend(path, this.getFastTrackPaymentInfoPath);
        path = this.urlAppend(path, fastTrackGUID);
        path = this.urlAppend(path, '');  // Empty closing / for pathing

        return this.http.get(this.domainUrl + '?relativePath=' + encodeURIComponent(path) + '&requireCredentials=false',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<FastTrackPaymentInfoResponse>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private callGetGuarantorEmailsApi(emailRequest: GuarantorEmailRequest[]): Promise<GuarantorEmail[]> {
        this.token = this.componentService.storageService.retrieve('token');

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.getGuarantorEmailsPath,
            collectionRequest: [
                {
                    customerId: emailRequest[0].customerId,
                    customerAccountId: emailRequest[0].customerAccountId,
                    id: emailRequest[0].id,
                    type: emailRequest[0].emailAddressType
                }
            ]
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise().then((response: Response<GuarantorEmail[]>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private callGetGuarantorEmailsByVerificationIdApi(accountVerificationId: string): Promise<GuarantorEmail[]> {
        const path = `${this.getGuarantorEmailsPath}/?accountVerificationId=${accountVerificationId}`;

        return this.http.get(this.domainUrl + '?relativePath=' + encodeURIComponent(path) + '&requireCredentials=false',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<GuarantorEmail[]>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetAccountTypeByVerificationID(verificationID: string): Promise<string> {
        let path = this.getAccountTypeByVerificationIdPath;
        path = this.urlAppend(path, verificationID);
        path = this.urlAppend(path, '');  // Empty closing / for pathing

        return this.http.get(this.domainUrl + '?relativePath=' + encodeURIComponent(path) + '&requireCredentials=false',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<FastTrackPaymentInfoResponse>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetConsumerRoles(): Promise<string[]> {
        this.token = this.componentService.storageService.retrieve('token');
        if (this.token == null) {
            return null;
        }

        let path = this.token;
        path = this.urlAppend(path, this.getConsumerRolesPath);

        return this.http.get(this.domainUrl + '?relativePath=' + path + '&requireCredentials=true',
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<string[]>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    /**
     * Binds API payment method to the website payment method. 'Tis a necessary evil.
     *
     * @private
     * @param {PaymentMethod} paymentMethod
     *
     * @memberof ConsumerService
     */
    bindPaymentMethod(method: PaymentMethod): void {
        if (method != null) {
            if (method.cardType == null) {
                method.cardType = CreditCardIssuerType.unknown;
            } else {
                method.cardType = CreditCardIssuerType[method.cardType.toString().toLowerCase().replace(' ', '')];
            }
            method.paymentMethodType = PaymentMethodType[method.paymentMethodType.toString().toLowerCase()];
            method.paymentMethodLast4 = method.paymentMethodLast4.replace('XXXX', '');
        }
    }

    /**
     * Binds API AutopayAgreements to web AutopayAgreements
     * @private
     * @param {IAutopayAgreement[]} agreements
     *
     * @memberof ConsumerService
     */
    bindAutopayAgreements(agreements: IAutopayAgreement[]): void {
        agreements.forEach((agreement) => {
            agreement.status = AgreementStatus[agreement.status.toString().toLowerCase()];
            this.bindPaymentMethod(agreement.storedPaymentMethod);
        });
    }

    /**
     * Binds API Documents to web Document
     * @private
     * @param {IDocument[]} documents
     *
     * @memberof ConsumerService
     */
    bindDocuments(documents: IDocument[]): void {
        documents.forEach((document) => {
            document.autopayPaymentStatus = AutopayPaymentStatus[document.autopayPaymentStatus.toString().toLowerCase()];
        });
    }

    bindRecurringPaymentPlanStatus(plan: IRecurringPaymentPlan): void {
        this.bindPaymentMethod(plan.storedPaymentMethod);
        plan.status = RecurringPaymentPlanStatus[plan.status.toString().toLowerCase()];
        plan.frequency = RecurringFrequency[plan.frequency.toString().toLowerCase()];
        plan.recurringMode = RecurringMode[plan.recurringMode.toString().toLowerCase()];
        plan.type = RecurringPlanType[plan.type.toString().toLowerCase()];
    }

    private async callConsumerPaymentHistoryApi(): Promise<IPayment[]> {
        // need to reset the local token property when calling the API, to avoid login/logout/expiring issues
        this.token = this.componentService.storageService.retrieve('token');
        const consumer = await this.consumerStorageService.getConsumerFromStorage();
        const body = {
            UrlAccessKey: this.token,
            path: 'consumer/v2/paymenthistory',
            bypass: true,
            customerAccountID: consumer.accountID
        };

        return this.http.post(this.domainUrl,
            { body },
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<IPayment[]>) => response.body.data)
            .catch((response: HttpResponse<Response<any>>) => this.loggingService.handleError(response));
    }

    private async callIsCaptchaRequiredApi(): Promise<boolean> {
        this.token = this.componentService.storageService.retrieve('token');
        if (this.token == null) {
            return null;
        }
        const body = {
            UrlAccessKey: this.token,
            path: this.consumerIsCaptchaRequiredPath,
            bypass: true
        };

        return this.http.post(this.domainUrl,
            { body },
            jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: HttpResponse<Response<any>>) => this.loggingService.handleError(response));
    }

    private getPayableDocuments(documents: IDocument[]): IDocument[] {
        return documents
            .filter((doc) => doc.isPayable)
            .map(x => Object.assign(x, {promptPayDueDate: x.promptPayDueDate != null ? new Date(x.promptPayDueDate) : null}));
    }

    private urlAppend(first: string, second: string): string {
        const lastChar = first.substr(-1); // Selects the last character
        const firstChar = second.substr(0, 1);
        if (lastChar !== '/' && firstChar !== '/') {         // If there isn't a slash here, add it
            first = first + '/';            // Append a slash to it.
        }
        return first + second;
    }

    private async callActivateRecurringPaymentAgreement(recurringPaymentAgreement: IRecurringPaymentPlanSubmit): Promise<boolean> {
        const path = this.activateRecurringPaymentAgreementPath;
        const body = { UrlAccessKey: this.token, path, bypass: true };

        // tslint:disable-next-line: forin
        for (const i in recurringPaymentAgreement) {
            body[i] = recurringPaymentAgreement[i];
        }

        return this.http.post(this.domainUrl, { body }, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async sendVerificationCodeApi(customerAccountID: string, emailAddress: string): Promise<GenericResponse> {
        this.token = this.componentService.storageService.retrieve('token');

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.sendVerificationCodePath,
            customerAccountID,
            emailAddress
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: GenericResponse) => response)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async validateVerificationCodeApi(
        customerAccountID: string,
        emailAddress: string,
        code: string,
        consumerAccountGUID: string,
        prefChanges: ICommunicationPreferenceChange[]
    ): Promise<GenericResponse> {
        this.token = this.componentService.storageService.retrieve('token');

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.validateVerificationCodePath,
            consumerAccountGUID,
            customerAccountID,
            emailAddress,
            emailVerificationCode: code,
            enrollmentChannel: 'MySecureBill',
            prefChanges
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<GenericResponse>) => response)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async updateLastEStatementPromptApi(consumerAccountGUID: string): Promise<boolean> {
        this.token = this.componentService.storageService.retrieve('token');

        const body = {
            UrlAccessKey: this.token,
            bypass: true,
            path: this.updateConsumerAccountLastEStatementPromptPath,
            consumerAccountGUID
        };

        return this.http.post(this.domainUrl, {body}, jsonRequestOptions())
            .toPromise()
            .then((response: Response<boolean>) => response.body.success)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }
}
