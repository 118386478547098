import { Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaComponent } from 'angular2-recaptcha';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountPaymentEntryComponent } from '../../components/Controls/AccountPaymentEntry/accountpaymententry.component';
import { PaymentSelectionComponent } from '../../components/Controls/PaymentSelection/paymentselection.component';
import { IAccountPaymentResponse } from '../../models/accountpaymentresponse';
import { Address } from '../../models/address';
import { IConsumer } from '../../models/consumer';
import { ConsumerPayment } from '../../models/consumerpayment';
import { IDomainInfo } from '../../models/domaininfo';
import { PaymentConfirmation } from '../../models/paymentconfirmation';
import { IPaymentLocation } from '../../models/PaymentLocation';
import { OtherPaymentMethod, PaymentMethod, PaymentMethodType } from '../../models/paymentmethod';
import { PaymentSelectionModel, PaymentSelectionStateModel } from '../../models/paymentselectionmodel';
import { RealtimePayment } from '../../models/realtimepayment';
import { ComponentService } from '../../services/component/component.service';
import { LoginService } from '../../services/login/login.service';
import { PaymentService } from '../../services/payment/payment.service';

@Component({
    selector: 'accountpayment',
    template: require('./accountpayment.component.html'),
    styles: [require('./accountpayment.component.css')]
})
export class AccountPaymentComponent implements OnInit, OnDestroy {
    constructor(
        private componentService: ComponentService,
        private parentRouter: Router,
        private loginService: LoginService,
        private paymentService: PaymentService,
        private activatedRoute: ActivatedRoute,
        private ngZone: NgZone
    ) { }

    /**
     * If the form on step 3 is ready for validation. This will return
     * `false` until the user checks the checkbox at least once and
     * also has attempted the reCaptcha
     */
    get readyForStep3Validation(): boolean {
        return this.checkedBoxOnce && this.recaptchaAttempted;
    }
    private ngUnsubscribe: Subject<any> = new Subject();
    recaptchaAttempted = false;
    private recaptchaErrorMessage = '';
    private selectedPaymentMethod: PaymentMethod;
    private checkedBoxOnce = false;
    private captchaToken = '';

    @ViewChild(ReCaptchaComponent, { static: true }) captcha: ReCaptchaComponent;
    @ViewChild('accountPaymentEntryCmp', { static: true }) accountPaymentEntryCmp: AccountPaymentEntryComponent;
    @ViewChild('paymentSelectionCmp', { static: false }) paymentSelectionCmp: PaymentSelectionComponent;

    modelToPassToPayment: PaymentSelectionModel = {
        isOneTimePayment: true,
        isAccountPayment: true,
        defaultUpdateAddress: true,
        contentItemCategory: 'payment',
        contentItemSubCategory: 'accountPayment'
    } as PaymentSelectionModel;

    submission = false;
    currentStep = 1;
    finalStep = 3;
    amountToPay = 0.00;
    todaysDate = new Date();
    paymentDate: string = moment(this.todaysDate).format('YYYY-MM-DDTHH:mm:ss');
    receiptEmail = '';
    showFreeForm: boolean;
    showPayorTypeError = false;
    showReceiptEmail = false;
    receiptEmailError = false;
    paymentAuthorization = false;
    step1Valid = true;
    step2Valid = true;
    step3Valid = true;
    resubmitPayment = true;
    stepValidationMessages: string[] = [];
    otherPaymentMethod: PaymentMethod;

    domainInfo: IDomainInfo;
    paymentRevocationText: string;
    paymentRevocationTimeRangeText: string;
    paymentStep1Name: string;
    paymentStep1Header: string;
    paymentStep1SubHeading: string;
    paymentStep2Name: string;
    paymentStep2Header: string;
    paymentStep2SubHeading: string;
    paymentStep3Name: string;
    paymentStep3Header: string;
    paymentStep3SubHeading: string;
    payorTypeChoices: string; // semi-colon delimited list of Payor Types ex. (self;parent;business)
    payorTypeLabelText: string;
    payorTypeDescriptionText: string;
    payorTypeErrorText: string;
    continueButtonText: string;
    backButtonText: string;
    paymentDateText: string;
    makePaymentButtonText: string;
    payHeaderText: string;
    amountHeaderText: string;
    clearSelectionsText: string;
    paymentMethodText: string;
    paymentAuthText: string;
    paymentAuthDisclaimerText: string;
    paymentAuthErrorText: string;
    freeFormDescription: string;
    freeFormText: string;
    freeFormInfoText: string;
    freeFormLabelText = '';
    freeFormInput = '';
    emailReceiptText: string;
    emailReceiptInfoText: string;
    commentsText: string;
    commentsInfoText: string;
    invalidEmailError: string;
    mySecureWalletInfoLink: string;
    postalCodeErrorText: string;
    paymentError: string;
    payorType: string;

    stepList: any[] = ['one', 'two', 'three'];

    paymentMethods: PaymentMethod[] = [];

    consumer: IConsumer;
    saveToWallet: boolean;
    creditCardNumber: string;
    cvvNumber: string;
    achAccountNumber: string;
    achRoutingNumber: string;
    locationList: IPaymentLocation[] = [];

    companyName: string;
    private automationParam: string;

    static isNullOrEmpty(s: string): boolean {
        return !s;
    }

    /**
     * onPopState listens for the popstate event on the window and performs the actions, this handles the browser back button
     * @see https://stackoverflow.com/questions/40381814/how-do-i-detect-user-navigating-back-in-angular2
     * @param {*} event the event
     * @memberof PaymentComponent
     */
    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        // wrap with this because it messes up unit tests
        if (process.env.ENV !== 'test') {
            history.pushState(false, 'payment', 'payment');
        }
        this.prevStep();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        // we want to wipe potentially-stale merchant profile data and re-load to get an accurate allowed max CC amount.
        // the merchant profile data will be reloaded when a facility is chosen in the dropdown.
        this.componentService.storageService.clearSession();

        this.componentService.scrollPageToTop();
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                // use one time payment content items
                this.companyName = this.componentService.contentService.tryGetContentItem(
                    content, 'help', 'contactUs', 'contactUsTitle').text;

                this.paymentRevocationTimeRangeText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentRevocationLanguageTimeRange').text;
                this.paymentStep1Name = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep1Name').text;
                this.paymentStep1Header = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep1Header').text;
                this.paymentStep1SubHeading = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep1SubHeader').text;
                this.paymentStep2Name = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep2Name').text;
                this.paymentStep2Header = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep2Header').text;
                this.paymentStep2SubHeading = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep2SubHeader').text;
                this.paymentStep3Name = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep3Name').text;
                this.paymentStep3Header = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep3Header').text;
                this.paymentStep3SubHeading = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentStep3SubHeader').text;
                this.continueButtonText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentContinueButton').text;
                this.backButtonText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentBackButton').text;
                this.makePaymentButtonText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentMakePaymentButton').text;
                this.payHeaderText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentPayHeader').text;
                this.amountHeaderText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentAmountHeader').text;
                this.clearSelectionsText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentClearSelectionsButton').text;
                this.paymentDateText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentPaymentDateLabel').text;
                this.paymentMethodText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentPaymentMethod').text;
                this.paymentAuthText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentPaymentAuthorization').text;
                this.paymentAuthDisclaimerText = this.componentService.contentService.tryGetContentItem(
                    content, 'payment', 'accountPayment', 'paymentPaymentAuthorizationDisclaimer').text;

                this.paymentAuthErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'accountPayment', 'paymentPaymentAuthorizationError').text;
                this.emailReceiptText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentEmailReceipt').text;
                this.emailReceiptInfoText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentEmailReceiptInfo').text;
                this.commentsText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentCommentsLabel').text;
                this.commentsInfoText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentCommentsInfo').text;
                this.invalidEmailError = this.componentService.contentService.tryGetContentItem(content, 'error', 'accountPayment', 'paymentInvalidEmailError').text;
                this.mySecureWalletInfoLink = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentmySecureWalletInformationLink').text;
                this.postalCodeErrorText = this.componentService.contentService.tryGetContentItem(content, 'error', 'accountPayment', 'paymentPostalCodeErrorText').text;
                this.paymentError = this.componentService.contentService.tryGetContentItem(content, 'error', 'payment', 'paymentGenericErrorMessage').text;
                this.stepList = [{ stepName: this.paymentStep1Name },
                    { stepName: this.paymentStep2Name },
                    { stepName: this.paymentStep3Name }];

                this.recaptchaErrorMessage = this.componentService.contentService.tryGetContentItem(content, 'error', 'accountPayment', 'accountPaymentRecaptchaError').text;
                this.setPaymentRevocationText(content);
                this.payorTypeChoices = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentPayorTypeChoices').text;
                this.payorTypeLabelText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentPayorTypeLabelText').text;
                this.payorTypeDescriptionText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentPayorTypeDescriptionText').text;
                this.freeFormLabelText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentFreeFormField').text;
                this.freeFormDescription = this.componentService.contentService.tryGetContentItem(content, 'payment', 'accountPayment', 'paymentFreeFormFieldDescription').text;
                this.showFreeForm = !!this.freeFormLabelText;
            });

        this.otherPaymentMethod = Object.assign({}, OtherPaymentMethod[0]);
        this.paymentMethods = this.paymentMethods.concat(this.otherPaymentMethod);
        this.componentService.domainService.getLocations().then(locationList => {
            // Make sure locationList is sorted by description
            this.locationList = locationList.sort(this.componentService.sortListByStringProperty('description'));
            if (locationList.length === 1) {
                this.accountPaymentEntryCmp.updateFacility(this.locationList[0]);
            }
        });
        this.otherPaymentMethod.paymentMethodType = PaymentMethodType.credit;
        this.captcha
            .captchaExpired
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.recaptchaAttempted = false;
            });
        this.captcha
            .captchaResponse
            .asObservable()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((token: string) => this.captchaTokenExists(token));

        this.activatedRoute.queryParams
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(p => {
                this.automationParam = p.automation;
            });
    }

    private redirect(relativeRoute: string): void {
        this.ngZone.run(() => this.parentRouter.navigateByUrl(relativeRoute)).then();
    }

    async nextStep() {
        // Check for an outage
        if (await this.paymentService.checkOutage()) {
            return this.redirect('/outage');
        }

        // wrap with this because it messes up unit tests
        if (process.env.ENV !== 'test') {
            // needed for onPopState to function completely
            history.pushState(false, 'accountpayment', 'accountpayment');
        }

        // clear validation messages
        this.stepValidationMessages = [];

        if (this.currentStep === 1) {
            // Set running amount display from step 1 total
            this.amountToPay = this.accountPaymentEntryCmp.paymentAmount;

            this.modelToPassToPayment.amountToPay = this.amountToPay;
            this.modelToPassToPayment.paymentDate = this.paymentDate;
            this.paymentSelectionCmp.disableWallet();
            this.paymentSelectionCmp.setMerchantProfile(this.accountPaymentEntryCmp.merchantProfile);
            this.paymentAmountValidation();
            this.showReceiptEmail = this.accountPaymentEntryCmp.merchantProfile.enableConfirmationEmails;
        }

        if (this.currentStep === 2) {
            // Get payment method selected on step 2 so we can pass it to step 3's binding
            this.step2Valid = this.paymentSelectionCmp.isValid();
        }

        if (this.currentStep === 3) {
            // TODO: Validate email address or just send it up?
            this.reviewPaymentValidation();
        }

        if (this.currentStep < this.finalStep && this.currentStepIsValid(this.currentStep)) {
            this.currentStep++;
            this.componentService.scrollPageToTop();
        } else if (this.currentStep === this.finalStep && this.currentStepIsValid(this.currentStep)) {
            // Submit payment and redirect to confirmation page?
            if (this.resubmitPayment) {
                // Sounds good, let's do that
                this.submitAccountPayment();
            } else {
                this.step3Valid = false;
                this.addPageValidationMessage('You must make changes to resubmit payment');
            }
            // throw up the waiting icon
            // do some cool redirect on success, and grab failure, etc.
        }

        this.selectedPaymentMethod = this.paymentSelectionCmp.getPaymentSelectionState().selectedPaymentMethod;
    }

    prevStep(): void {
        this.paymentAuthorization = false;
        this.step3Valid = true;
        this.resubmitPayment = true;

        if (this.currentStep > 1) {
            if (this.currentStep === 2) {
                // tslint:disable-next-line:triple-equals
                if (this.selectedPaymentMethod == this.paymentMethods[this.paymentMethods.length - 1]
                    && this.creditCardNumber != null
                    && this.creditCardNumber.length > 0) {
                    this.paymentSelectionCmp.prepareCreditCard();
                }
            }

            if (this.currentStep === 3) {
                this.paymentSelectionCmp.disableWallet();
            }

            this.paymentSelectionCmp.checkACH();
            const paymentMethod = this.paymentMethods[this.paymentMethods.length - 1];
            paymentMethod.expirationDate = this.otherPaymentMethod.expirationDate;
            paymentMethod.description = this.otherPaymentMethod.description;
            paymentMethod.cardType = this.otherPaymentMethod.cardType;
            paymentMethod.paymentMethodType = PaymentMethodType.unknown;

            this.currentStep--;
            this.componentService.scrollPageToTop();
            this.selectedPaymentMethod = this.paymentSelectionCmp.getPaymentSelectionState().selectedPaymentMethod;
        } else {
            // TODO: clear data -> redirect to dashboard
            this.redirect('/');
        }
    }

    submitAccountPayment(): void {
        this.startSubmission();
        const payment: ConsumerPayment = new ConsumerPayment();
        const state: PaymentSelectionStateModel = this.paymentSelectionCmp.getPaymentSelectionState();

        payment.PaymentSource = this.paymentSelectionCmp.getPaymentSource();
        if (state.selectedPaymentMethod.paymentMethodType === PaymentMethodType.ach) {
            payment.ACHPaymentMethod = this.paymentSelectionCmp.getACHPayment();
        } else {
            payment.CreditCardPaymentMethod = this.paymentSelectionCmp.getCreditCardPayment();
        }
        payment.Address = new Address();
        payment.RealtimeBalanceToPayOn = new RealtimePayment();
        payment.AccountID = this.accountPaymentEntryCmp.accountNumber;
        payment.AccountName = this.accountPaymentEntryCmp.nameOnAccount;
        payment.RealtimeBalanceToPayOn.PaymentAmount = this.accountPaymentEntryCmp.paymentAmount;
        payment.StorePaymentMethod = false;
        payment.Address.firstName = state.firstName;
        payment.Address.lastName = state.lastName;
        payment.Address.address1 = state.address1;
        payment.Address.address2 = state.address2;
        payment.Address.city = state.city;
        payment.Address.stateRegion = state.state;
        payment.Address.postalCode = state.postalCode;
        payment.PaymentComment = this.accountPaymentEntryCmp.comments;
        payment.PaymentLocationGUID = this.accountPaymentEntryCmp.facility;
        payment.ConfirmationEmail = this.receiptEmail;
        payment.Language = window.navigator.language;
        payment.PhoneNumber = this.accountPaymentEntryCmp.phoneNumber;
        payment.smsGUID = this.componentService.storageService.retrieve('smsGUID');
        payment.PayorType = this.payorType;

        if (this.freeFormLabelText.length > 0) {
            payment.CustomTextJSON = '{' + '"' + this.freeFormLabelText + '" : "' + this.freeFormInput.trim() + '"}';
        }

        this.paymentService.payAccount(payment, this.captchaToken).then(paymentDone => {
            this.processPayment(paymentDone);
        }).catch(paymentError => {
            this.processPayment(paymentError);
        });
    }

    processPayment(payment: IAccountPaymentResponse): void {
        // clear waiting icon
        this.submission = false;
        if (payment.success) {
            // we'll use the consumer payment stuff and redirect
            const paymentConfirmation: PaymentConfirmation = new PaymentConfirmation();
            paymentConfirmation.accountId = this.accountPaymentEntryCmp.accountNumber;
            paymentConfirmation.originalPaymentAmount = this.amountToPay;
            paymentConfirmation.paymentAmount = payment.data.paymentAmount;
            paymentConfirmation.paymentDate = this.paymentDate;
            paymentConfirmation.paymentReferenceId = payment.data.referenceID;
            paymentConfirmation.consumerPaymentGUID = payment.data.consumerPaymentGUID;
            paymentConfirmation.confirmationNumber = payment.data.processorID;
            paymentConfirmation.surveyCompleted = false;
            // store information needed for confirmation page
            this.componentService.storageService.store('paymentconfirmation', paymentConfirmation);

            this.paymentSelectionCmp.clearPayments();
            this.selectedPaymentMethod = null;
            this.redirect('/confirmation');
        } else {
            this.captcha.reset();
            this.step3Valid = false;
            this.resubmitPayment = false;
            if (payment.data && payment.data[0] && payment.data[0].message != null) {
                this.addPageValidationMessage(this.componentService.handleValidationErrorCode(payment.data[0].message));
            } else if (payment.messages != null && payment.messages.length > 0) {
                this.addPageValidationMessage(payment.messages.join('<br/>'));
            } else {
                this.addPageValidationMessage(this.paymentError);
            }
        }
    }

    checkTotalAmountNotZero(): boolean {
        if (!this.step1Valid) {
            return false;
        }
        return this.amountToPay > 0;
    }

    paymentAmountValidation(): void {
        this.step1Valid = true;
        this.step1Valid = this.checkTotalAmountNotZero();
        this.step1Valid = this.accountPaymentEntryCmp.accountEntryIsValid() && this.step1Valid;
    }

    /**
     * Review the required parts of step 3. Only used on "Make Payment"
     * button click or after both recaptcha is attempted and the checkbox
     * is checked once
     */
    reviewPaymentValidation(): void {
        this.emailInputBlur();

        this.stepValidationMessages = [];
        this.step3Valid = true;
        if (!this.paymentAuthorization) {
            this.step3Valid = false;
            this.addPageValidationMessage(this.paymentAuthErrorText);
        }
        if (!this.recaptchaAttempted) {
            this.step3Valid = false;
            this.addPageValidationMessage(this.recaptchaErrorMessage);
        }
        if (this.receiptEmailError) {
            this.step3Valid = false;
        }
    }

    private emailInputBlur(): void {
        // This is an optional field.  It is only triggered on blank or empty whitespace input,
        // and we don't want to show an error until the user has moved out of the field.
        this.receiptEmail = this.receiptEmail.trim();

        this.receiptEmailError = this.receiptEmail && !this.componentService.emailRegex.test(this.receiptEmail);
    }

    private clearEmailError(): void {
        this.receiptEmailError = false;
    }

    currentStepIsValid(step: number): boolean {
        let stepIsValid: boolean;
        switch (step) {
            case 1:
                stepIsValid = this.step1Valid;
                break;

            case 2:
                stepIsValid = this.step2Valid;
                break;

            case 3:
                stepIsValid = this.step3Valid;
                break;
        }
        return stepIsValid;
    }

    /**
     * Returns true if current selected payment is ACH (bank account)
     * Intended to be consumed by the template for determining when to display revocation language.
     * @memberof PaymentComponent
     */
    isSelectedPaymentMethodAch(): boolean {
        if (this.paymentSelectionCmp) {
            if (this.paymentSelectionCmp.getPaymentSelectionState() &&
                this.paymentSelectionCmp.getPaymentSelectionState().selectedPaymentMethod) {
                return this.paymentSelectionCmp.getPaymentSelectionState()
                    .selectedPaymentMethod.paymentMethodType === PaymentMethodType.ach;
            }
        }

        return false;
    }

    private startSubmission(): void {
        this.submission = true;
        setTimeout(this.submissionTimeout, 30000);
    }

    private isSubmission(): boolean {
        return this.submission;
    }

    private submissionTimeout(): void {
        this.submission = false;
    }

    /**
     * Listens for the authorization checkbox checking and then validates step 3.
     *
     * @private
     * @memberof PaymentComponent
     */
    private authorizationCheckboxChecked() {
        // When we check the authorization box, check the step 3 validation.
        this.checkedBoxOnce = true;
        this.validateStep3();
    }

    async captchaTokenExists(token: string) {
        this.captchaToken = token;
        this.recaptchaAttempted = !!this.captchaToken;

        this.validateStep3();
    }

    /**
     * Performs step3 validation only if the `this.readyForStep3Validation` flag is `true`
     * used in the callbacks for the authorization checkbox and recaptcha events
     */
    private validateStep3() {
        if (this.readyForStep3Validation) {
            this.reviewPaymentValidation();
        }
    }

    /**
     * Helper for updating the array of error messages on step 3
     * @param {string} msg - The message you want to add to the array
     */
    private addPageValidationMessage(msg: string) {
        this.stepValidationMessages = this.stepValidationMessages.concat([msg]);
    }

    private checkOneTimePayment() {
        return this.componentService.storageService.exists('onetimepayment');
    }

    /**
     * Helper for replacing content item placeholders on revocation text.
     * Sets value after finding-and-replacement in order to present the message in the template.
     * @memberof PaymentComponent
     */
    private setPaymentRevocationText(content: any): void {
        if (this.checkOneTimePayment()) {
            this.paymentRevocationText = this.componentService.contentService.tryGetContentItem(
                content,
                'payment',
                'oneTimePayment',
                'paymentRevocationLanguage',
            ).text;
        } else {
            this.paymentRevocationText = this.componentService.contentService.tryGetContentItem(
                content,
                'payment',
                'loggedInPayment',
                'paymentRevocationLanguage',
            ).text;
        }

        this.paymentRevocationText = this.paymentRevocationText.replace(
            /!CONTACTUSTITLE!/g,
            this.companyName);
        this.paymentRevocationText = this.paymentRevocationText.replace(
            /!TIMERANGE!/g,
            this.paymentRevocationTimeRangeText);
    }

    private isAccessedFromAutomation(): boolean {
        return (process.env.ENV !== 'production' &&
            this.automationParam === 'automation');
    }
}
