import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { FeedbackComponent } from '../../components/Controls/Feedback/feedback.component';
import { ICommunicationPreferences } from '../../models/communicationPreferences';
import { ConsumerAccount } from '../../models/consumeraccount';
import { ConsumerSurvey } from '../../models/consumersurvey';
import { IDomainInfo } from '../../models/domaininfo';
import { PaymentConfirmation } from '../../models/paymentconfirmation';
import { IPaymentPlanBalance } from '../../models/paymentplanbalance';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { LoggingLevel } from '../../services/logging/logging.service';
import { PaymentService } from '../../services/payment/payment.service';

@Component({
    selector: 'confirmation',
    template: require('./confirmation.component.html'),
    styles: [require('./confirmation.component.css')]
})
export class ConfirmationComponent implements OnInit, OnDestroy {
    constructor(
        private parentRouter: Router,
        private config: AppConfig,
        private componentService: ComponentService,
        private paymentService: PaymentService,
        private consumerService: ConsumerService,
        private ngZone: NgZone
    ) { }

    private ngUnsubscribe: Subject<any> = new Subject();

    @ViewChild('feedbackCmp', { static: false }) feedbackCmp: FeedbackComponent;

    userIsLoggedIn = false;
    domainInfo: IDomainInfo;
    paymentConfirmation: PaymentConfirmation;
    consumerAccount: ConsumerAccount;
    loading = false;
    loadingBalance = false;
    consumerToken = '';
    buttonLink = '/';
    receiptRetrieved = false;
    printUrl: string;
    surveyEnabled: boolean;
    surveyPrompted = false;
    surveySubmitted = false;
    surveyCompleted = false;
    isAgentAssistedPayment = false;
    isOneTimePayment = false;
    hasSurveyError = false;
    alreadyInitialized = false;
    isFileSaverSupported = false;
    enableSetupPlanButton = false;
    offPlanBalances: IPaymentPlanBalance[] = [];

    confirmationHeader: string;
    confirmationSubHeader: string;
    printReceiptText: string;
    saveReceiptText: string;
    paymentSuccessText: string;
    buttonText: string;
    confirmationNumberText: string;
    paymentDateText: string;
    unableToRetrieveReceiptText: string;
    surveyTitleText: string;
    surveyLeadingPromptText: string;
    surveyLinkText: string;
    surveyQuestionText: string;
    yesButtonText: string;
    noButtonText: string;
    commentsText: string;
    commentsSubmitButtonText: string;
    surveyThankYouText: string;
    surveyErrorText: string;
    surveyAnswer: string;
    comments = '';
    wereSorryText = 'We\'re Sorry';
    noLongerAvailableText = 'This page is no longer available.';
    setupPaymentPlanButtonText: string;
    quickPayIdentifier: string;
    partialCCAuthNoticeText: string;

    leftAdTitle: string;
    leftAdText: string;
    leftAdUrl: string;
    leftAdLinkText: string;
    leftAdLinkUrl: string;
    leftAdPCBTitle: string;
    leftAdPCBText: string;
    leftAdPCBUrl: string;
    leftAdPCBLinkText: string;
    leftAdPCBLinkUrl: string;
    leftAdPCTTitle: string;
    leftAdPCTText: string;
    leftAdPCTUrl: string;
    leftAdPCTLinkText: string;
    leftAdPCTLinkUrl: string;
    leftAdPCRTitle: string;
    leftAdPCRText: string;
    leftAdPCRUrl: string;
    leftAdPCRLinkText: string;
    leftAdPCRLinkUrl: string;
    leftAdPCRQTitle: string;
    leftAdPCRQText: string;
    leftAdPCRQUrl: string;
    leftAdPCRQLinkText: string;
    leftAdPCRQLinkUrl: string;
    leftAdPCQTitle: string;
    leftAdPCQText: string;
    leftAdPCQUrl: string;
    leftAdPCQLinkText: string;
    leftAdPCQLinkUrl: string;

    rightAdTitle: string;
    rightAdText: string;
    rightAdUrl: string;
    rightAdLinkText: string;
    rightAdLinkUrl: string;

    totalPaymentAmount: number;

    isPartialPayment = false;

    ngOnInit() {
        this.componentService.scrollPageToTop();
        // Check if HTML5 filesaving is supported, if not, they can open in new tab.
        try {
            this.isFileSaverSupported = !!new Blob();
        } catch (e) {
            // Do nothing because the variable is already false and we don't care.
        }

        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.confirmationHeader = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationHeader').text;
                this.confirmationSubHeader = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationSubHeader').text;
                this.printReceiptText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationPrintReceiptText').text;
                this.saveReceiptText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationSaveReceiptText').text;
                this.paymentSuccessText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationPaymentSuccessText').text;
                this.buttonText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationStartOverButton').text;
                this.confirmationNumberText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationConfirmationNumberText').text;
                this.paymentDateText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationPaymentDateText').text;
                this.unableToRetrieveReceiptText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'error', 'paymentConfirmationReceiptErrorText').text;
                this.surveyTitleText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationSurveyTitleText').text;
                this.surveyQuestionText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationSurveyQuestionText').text;
                this.surveyLeadingPromptText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationSurveyLeadingPromptText').text;
                this.surveyLinkText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationSurveyLinkText').text;
                this.yesButtonText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationSurveyYesButtonText').text;
                this.noButtonText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationSurveyNoButtonText').text;
                this.commentsText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationSurveyCommentsText').text;
                this.commentsSubmitButtonText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationSurveySubmitButtonText').text;
                this.surveyThankYouText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationSurveyThankYouText').text;
                this.surveyErrorText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'error', 'paymentConfirmationSurveyErrorText').text;
                this.wereSorryText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationWereSorryText').text;
                this.noLongerAvailableText = this.componentService.contentService.tryGetContentItem(content, 'confirmation', 'pageText', 'paymentConfirmationNoLongerAvailableText').text;
                this.leftAdTitle = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdTitle').text;

                this.leftAdText = this.componentService.contentService.tryGetContentItem(content, 'ad', 'confirmation', 'leftAdText').text;
                this.leftAdUrl = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'image', 'confirmationLeftAdImageUrl').text;

                this.leftAdLinkText = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdLinkText').text;

                this.leftAdLinkUrl = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdLinkUrl').text;

                this.rightAdTitle = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'rightAdTitle').text;

                this.rightAdText = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'rightAdText').text;

                this.rightAdUrl = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'image', 'confirmationRightAdImageUrl').text;

                this.rightAdLinkText = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'rightAdLinkText').text;

                this.rightAdLinkUrl = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'rightAdLinkUrl').text;

                this.leftAdPCBTitle = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdTitle').text;

                this.leftAdPCBText = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdText').text;

                this.leftAdPCBUrl = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'image', 'confirmationLeftAdImageUrl').text;

                this.leftAdPCBLinkText = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdLinkText').text;

                this.leftAdPCBLinkUrl = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdLinkUrl').text;

                this.leftAdPCTTitle = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdPCTTitle').text;

                this.leftAdPCTText = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdPCTText').text;

                this.leftAdPCTUrl = this.componentService.contentService.tryGetContentItem(content, 'ad', 'image', 'confirmationLeftAdPCTImageUrl').text;
                this.leftAdPCTLinkText = this.componentService.contentService.tryGetContentItem(content, 'ad', 'confirmation', 'leftAdPCTLinkText').text;
                this.leftAdPCTLinkUrl = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdPCTLinkUrl').text;

                this.leftAdPCRTitle = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdPCRTitle').text;

                this.leftAdPCRText = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdPCRText').text;

                this.leftAdPCRUrl = this.componentService.contentService.tryGetContentItem(content, 'ad', 'image', 'confirmationLeftAdPCRImageUrl').text;
                this.leftAdPCRLinkText = this.componentService.contentService.tryGetContentItem(content, 'ad', 'confirmation', 'leftAdPCRLinkText').text;
                this.leftAdPCRLinkUrl = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdPCRLinkUrl').text;

                this.leftAdPCRQTitle = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdPCRQTitle').text;

                this.leftAdPCRQText = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdPCRQText').text;

                this.leftAdPCRQUrl = this.componentService.contentService.tryGetContentItem(content, 'ad', 'image', 'confirmationLeftAdPCRQImageUrl').text;
                this.leftAdPCRQLinkText = this.componentService.contentService.tryGetContentItem(content, 'ad', 'confirmation', 'leftAdPCRQLinkText').text;
                this.leftAdPCRQLinkUrl = this.componentService.contentService.tryGetContentItem(content, 'ad', 'confirmation', 'leftAdPCRQLinkUrl').text;
                this.leftAdPCQTitle = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdPCQTitle').text;

                this.leftAdPCQText = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdPCQText').text;

                this.leftAdPCQUrl = this.componentService.contentService.tryGetContentItem(content, 'ad', 'image', 'confirmationLeftAdPCQImageUrl').text;
                this.leftAdPCQLinkText = this.componentService.contentService.tryGetContentItem(content, 'ad', 'confirmation', 'leftAdPCQLinkText').text;
                this.leftAdPCQLinkUrl = this.componentService.contentService.tryGetContentItem(
                    content, 'ad', 'confirmation', 'leftAdPCQLinkUrl').text;

                this.setupPaymentPlanButtonText = this.componentService.contentService.tryGetContentItem(
                    content, 'confirmation', 'pageText', 'setupPaymentPlanButtonText').text;

                this.partialCCAuthNoticeText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'pageText', 'paymentMSBPartialCCAuthNotice').text;
            });

        this.componentService.domainService.domainInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domainInfo => {
            this.domainInfo = domainInfo;
            if (this.domainInfo !== null) {
                this.initializeConfirmationPage();
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        // Called once, before the instance is destroyed.
        this.componentService.storageService.clear('paymentconfirmation');
        this.componentService.storageService.clear('paymentsource');
    }

    initializeConfirmationPage() {
        if (this.componentService.storageService.exists('onetimepayment')) {
            this.isOneTimePayment = true;
        }

        if (this.componentService.storageService.exists('agentassistedrole')) {
            this.isAgentAssistedPayment = true;
        }

        this.userIsLoggedIn = this.componentService.userIsLoggedIn();
        this.consumerToken = this.componentService.storageService.retrieve('token');
        if (!this.isOneTimePayment && !this.isAgentAssistedPayment && this.userIsLoggedIn) {
            // Not account payment and not onetimepayment and not agentAssistedPayment ->
            //    redirect to home, otherwise go to /
            this.buttonLink = '/home';
        } else if (this.isOneTimePayment) {
            this.buttonLink = '/mempayment';
        } else if (this.isAgentAssistedPayment) {
            this.buttonLink = '/payment';
        }

        if (this.componentService.storageService.exists('paymentconfirmation')) {
            this.paymentConfirmation = this.componentService.storageService.retrieve('paymentconfirmation');

            // Only if both model and domain say enable survey
            this.surveyEnabled = this.paymentConfirmation.enableSurvey && this.domainInfo.enableSurvey;

            if (this.paymentConfirmation.surveyCompleted) {
                this.surveyCompleted = true;
            }

            if (this.paymentConfirmation.paymentAmount !== undefined &&
                this.paymentConfirmation.originalPaymentAmount !== this.paymentConfirmation.paymentAmount) {
                this.isPartialPayment = true;
                this.totalPaymentAmount = this.paymentConfirmation.paymentAmount;
            }
            else {
                if (this.paymentConfirmation.paymentFeesToPay) {
                    this.totalPaymentAmount = this.paymentConfirmation.paymentAmount + this.paymentConfirmation.paymentFeesToPay;
                } else {
                    this.totalPaymentAmount = this.paymentConfirmation.paymentAmount;
                }
            }

            this.retrieveReceipt();
            this.alreadyInitialized = true;
        } else {
            this.paymentConfirmation = null;
            this.receiptRetrieved = false;
            this.surveyEnabled = false;
        }

        this.getConsumer(this.paymentConfirmation);
    }

    private submitSurveyPrompt(): void {
        this.surveyPrompted = true;
    }

    private submitSurvey(answer: string): void {
        this.loading = true;
        this.surveyAnswer = answer;

        const survey: ConsumerSurvey = new ConsumerSurvey();

        survey.AccountID = this.paymentConfirmation.accountId;
        survey.ReferenceID = this.paymentConfirmation.paymentReferenceId;
        survey.ConsumerPaymentGUID = this.paymentConfirmation.consumerPaymentGUID;
        survey.Response = this.surveyAnswer;
        survey.MobilePayment = this.componentService.isMobileBrowser();

        this.paymentService.submitSurvey(survey).then(surveyDone => {
            this.surveySubmitted = surveyDone;
            this.doneLoading();
        }).catch(surveyError => {
            this.surveySubmitted = false;
            this.doneLoading();
            this.hasSurveyError = true;
            return;
        });
    }

    private submitComments(): void {
        this.loading = true;
        const survey: ConsumerSurvey = new ConsumerSurvey();

        survey.AccountID = this.paymentConfirmation.accountId;
        survey.ReferenceID = this.paymentConfirmation.paymentReferenceId;
        survey.ConsumerPaymentGUID = this.paymentConfirmation.consumerPaymentGUID;
        survey.Response = this.surveyAnswer;
        survey.Comment = this.comments;
        survey.MobilePayment = this.componentService.isMobileBrowser();

        this.paymentService.submitSurvey(survey).then(surveyDone => {
            this.surveyCompleted = surveyDone;
            this.paymentConfirmation.surveyCompleted = true;
            // Update the storage object so we don't get the survey next time.
            this.componentService.storageService.store('paymentconfirmation', this.paymentConfirmation);
            this.doneLoading();
        }).catch(surveyError => {
            this.surveyCompleted = false;
            this.doneLoading();
            this.hasSurveyError = true;
            return;
        });
    }

    private retrieveReceipt(): void {
        // This GUID can be used for testing with davetest "86663019-49E1-4E57-A883-75109AE726F8"
        const getReceiptUrl: string = this.config.getConfig('receiptPdfPath');
        let token: string = this.componentService.storageService.retrieve('token');
        if (token === null || token.length === 0) {
            token = this.paymentConfirmation.accountId;
        }
        this.printUrl = getReceiptUrl + '?t=' + token + '&did=' + this.paymentConfirmation.consumerPaymentGUID + '&v=MySecureBill';
        this.receiptRetrieved = true;
    }

    private downloadReceipt(): void {
        if (!this.isFileSaverSupported) {
            window.open(this.printUrl, '_blank');
            return;
        } else {
            let token: string = this.componentService.storageService.retrieve('token');
            if (token === null || token.length === 0) {
                token = this.paymentConfirmation.accountId;
            }
            this.componentService.archiveService.getArchiveReceiptPdf(
                this.paymentConfirmation.consumerPaymentGUID, token).then(response => {
                const blob = new Blob([response], { type: 'application/pdf;charset=utf-8"' });
                const dateString = new Date().toISOString().substr(0, 10).replace(/-/g, '');
                FileSaver.saveAs(blob, dateString + '_Receipt.pdf');
                this.componentService.loggingService.log(blob.size + ' bytes file downloaded. File type: ' + blob.type, LoggingLevel.debug);
            }).catch(error => {
                this.componentService.loggingService.log(error, LoggingLevel.error);
                this.receiptRetrieved = false;
            });
        }
    }

    private clearPaymentData() {
        if (this.componentService.storageService.exists('onetimepayment')) {
            // Store token as quickPayIdentifier
            this.quickPayIdentifier = this.componentService.storageService.retrieve('token');

            // Clear seassions
            this.componentService.storageService.clearSession();

            // Store quickPayIdentifier to storage.  The connect message link should be set to redirect?smsenrollment=smsenrollment
            this.componentService.storageService.store('quickpayidentifier', this.quickPayIdentifier);

        } else {
            this.componentService.storageService.clear('wallet');
        }
    }

    private backLink() {
        this.ngZone.run(() => this.parentRouter.navigateByUrl(this.buttonLink)).then();
    }

    private goToPayment() {
        this.componentService.redirectToPayment();
    }

    private goToPaymentPlans() {
        this.ngZone.run(() => this.parentRouter.navigateByUrl('/paymentplans/new')).then();
    }

    private doneLoading() {
        this.loading = false;
    }

    private isLoading(): boolean {
        return this.loading;
    }

    private doneLoadingBalance() {
        this.loadingBalance = false;
    }

    private isLoadingBalance(): boolean {
        return this.loadingBalance;
    }

    private showSurveyPrompt(): boolean {
        return !this.surveyPrompted;
    }

    private showSurveyQuestion(): boolean {
        return this.surveyPrompted && !this.surveySubmitted;
    }

    private showSurveyComments(): boolean {
        return this.surveyPrompted && this.surveySubmitted && !this.surveyCompleted;
    }

    private async getConsumer(paymentConfirmation: PaymentConfirmation): Promise<void> {
        if (this.componentService.userIsLoggedIn()) {
            const consumerAccount = await this.consumerService.getConsumerAccount();
            if (this.domainInfo.useConsolidatedCommunication) {
                const preferences = await this.consumerService.getConsumerCommunicationPreferences(consumerAccount.customerAccountID);
                this.setAd(consumerAccount, preferences);
            } else {
                this.setAd(consumerAccount, null);
            }
            this.consumerAccount = consumerAccount;
            this.clearPaymentData();
            this.feedbackCmp.populateConsumerProperties(consumerAccount.customerAccountID);
        } else {
            this.setAd(null, null);
        }
    }

    private setAd(consumer: ConsumerAccount, preferences: ICommunicationPreferences) {
        let deliveryMethod = 'electronic';

        if (consumer !== null) {
            if (this.domainInfo.useConsolidatedCommunication && !!preferences) {
                deliveryMethod = this.consumerService
                    .convertConsumerCommunicationPreferencesToDeliveryMethodWords(preferences)
                    .toLocaleLowerCase();
            } else {
                deliveryMethod = consumer.deliveryMethod.toLocaleLowerCase();
            }
        }

        if (consumer !== null && consumer.accountType !== 'QuickPay') {
            if (deliveryMethod === 'electronic') {
                // PCB
                this.leftAdTitle = this.leftAdPCBTitle;
                this.leftAdText = this.leftAdPCBText;
                this.leftAdUrl = this.leftAdPCBUrl;
                this.leftAdLinkText = this.leftAdPCBLinkText;
                this.leftAdLinkUrl = this.leftAdPCBLinkUrl;
                return;
            }

            // online called when consumer is set
            if (this.isOneTimePayment) {
                // PCRQ
                this.leftAdTitle = this.leftAdPCRQTitle;
                this.leftAdText = this.leftAdPCRQText;
                this.leftAdUrl = this.leftAdPCRQUrl;
                this.leftAdLinkText = this.leftAdPCRQLinkText;
                this.leftAdLinkUrl = this.leftAdPCRQLinkUrl;
            } else {
                // PCR   logged in and not electronic
                this.leftAdTitle = this.leftAdPCRTitle;
                this.leftAdText = this.leftAdPCRText;
                this.leftAdUrl = this.leftAdPCRUrl;
                this.leftAdLinkText = this.leftAdPCRLinkText;
                this.leftAdLinkUrl = this.leftAdPCRLinkUrl;
            }
        } else {
            if (consumer !== null && !deliveryMethod.includes('paper') && this.isOneTimePayment) {
                // PCQ - Payment Complete Quick Pay Account
                this.leftAdTitle = this.leftAdPCQTitle;
                this.leftAdText = this.leftAdPCQText;
                this.leftAdUrl = this.leftAdPCQUrl;
                this.leftAdLinkText = this.leftAdPCQLinkText;
                this.leftAdLinkUrl = this.leftAdPCQLinkUrl;
            } else if (this.isOneTimePayment) {
                // PCT - Payment Confirmation Teaser
                this.leftAdTitle = this.leftAdPCTTitle;
                this.leftAdText = this.leftAdPCTText;
                this.leftAdUrl = this.leftAdPCTUrl;
                this.leftAdLinkText = this.leftAdPCTLinkText;
                this.leftAdLinkUrl = this.leftAdPCTLinkUrl;
            } else {
                // account number
                this.leftAdTitle = this.leftAdPCBTitle;
                this.leftAdText = this.leftAdPCBText;
                this.leftAdUrl = this.leftAdPCBUrl;
                this.leftAdLinkText = this.leftAdPCBLinkText;
                this.leftAdLinkUrl = this.leftAdPCBLinkUrl;
            }
        }
    }
}
