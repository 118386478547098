export class PaymentConfirmation {
    constructor(enableConnectMessaging?: boolean, enableSurvey?: boolean, enableMakePaymentButton?: boolean, logoClickable?: boolean) {
        this.enableConnectMessaging = enableConnectMessaging != null ? enableConnectMessaging : true;
        this.enableSurvey = enableSurvey != null ? enableSurvey : true;
        this.enableMakePaymentButton = enableMakePaymentButton != null ? enableMakePaymentButton : true;
        this.logoClickable = logoClickable != null ? logoClickable : true;
        this.additionalDisplayFields = [];
    }
    accountId: string;
    additionalDisplayFields: AdditionalDisplayField[];
    confirmationNumber: string;
    paymentDate: string;
    originalPaymentAmount: number;
    paymentAmount: number;
    paymentFeesToPay: number;
    paymentReferenceId: number;
    consumerPaymentGUID: string;
    surveyCompleted: boolean;
    enableConnectMessaging: boolean;
    enableSurvey: boolean;
    enableMakePaymentButton: boolean;
    customerAccountID: string;
    logoClickable: boolean;
}

interface AdditionalDisplayField {
    name: string;
    value: string;
}
